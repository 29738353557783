import { Grid, Typography } from '@mui/material'
import React from 'react'
import { PartnerArrowIcon } from '../res/NewWebIcons'

const PaymentRTopComp = () => {
  return (
    <Grid>
        <Grid style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginTop:'1%'}}>
            <Grid>
                <Typography style={{fontWeight:'bold', fontFamily:'sans-serif', fontSize:'19px', color:'#343C6A'}}>Payment Request</Typography>
            </Grid>    
        </Grid>
    </Grid>

    
  )
}

export default PaymentRTopComp;
