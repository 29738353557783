import React from 'react';
import { Grid, Box, Typography, TextField, Button } from '@mui/material';
import iPaisaMobScreenImage from '../Assets/login/iPaisaMobScreen.png';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import iPaisaLogo from '../Assets/login/logo.png';
import IndianFlag from '../Assets/login/IndianFlag.png';
import { Activated_Icon, Active_Icon, BankLogin_Icon, Earn_Icon, Flag_Icon, MadeInIndiaIcon, PartnerCashback_Icon } from '../../res/icons';
import * as Yup from 'yup';

const SignIn = () => {
  const validationSchema = Yup.object({
    userId: Yup.string().required('User ID is required'),
    password: Yup.string().required('Password is required'),
  });

  return (
    <Grid container style={{ height: '100vh' }}>
      <img src={IndianFlag} alt="Indian Flag" style={{
        position: 'absolute',
        top: '2%',
        right: '2%',
        width: '3%', // Adjust the width as needed
        height: 'auto'
      }} />
      {/* Left Side */}
      <Grid
        item
        xs={12}
        md={6}
        style={{
          backgroundColor: '#3029D9',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '5%', // Adjusted padding
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          
        }}
        
        
      >
        <Grid>
          
          <Grid style={{ textAlign: 'center', marginTop:'-10%'}}>
            <img src={iPaisaMobScreenImage} alt="Screenshots" style={{ maxWidth: '78%'}} />
          </Grid>
        </Grid>
        
      </Grid>
      

      {/* Right Side */}
      <Grid
        item
        xs={12}
        md={6}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '-0% 3% 3% 3%', // Adjusted padding
          backgroundColor: 'white',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
          maxWidth="80%"
          padding="2%" // Adjusted padding
        >
          <img src={iPaisaLogo} style={{ marginBottom: '2%', width: '20%', height: 'auto' }} />
          <Typography variant="h5" style={{ marginBottom: '1%', color: '#3029D9', fontWeight: 'bold', marginTop: '2%' }}>
            Sign In
          </Typography>
          <Typography variant="subtitle1" style={{ marginBottom: '3%', textAlign: 'center' }}>
            Welcome to iPaisa
          </Typography>

          <Formik
            initialValues={{ userId: '', password: '' }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              console.log(values);
              setSubmitting(false);
              // You can now make an API call with the `values` object
            }}
          >
            {({ isSubmitting, errors, touched }) => (
              <Form style={{ width: '100%' }}>
                <Field
                  as={TextField}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="userId"
                  label="User Id"
                  name="userId"
                  autoComplete="userId"
                  autoFocus
                  InputLabelProps={{ shrink: true }}
                  helperText={<ErrorMessage name="userId" />}
                  error={touched.userId && Boolean(errors.userId)}
                />
                <Field
                  as={TextField}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  InputLabelProps={{ shrink: true }}
                  helperText={<ErrorMessage name="password" />}
                  error={touched.password && Boolean(errors.password)}
                />
                <Box display="flex" justifyContent="flex-end" width="100%" marginBottom='3%'>
                  <Button style={{ color: '#3029D9', textTransform: 'none' }}>Forgot MPIN?</Button>
                </Box>
                <Button
                  type="submit"
                  fullWidth
                  style={{ height: '45px', backgroundColor: '#3029D9', color: 'white', borderRadius: '5px', fontWeight: '1000', textTransform: 'none' }}
                  disabled={isSubmitting}
                >
                  Login
                </Button>
              </Form>
            )}
          </Formik>
          
          <Grid display="flex" justifyContent="center" width="100%">
            <MadeInIndiaIcon style={{ width: '15%', height: 'auto' }} />
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SignIn;
