// redux/reducers/index.js
import { combineReducers } from 'redux';
import partnerReducer from './partnerReducer'; // Import your specific reducers

const rootReducer = combineReducers({
  partner: partnerReducer,
  // Add more reducers as needed
});

export default rootReducer;
