import React, { useState } from 'react';
import { Avatar, Box, Container, Grid, Paper, Typography, TextField, IconButton, Button } from '@mui/material';
import { EditIcon } from '../../res/icons';

const UserProfile = () => {
  const [editMode, setEditMode] = useState(false);
  const userType = localStorage.getItem('uType');
  const userName = localStorage.getItem('username');

  const [formValues, setFormValues] = useState({
    firstName: '-',
    middleName: '-',
    lastName: '-',
    email: 'example@gmail.com',
    phoneNumber: '-',
    bio: 'Admin',
    country: 'India',
    postalCode: '411045',
    state: 'Maharashtra',
    taxId: 'TAX7741FG',
    city: 'Pune',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSave = () => {
    setEditMode(false);
    // Add logic to save the updated formValues to your backend or local storage here
    console.log('Saved data:', formValues);
  };

  return (
    <Container style={{ marginTop: '9%', marginBottom: '4%', borderRadius: '8px' }}>
      <Paper elevation={2} style={{ padding: '2rem' }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography style={{ fontWeight: 'bold', fontSize: '20px' }}>My Profile</Typography>
          {editMode && (
            <Button style={{ backgroundColor: '#027BFE', color: '#ffffff' }} onClick={handleSave}>
              Save
            </Button>
          )}
        </Grid>

        <Grid container style={{ border: '1px solid #D9D9D9', borderRadius: '8px', marginTop: '2%' }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={12} md={8} style={{ padding: '1rem', display: 'flex', alignItems: 'center' }}>
              <Avatar style={{ width: '70px', height: '70px' }} />
              <Box ml={2}>
                <Typography style={{ fontWeight: 'bold', fontSize: '18px' }}>{userName}</Typography>
                <Typography color="textSecondary">{userType}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} style={{ padding: '1rem' }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography color="textSecondary">Surcharge/Commission</Typography>
                  <Typography style={{ fontWeight: 'bold', fontSize: '14px' }}>---</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography color="textSecondary">Percentage</Typography>
                  <Typography style={{ fontWeight: 'bold', fontSize: '14px' }}>---</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container style={{ border: '1px solid #D9D9D9', borderRadius: '8px', marginTop: '2%' }}>
          <Grid item xs={12} style={{ padding: '1rem' }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Typography style={{ fontWeight: 'bold', fontSize: '18px' }}>Personal Information</Typography>
              <IconButton onClick={() => setEditMode(!editMode)} style={{ cursor: 'pointer' }}>
                <EditIcon />
              </IconButton>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <Typography color="textSecondary">First Name</Typography>
                {editMode ? (
                  <TextField name="firstName" value={formValues.firstName} onChange={handleInputChange} fullWidth />
                ) : (
                  <Typography style={{ fontWeight: 'bold' }}>{formValues.firstName}</Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Typography color="textSecondary">Middle Name</Typography>
                {editMode ? (
                  <TextField name="middleName" value={formValues.middleName} onChange={handleInputChange} fullWidth />
                ) : (
                  <Typography style={{ fontWeight: 'bold' }}>{formValues.middleName}</Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Typography color="textSecondary">Last Name</Typography>
                {editMode ? (
                  <TextField name="lastName" value={formValues.lastName} onChange={handleInputChange} fullWidth />
                ) : (
                  <Typography style={{ fontWeight: 'bold' }}>{formValues.lastName}</Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Typography color="textSecondary">Email Address</Typography>
                {editMode ? (
                  <TextField name="email" value={formValues.email} onChange={handleInputChange} fullWidth />
                ) : (
                  <Typography style={{ fontWeight: 'bold' }}>{formValues.email}</Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Typography color="textSecondary">Phone Number</Typography>
                <Typography style={{ fontWeight: 'bold' }}>{formValues.phoneNumber}</Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Typography color="textSecondary">Bio</Typography>
                {editMode ? (
                  <TextField name="bio" value={formValues.bio} onChange={handleInputChange} fullWidth />
                ) : (
                  <Typography style={{ fontWeight: 'bold' }}>{formValues.bio}</Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container style={{ border: '1px solid #D9D9D9', borderRadius: '8px', marginTop: '2%' }}>
          <Grid item xs={12} style={{ padding: '1rem' }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Typography style={{ fontWeight: 'bold', fontSize: '18px' }}>Address</Typography>
              <IconButton onClick={() => setEditMode(!editMode)} style={{ cursor: 'pointer' }}>
                <EditIcon />
              </IconButton>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <Typography color="textSecondary">Country</Typography>
                {editMode ? (
                  <TextField name="country" value={formValues.country} onChange={handleInputChange} fullWidth />
                ) : (
                  <Typography style={{ fontWeight: 'bold' }}>{formValues.country}</Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Typography color="textSecondary">State</Typography>
                {editMode ? (
                  <TextField name="state" value={formValues.state} onChange={handleInputChange} fullWidth />
                ) : (
                  <Typography style={{ fontWeight: 'bold' }}>{formValues.state}</Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Typography color="textSecondary">City</Typography>
                {editMode ? (
                  <TextField name="city" value={formValues.city} onChange={handleInputChange} fullWidth />
                ) : (
                  <Typography style={{ fontWeight: 'bold' }}>{formValues.city}</Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Typography color="textSecondary">Postal Code</Typography>
                {editMode ? (
                  <TextField name="postalCode" value={formValues.postalCode} onChange={handleInputChange} fullWidth />
                ) : (
                  <Typography style={{ fontWeight: 'bold' }}>{formValues.postalCode}</Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Typography color="textSecondary">Tax Id</Typography>
                {editMode ? (
                  <TextField name="taxId" value={formValues.taxId} onChange={handleInputChange} fullWidth />
                ) : (
                  <Typography style={{ fontWeight: 'bold' }}>{formValues.taxId}</Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default UserProfile;
