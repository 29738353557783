import React, { useState, useEffect } from "react";
import axios from "axios";
import BalanceTopComponent from './BalanceTopComponent'
import { Box, Button, Container, Grid, Paper, TextField, Typography } from '@mui/material'
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Walllet_Icon } from '../../res/icons';
import { toast } from "react-toastify";
import WalletBalanceComponent from "./WalletBalanceComponent";

const NewAddMoney = () => {

    const [walletBalance, setWalletBalance] = useState(0);
    const [hitApi, setHitApi] = useState(true);
    const [paymentData, setPaymentData] = useState(null);
  
    const fetchWalletBalance = async () => {
      try {
        const token = localStorage.getItem("jwtToken");
        const response = await axios.get(
          "https://api.ipaisa.co.in/myapp/auth/getwalletbalance",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setWalletBalance(response.data.walletBalance);
      } catch (error) {
        console.error("Error fetching wallet balance:", error);
      }
    };
    useEffect(() => {
      fetchWalletBalance();
    }, []);
  
    const validationSchema = Yup.object().shape({
        amount: Yup.string()
        .matches(/^[0-9]*$/, "Amount should only contain numbers")
        .test('is-positive', 'Amount must be a positive number', (value) => {
          return Number(value) > 0;
        }),
      remark: Yup.string().required("Remark is required"),
    });
  
    const formik = useFormik({
      initialValues: {
        amount: "",
        remark: "",
      },
      validationSchema: validationSchema,
      onSubmit: async (values) => {
        try {
          const token = localStorage.getItem("jwtToken");
          const response = await axios.post(
            "https://ipaisa.co.in/api/protected/add-money",
            values,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          // const updatedBalance = walletBalance + parseFloat(values.amount);
          // setWalletBalance(updatedBalance);
          formik.resetForm();
        } catch (error) {
          const errorMessage =
            error.response?.data?.message || "Error adding amount";
          //alert(errorMessage);
        }
      },
    });
  
    const initiatePayment = async (values) => {
      try {
        const paymentData = {
          txnid: "iPaisa" + new Date().getTime(),
          amount: formik.values.amount,
          productinfo: "Add Money",
          firstname: "Edsom",
          phone: "8378054080",
          email: "edsomfintech@gmail.com",
          surl: "https://ipaisa.co.in/transactionsuccess",
          furl: "https://ipaisa.co.in/transactionfailed",
        };
        setPaymentData(paymentData);
  
        const token = localStorage.getItem("jwtToken");
        const response = await axios.post(
          "https://api.ipaisa.co.in/myapp/auth/easebuzz/initiate-payment",
          paymentData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const responseBody = response.data;
  
        if (responseBody.status === 1) {
          const accessKey = responseBody.data;
          const paymentUrl = `https://pay.easebuzz.in/pay/${accessKey}`;
  
          // Open the payment URL in a new window
          const newWindow = window.open(paymentUrl, "_blank");
  
          if (newWindow) {
            const checkWindowClosed = setInterval(() => {
              if (newWindow.closed) {
                clearInterval(checkWindowClosed);
                setTimeout(() => {
                  handlePaymentStatus(paymentData.txnid);
                }, 1000); // Adjust the timeout as needed
              }
            }, 1000); // Check every second if the window is closed
  
            // Set a timer to close the window after 2 minutes
            setTimeout(() => {
              if (!newWindow.closed) {
                newWindow.close();
                console.log("Payment window closed after 2 minutes");
              }
            }, 120000); // 2 minutes in milliseconds
          }
        } else {
          console.error("Access key not received from backend");
        }
      } catch (error) {
        console.error("Error initiating payment:", error);
      }
    };
  
    const handlePaymentStatus = async (txnid) => {
      try {
        const token = localStorage.getItem("jwtToken");
  
        const response = await axios.post(
          "https://api.ipaisa.co.in/myapp/auth/transaction-api-v2",
          { txnid },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
  
        handlePaymentSuccess(response.data);
  
        // Fetch the updated wallet balance after the transaction
        await fetchWalletBalance();
  
        if (response.data) {
          toast.success("Funds Added Successfully");
        }
      } catch (error) {
        console.error("Error verifying payment:", error);
        handlePaymentSuccess({ status: 0, message: "Error verifying payment" });
      }
    };
  
  
    const handlePaymentSuccess = (response) => {
      console.log("Payment success response:", response.msg.status);
      if (response.msg.status === "userCancelled") {
        setHitApi(false);
        console.log(hitApi, "This is hit api");
      }
      if (response.status === 1) {
        alert("Payment successful");
      } else {
        alert("Payment failed: " + response.message);
      }
    };
  

    const handleAmountChange = (e) => {
        const { name, value } = e.target;
      
        let validValue = value.replace(/[^0-9]/g, '');
      
        if (validValue.startsWith('0') && validValue.length > 1) {
          validValue = validValue.replace(/^0+/, '');
        }
      
        if (validValue === '0') {
          validValue = '';
        }
      
        formik.setFieldValue(name, validValue);
      };

      const lineHeight = '50px';

  return (
    <Container style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
       <BalanceTopComponent />

       <Grid container spacing={2} style={{ marginTop: '3%' }}>
        {/* Wallet Balance Card */}
        <WalletBalanceComponent/>

       <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
        <Paper style={{ padding: '2rem', borderRadius: '8px' }}>
        <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h7">Amount</Typography>
                    <TextField
                      name="amount"
                      type="text"
                      value={formik.values.amount}
                      onChange={handleAmountChange}
                      fullWidth
                      required
                      InputLabelProps={{
                        style: { height: "2.5em" },
                      }}
                      error={formik.touched.amount && Boolean(formik.errors.amount)}
                      helperText={formik.touched.amount && formik.errors.amount}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h7">Remark</Typography>
                    <TextField
                      name="remark"
                      value={formik.values.remark}
                      onChange={formik.handleChange}
                      fullWidth
                      required
                      multiline
                      rows={4}
                      InputLabelProps={{
                        style: { height: "3.5em" },
                      }}
                      error={formik.touched.remark && Boolean(formik.errors.remark)}
                      helperText={formik.touched.remark && formik.errors.remark}
                    />
                  </Grid>
                  <Grid item xs={12}>
                  <Box display="flex" justifyContent="end" gap={1} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
                  <Button
                      sx={{
                        border: '1.5px solid #FF0000',
                        height: lineHeight,
                        fontSize: '16px',
                        borderRadius: '8px',
                        color: '#FF0000',
                        textTransform: 'none',
                        width: { xs: '100%', md: '18%' },
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                     sx={{
                        height: lineHeight,
                        fontSize: '16px',
                        textTransform:'none',
                        width: { xs: '100%', md: '18%' },
                        borderRadius: '8px',
                        backgroundColor: "#027BFE",
                        color: '#FFFFFF',
                        '&:hover': {
                            backgroundColor: "#027BFE",
                            color: '#FFFFFF',
                        },
                      }}
                      type="submit"
                      onClick={initiatePayment}
                    >
                      Add Money
                    </Button>
                    </Box>
                  </Grid>
                  
                </Grid>
              </form>
            </Paper>
        </Grid>
    </Grid>
</Container>
 )
}

export default NewAddMoney
