import React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";
import { styled } from "@mui/system";

const RootCard = styled(Card)(({ theme }) => ({
  alignItems: 'flex-start',
  backgroundColor: '#ffffff',
  border: '1px solid #80808099',
  borderRadius: '16px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  height: '348px',
  padding: '24px',
  width: '378px',
}));

const Header = styled(Typography)(({ theme }) => ({
  color: '#343c6a',
  fontFamily: "'Open Sans', Helvetica",
  fontSize: '20px',
  fontWeight: 700,
  letterSpacing: 0,
  marginTop: '-1px',
  width: '100%',
}));

const ContentBox = styled(Box)(({ theme }) => ({
  alignItems: 'flex-start',
  boxShadow: '0px 4px 4px rgba(0,0,0,0.1)',
  display: 'flex',
  flexDirection: 'column',
  gap: '22px',
  width: '100%',
}));

const CategoryCard = styled(Card)(({ theme, color }) => ({
  backgroundColor: color,
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  padding: '12px',
  width: '153px',
}));

const CategoryContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
}));

const CategoryTitle = styled(Typography)(({ theme }) => ({
  color: '#ffffff',
  fontFamily: "'Open Sans', Helvetica",
  fontSize: '12px',
  fontWeight: 600,
  letterSpacing: '0.15px',
  width: '120px',
}));

const CategoryCount = styled(Typography)(({ theme }) => ({
  color: '#ffffff',
  fontFamily: "'Open Sans', Helvetica",
  fontSize: '16px',
  fontWeight: 700,
  letterSpacing: '0.15px',
}));

const categories = [
  { name: 'Chanel Partners', color: '#092e1f' },
  { name: 'Super Distributors', color: '#8f00ff' },
  { name: 'Master Distributors', color: '#b25aef' },
  { name: 'Area Distributors', color: '#ff7b31' },
  { name: 'Retailers', color: '#00dce5' },
  { name: 'Users', color: '#027bfe' },
];

export const PartnerCards = () => {
  return (
    <RootCard>
      <Header>Records</Header>
      <ContentBox>
        {categories.map((category, index) => (
          <Box key={index} display="flex" gap="22px" width="100%">
            <CategoryCard color={category.color}>
              <CategoryContent>
                <CategoryTitle>{category.name}</CategoryTitle>
                <CategoryCount>100</CategoryCount>
              </CategoryContent>
            </CategoryCard>
          </Box>
        ))}
      </ContentBox>
    </RootCard>
  );
};
