import React, { useState } from 'react';
import { Button, Container, Grid, Paper, TextField, Typography } from '@mui/material'
import NewPayInOutTopComp from './NewPayInOutTopComp'
import { Form, Formik } from 'formik'
import * as Yup from 'yup';
import NewTransactionalTable from './NewTransactionalTable';

const NewTransactionReport = () => {

    const [searchParams, setSearchParams] = useState({
        fromDate: '',
        toDate: '',
        transactionId: '',
        partnerId: '',
        partnerName: '',
    });
    const validationSchema = Yup.object({
        fromDate: Yup.date().required('Required'),
        toDate: Yup.date().required('Required'),
    });
    const handleSearch = (values) => {
        setSearchParams(values);
    };

    const lineHeight = '50px';
  return (
    <Container style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
    <NewPayInOutTopComp/>

        <Grid style={{marginTop:'1%'}}>
            <Typography style={{fontWeight:'bold', fontFamily:'sans-serif', fontSize:'19px', color:'#343C6A'}}>Transactional Report</Typography>
        </Grid>

        <Grid style={{ marginTop: '3%' }}>
                <Paper elevation={2} sx={{ p: 3, borderRadius: 2 }}>
                        <Formik
                            initialValues={{ fromDate: '', toDate: '' }}
                            validationSchema={validationSchema}
                            onSubmit={handleSearch}
                        >
                            {({ errors, touched, setFieldValue, values }) => (
                                <Form>
                                    <Grid container spacing={4} alignItems="center" sx={{marginTop: { xs: '0%', md:'0.1%'} }}>
                                    <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                id="from-date"
                                                name="fromDate"
                                                label="From Date"
                                                type="date"
                                                value={values.fromDate}
                                                onChange={(event) => setFieldValue('fromDate', event.target.value)}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    style: { paddingTop: '0.6rem' },
                                                }}
                                                error={touched.fromDate && Boolean(errors.fromDate)}
                                                helperText={touched.fromDate && errors.fromDate}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                id="to-date"
                                                name="toDate"
                                                label="To Date"
                                                type="date"
                                                value={values.toDate}
                                                onChange={(event) => setFieldValue('toDate', event.target.value)}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    style: { paddingTop: '0.6rem' },
                                                }}
                                                error={touched.toDate && Boolean(errors.toDate)}
                                                helperText={touched.toDate && errors.toDate}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sx={{display:'flex', justifyContent:'end', marginTop: { xs: '0%', md:'1%'} }}>
                                        <Button
                                                sx={{
                                                  height: lineHeight,
                                                  fontSize: '16px',
                                                  textTransform:'none',
                                                  width: { xs: '100%', md: '18%' },
                                                  borderRadius: '8px',
                                                  backgroundColor: "#027BFE",
                                                  color: '#FFFFFF',
                                                  '&:hover': {
                                                      backgroundColor: "#027BFE",
                                                      color: '#FFFFFF',
                                                  },
                                                }}
                                                type="submit"
                                                fullWidth
                                                onClick={() => handleSearch(values)}
                                            >
                                                Search
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Paper>
             </Grid>

        <NewTransactionalTable searchParams={searchParams} />
    </Container>
  )
}

export default NewTransactionReport
