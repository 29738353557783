import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Container,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  Paper,
} from "@mui/material";
import { Walllet_Icon } from "../../res/icons";
import SearchIcon from "@mui/icons-material/Search";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const AddWalletBalance = () => {
  const [walletBalance, setWalletBalance] = useState(0);
  const [payoutType, setPayoutType] = useState(""); // State for dropdown selection
  
  const validationSchema = Yup.object().shape({
    receiverId: Yup.string()
      .required("Receiver ID is required")
      .matches(
        /^[a-zA-Z0-9]+$/,
        "Receiver ID should only contain alphanumeric characters"
      ),
    amount: Yup.number()
      .typeError("Amount must be a number")
      .positive("Amount must be positive")
      .required("Amount is required"),
    remark: Yup.string().required("Remark is required"),
  });

  const formik = useFormik({
    initialValues: {
      receiverId: "",
      receiverName: "",
      receiverType: "",
      amount: "",
      remark: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      if (values.amount > walletBalance) {
        toast.error("Insufficient wallet balance.");
        return;
      }
      try {
        const token = localStorage.getItem("jwtToken");
        const response = await axios.post(
          "https://api.ipaisa.co.in/myapp/auth/transaction",
          values,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.status === "Balance is not sufficient") {
          toast.error("Balance is not sufficient");
        } else {
          if(response.data.statusCode === "OK"){
            toast.success(
              response.data.message || "Funds transferred successfully"
            );
            fetchBalance();
            resetForm(); 

          }
          else {
            // toast.error(response.data.body.status || "User is not under you");
            toast.error("Please enter valid User ID");
          }
          
          console.log(response.data)
          fetchBalance(); // Reload the wallet balance after successful transaction
        }
      } catch (error) {
        const errorMessage =
          error.response?.data?.message || "Error transferring funds";
        toast.error(errorMessage);
      }
    },
  });

  const fetchBalance = async () => {
    try {
      const token = localStorage.getItem("jwtToken");
      const response = await axios.get(
        "https://api.ipaisa.co.in/myapp/auth/getwalletbalance",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setWalletBalance(response.data.walletBalance || 0);
    } catch (error) {
      console.error("Error fetching wallet balance:", error);
    }
  };
  useEffect(() => {
    fetchBalance();
  }, [walletBalance]);
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, value);
  };
  const fetchReceiverDetails = async () => {
    try {
      const token = localStorage.getItem("jwtToken");
      const response = await axios.get(
        `https://api.ipaisa.co.in/myapp/auth/reciverUnderSender/${formik.values.receiverId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { firstName, lastName, uType } = response.data;
      const receiverName = `${firstName} ${lastName}`; // Concatenate first and last names
      const receiverType = uType; // Assign uType to receiverType
      formik.setValues({
        ...formik.values,
        receiverName,
        receiverType,
      });
    } catch (error) {
      console.log("Error fetching receiver details:", error);
    }
  };
  const handlePayoutTypeChange = (event) => {
    setPayoutType(event.target.value);
  };
  return (
    <Container maxWidth="lg" style={{ marginTop: "5%", padding: "2%" }}>
      <ToastContainer position="top-right" autoClose={5000} />
      <Paper elevation={3} style={{ padding: "2%", borderRadius: "12px" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" gutterBottom>
              Fund Transfer
            </Typography>
            <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined" required>
                    <InputLabel>Pay Type</InputLabel>
                    <Select
                      value={payoutType}
                      onChange={handlePayoutTypeChange}
                      label="Payout Type"
                    >
                      <MenuItem value="payout">Single Pay</MenuItem>
                      <MenuItem value="bulk-payout">Bulk Pay</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Receiver ID"
                    name="receiverId"
                    value={formik.values.receiverId}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    InputProps={{
                      endAdornment: (
                        <Button
                          variant="contained"
                          onClick={fetchReceiverDetails}
                          style={{ marginLeft: 8 }}
                        >
                          <SearchIcon />
                        </Button>
                      ),
                    }}
                    error={
                      formik.touched.receiverId &&
                      Boolean(formik.errors.receiverId)
                    }
                    helperText={
                      formik.touched.receiverId && formik.errors.receiverId
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Receiver Name"
                    name="receiverName"
                    value={formik.values.receiverName}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      style: { height: "2.5em" },
                    }}
                    error={
                      formik.touched.receiverName &&
                      Boolean(formik.errors.receiverName)
                    }
                    helperText={
                      formik.touched.receiverName && formik.errors.receiverName
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    required
                    error={
                      formik.touched.receiverType &&
                      Boolean(formik.errors.receiverType)
                    }
                  >
                    <InputLabel>Receiver Type</InputLabel>
                    <Select
                      name="receiverType"
                      value={formik.values.receiverType}
                      onChange={handleInputChange}
                      label="Receiver Type"
                      readOnly
                      disabled
                    >
                      <MenuItem value="CHANNELPARTNER">Channel Partner</MenuItem>
                      <MenuItem value="SUPERDISTRIBUTOR">
                        Super Distributor
                      </MenuItem>
                      <MenuItem value="MASTERDISTRIBUTOR">
                        Master Distributor
                      </MenuItem>
                      <MenuItem value="AREADISTRIBUTOR">
                        Area Distributor
                      </MenuItem>
                      <MenuItem value="RETAILER">Retailer</MenuItem>
                    </Select>
                    {formik.touched.receiverType &&
                      formik.errors.receiverType && (
                        <Typography color="error" variant="caption">
                          {formik.errors.receiverType}
                        </Typography>
                      )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Amount"
                    name="amount"
                    type="number"
                    value={formik.values.amount}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    error={
                      formik.touched.amount && Boolean(formik.errors.amount)
                    }
                    helperText={formik.touched.amount && formik.errors.amount}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Remark"
                    name="remark"
                    value={formik.values.remark}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    multiline
                    rows={4}
                    error={
                      formik.touched.remark && Boolean(formik.errors.remark)
                    }
                    helperText={formik.touched.remark && formik.errors.remark}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Transfer Funds
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            textAlign="center"
            width={436}
            height={399}
            borderRadius={12}
          >
            <Card
              style={{
                width: "330px",
                height: "280px",
                borderRadius: "12px",
                alignContent: "center",
                marginTop:'50%',
                marginLeft:'40%',
                backgroundImage:
                  "linear-gradient(332.55deg, #121A4A 22.12%, #2B3EB0 77.43%)",
              }}
            >
              <CardContent>
                <Walllet_Icon />
                <Typography
                  color="textSecondary"
                  sx={{ color: "#FFFFFF", fontSize: "24px" }}
                  gutterBottom
                >
                  Wallet Balance
                </Typography>
                <Typography
                  variant="h5"
                  sx={{ color: "#FFFFFF", fontSize: "48px" }}
                  component="h2"
                >
                  ₹ {walletBalance.toFixed(2)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};
export default AddWalletBalance;