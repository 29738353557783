import React, { useState, useEffect } from "react";
import * as Yup from 'yup';
import { Container, Grid, IconButton, Paper, Table, TableBody, TableCell, Switch, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import PartnersTableTop from './PartnersTableTop';
import { Visibility, Edit, Delete } from '@mui/icons-material';
import { BrowserView, MobileView } from "react-device-detect";
import { toast, ToastContainer } from 'react-toastify';
import axios from "axios";
import { deletePartner, fetchPartners, togglePartnerStatus, updatePartners } from '../../redux/actions/partnerActions';
import AddUserDialog from "./AddUserDialog";
import EditUserDialog from "./EditUserDialog";
import ViewUserDialog from "./ViewUserDialog";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    evenRow: {
        backgroundColor: '#D0EFFF',
    },
    oddRow: {
        backgroundColor: '#FFFFFF',
    }
}));

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  middleName: Yup.string().required('Middle Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  dob: Yup.date().required('Date of Birth is required'),
  mobileNumber: Yup.string().required('Mobile Number is required'),
  alternateMobileNumber: Yup.string().required('Alternate Mobile Number is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
});


const ADL = () => {
  const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [open, setOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [editRow, setEditRow] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [showSearchInput, setShowSearchInput] = useState(false);
    const [userType, setUserType] = useState('');

    const partners = useSelector((state) => state.partner.partners);
    const areadistributor = partners?.AREADISTRIBUTOR || [];

    useEffect(() => {
      dispatch(fetchPartners());
    }, [dispatch]);

    useEffect(() => {
        console.log('Fetched Partners:', partners);
    }, [partners]);
    
    useEffect(() => {
      const storedUserType = localStorage.getItem('uType');
      setUserType(storedUserType);
  }, []);

      const [newUser, setNewUser] = useState({
        firstName: '',
        middleName: '',
        lastName: '',
        dob: '',
        mobileNumber: '',
        alternateMobileNumber: '',
        email: '',
        country: null,
        state: null,
        city: null,
    });
    const [addUserOpen, setAddUserOpen] = useState(false);



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClose = () => {
      setOpen(false);
      setSelectedRow(null);
  };

  const handleDelete = async (adId) => {
    try {
        const token = localStorage.getItem('jwtToken');
        await axios.delete(`/api/users/${adId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        dispatch(deletePartner(adId));
        toast.success('User deleted successfully');
    } catch (error) {
        console.error('Error deleting user:', error);
        toast.error('Error deleting user. Please try again later.');
    }
};


  const handleClickOpen = (row) => {
    setSelectedRow(row);
    setOpen(true);
};

const handleEditOpen = (row) => {
    setEditRow(row);
    setEditOpen(true);
};
    
    const handleAddUser = (values) => {
      console.log('New User:', values);
      toast.success('User added successfully');
  };

  const handleAddUserClose = () => {
      setAddUserOpen(false);
      dispatch(fetchPartners());

  };

  const handleEditClose = () => {
    setEditOpen(false);
    setEditRow(null);
};

const handleSaveEdit = async (editedUser) => {
    const editedIndex = partners.findIndex((user) => user.userid === editedUser.userid);
    if (editedIndex !== -1) {
        try {
            const token = localStorage.getItem('jwtToken');
            await axios.post('https://ipaisa.co.in/myapp/auth/updateUser', editedUser, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const newData = [...partners];
            newData[editedIndex] = editedUser;

            // Dispatch the update user action
            dispatch(updatePartners(editedUser));

            toast.success('User details updated successfully');
        } catch (error) {
            console.error('Error updating user:', error);
            toast.error('Error updating user details. Please try again later.');
        }
    } else {
        toast.error('Error updating user details');
    }
};

  const handleInputChange = (event) => {
      const { name, value } = event.target;
      if (editOpen) {
          setEditRow({ ...editRow, [name]: value });
      } else {
          setNewUser({ ...newUser, [name]: value });
      }
  };

      const handleStatusToggle = async (adId, currentStatus) => {
        try {
            const token = localStorage.getItem('jwtToken');
            const newStatus = currentStatus === 'Active' ? 'Inactive' : 'Active';

            const response = await axios.put(
                `https://api.ipaisa.co.in/myapp/admin/changeStatus/${adId}`,
                { status: newStatus },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json"
                    }
                }
            );

            // Check the response status and show the appropriate toast message
            if (response.data.message === "true") {
                dispatch(togglePartnerStatus(adId));
                toast.success(response.data.status); // Success message from the response
                dispatch(fetchPartners());
            } else {
                toast.error(`Error: ${response.data.status}`);
            }
        } catch (error) {
            console.error('Error toggling user status:', error);
            toast.error('Error toggling user status. Please try again later.');
        }
    };

    const filteredData = areadistributor.filter((row) => {
        return Object.values(row).some((value) =>
            value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        );
    });
    
  return (
    <>
    <BrowserView>
      <Container style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
        <PartnersTableTop />
        <Grid style={{ marginTop: '3%' }}>
          <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto' , borderRadius:'12px 12px 0px 0px' }}>
            <Table>
              <TableHead style={{ backgroundColor: '#027BFE' }}>
                <TableRow>
                  <TableCell sx={{ color: '#FFFFFF', textAlign: 'center', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>Sr.No</TableCell>
                  <TableCell sx={{ color: '#FFFFFF', textAlign: 'center', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>User ID</TableCell>
                  <TableCell sx={{ color: '#FFFFFF', textAlign: 'center', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>Parent ID</TableCell>
                  <TableCell sx={{ color: '#FFFFFF', textAlign: 'start', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>Name</TableCell>
                  <TableCell sx={{ color: '#FFFFFF', textAlign: 'start', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>Business Name</TableCell>
                  <TableCell sx={{ color: '#FFFFFF', textAlign: 'center', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>Mobile Number</TableCell>
                  <TableCell sx={{ color: '#FFFFFF', textAlign: 'center', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>Wallet Balance</TableCell>
                  <TableCell sx={{ color: '#FFFFFF', textAlign: 'center', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>Status</TableCell>
                  <TableCell sx={{ color: '#FFFFFF', textAlign: 'center', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                  {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                    <TableRow key={row.userid}
                     className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}>
                     <TableCell sx={{ textAlign: 'center' }}>{index + 1 + page * rowsPerPage}</TableCell>
                     <TableCell sx={{ textAlign: 'center' }}>{row.userid}</TableCell>
                     <TableCell sx={{ textAlign: 'center' }}>{row.parentId}</TableCell>
                     <TableCell sx={{ textAlign: 'start' }}>{row.firstName} {row.middleName} {row.lastName}</TableCell>
                     <TableCell sx={{ textAlign: 'start' }}>{row.bussinessName}</TableCell>
                     <TableCell sx={{ textAlign: 'center' }}>{row.mobileNumber}</TableCell>
                     <TableCell sx={{ textAlign: 'center' }}>{(Number(row.walletBalance)).toFixed(2)}</TableCell>
                     <TableCell sx={{ textAlign: 'center' }}>
                     {userType === 'ADMIN' ? (
                        <Switch
                           onChange={() => handleStatusToggle(row.userid, row.status)}
                           checked={row.status === 'ACTIVE'}
                            sx={{
                             '& .MuiSwitch-switchBase.Mui-checked': {
                                 color: 'green',},
                             '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                 backgroundColor: 'green',},
                             '& .MuiSwitch-switchBase': {
                                 color: 'red',},
                             '& .MuiSwitch-switchBase + .MuiSwitch-track': {
                                 backgroundColor: 'red',},
                                }}
                                            />
                                        ) : (
                                            <Switch
                                                checked={row.status === 'ACTIVE'}
                                                sx={{
                                                    '& .MuiSwitch-switchBase.Mui-checked': {
                                                      color: 'green',
                                                    },
                                                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                                      backgroundColor: 'green',
                                                    },
                                                    '& .MuiSwitch-switchBase': {
                                                      color: 'red',
                                                    },
                                                    '& .MuiSwitch-switchBase + .MuiSwitch-track': {
                                                      backgroundColor: 'red',
                                                    },
                                                  }}
                                                disabled
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>
                                        <IconButton onClick={() => handleClickOpen(row)}>
                                            <Visibility />
                                        </IconButton>
                                        {userType === 'ADMIN' && (
                                            <>
                                                <IconButton onClick={() => handleEditOpen(row)}>
                                                    <Edit />
                                                </IconButton>
                                                <IconButton onClick={() => handleDelete(row.userid)}>
                                                    <Delete />
                                                </IconButton>
                                            </>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
        </Grid>

        <AddUserDialog
                open={addUserOpen}
                handleClose={handleAddUserClose}
                handleAddUser={handleAddUser}
            />
            <EditUserDialog
                open={editOpen}
                handleClose={handleEditClose}
                handleSaveEdit={handleSaveEdit}
                editRow={editRow}
                handleInputChange={handleInputChange}
            />
            <ViewUserDialog
                open={open}
                handleClose={handleClose}
                selectedRow={selectedRow}
            />
        <ToastContainer />
      </Container>
    </BrowserView>

    <MobileView>
    <Container style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
        <PartnersTableTop />
        <Grid style={{ marginTop: '3%' }}>
          <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto' , borderRadius:'12px 12px 0px 0px' }}>
            <Table>
              <TableHead style={{ backgroundColor: '#027BFE' }}>
                <TableRow>
                  <TableCell sx={{ color: '#FFFFFF', textAlign: 'center', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>Sr.No</TableCell>
                  <TableCell sx={{ color: '#FFFFFF', textAlign: 'center', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>User ID</TableCell>
                  <TableCell sx={{ color: '#FFFFFF', textAlign: 'center', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>Parent ID</TableCell>
                  <TableCell sx={{ color: '#FFFFFF', textAlign: 'start', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>Name</TableCell>
                  <TableCell sx={{ color: '#FFFFFF', textAlign: 'start', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>Business Name</TableCell>
                  <TableCell sx={{ color: '#FFFFFF', textAlign: 'center', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>Mobile Number</TableCell>
                  <TableCell sx={{ color: '#FFFFFF', textAlign: 'center', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>Wallet Balance</TableCell>
                  <TableCell sx={{ color: '#FFFFFF', textAlign: 'center', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>Status</TableCell>
                  <TableCell sx={{ color: '#FFFFFF', textAlign: 'center', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                  {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                    <TableRow key={row.userid}
                     className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}>
                     <TableCell sx={{ textAlign: 'center' }}>{index + 1 + page * rowsPerPage}</TableCell>
                     <TableCell sx={{ textAlign: 'center' }}>{row.userid}</TableCell>
                     <TableCell sx={{ textAlign: 'center' }}>{row.parentId}</TableCell>
                     <TableCell sx={{ textAlign: 'start' }}>{row.firstName} {row.middleName} {row.lastName}</TableCell>
                     <TableCell sx={{ textAlign: 'start' }}>{row.bussinessName}</TableCell>
                     <TableCell sx={{ textAlign: 'center' }}>{row.mobileNumber}</TableCell>
                     <TableCell sx={{ textAlign: 'center' }}>{(Number(row.walletBalance)).toFixed(2)}</TableCell>
                     <TableCell sx={{ textAlign: 'center' }}>
                     {userType === 'ADMIN' ? (
                        <Switch
                           onChange={() => handleStatusToggle(row.userid, row.status)}
                           checked={row.status === 'ACTIVE'}
                            sx={{
                             '& .MuiSwitch-switchBase.Mui-checked': {
                                 color: 'green',},
                             '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                 backgroundColor: 'green',},
                             '& .MuiSwitch-switchBase': {
                                 color: 'red',},
                             '& .MuiSwitch-switchBase + .MuiSwitch-track': {
                                 backgroundColor: 'red',},
                                }}
                                            />
                                        ) : (
                                            <Switch
                                                checked={row.status === 'ACTIVE'}
                                                sx={{
                                                    '& .MuiSwitch-switchBase.Mui-checked': {
                                                      color: 'green',
                                                    },
                                                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                                      backgroundColor: 'green',
                                                    },
                                                    '& .MuiSwitch-switchBase': {
                                                      color: 'red',
                                                    },
                                                    '& .MuiSwitch-switchBase + .MuiSwitch-track': {
                                                      backgroundColor: 'red',
                                                    },
                                                  }}
                                                disabled
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>
                                        <IconButton onClick={() => handleClickOpen(row)}>
                                            <Visibility />
                                        </IconButton>
                                        {userType === 'ADMIN' && (
                                            <>
                                                <IconButton onClick={() => handleEditOpen(row)}>
                                                    <Edit />
                                                </IconButton>
                                                <IconButton onClick={() => handleDelete(row.userid)}>
                                                    <Delete />
                                                </IconButton>
                                            </>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
        </Grid>

        <AddUserDialog
                open={addUserOpen}
                handleClose={handleAddUserClose}
                handleAddUser={handleAddUser}
            />
            <EditUserDialog
                open={editOpen}
                handleClose={handleEditClose}
                handleSaveEdit={handleSaveEdit}
                editRow={editRow}
                handleInputChange={handleInputChange}
            />
            <ViewUserDialog
                open={open}
                handleClose={handleClose}
                selectedRow={selectedRow}
            />
        <ToastContainer />
      </Container>
    </MobileView>
  </>
  )
}

export default ADL
