import React from 'react';
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, Button, Typography, Container } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

const validationSchema = Yup.object().shape({
    amount: Yup.number().required('Amount is required').positive('Amount must be positive'),
    bankAccount: Yup.string().required('Bank Account is required'),
    paymentMode: Yup.string().required('Payment Mode is required'),
    utrNumber: Yup.number().required('UTR Number is required').positive('UTR Number must be positive'),
    bankReceipt: Yup.mixed().required('Bank Receipt is required'),
});

const OfflineBankIssue = () => {
    const handleNumberChange = (e) => {
        const { id, value } = e.target;
        const newValue = value.replace(/\D/g, ''); // Replace any non-digit character with an empty string
        formik.setFieldValue(id, newValue);
    };

    const formik = useFormik({
        initialValues: {
            amount: '',
            bankAccount: '',
            paymentMode: '',
            utrNumber: '',
            bankReceipt: null,
        },
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            const formData = new FormData();
            formData.append('issueType', 'offlinebanksissue');
            formData.append('amount', values.amount);
            formData.append('bankAccount', values.bankAccount);
            formData.append('paymentMode', values.paymentMode);
            formData.append('utrNumber', values.utrNumber);
            formData.append('bankReceipt', values.bankReceipt);

            try {
                const token = localStorage.getItem("jwtToken");
                const response = await axios.post('http://api.ipaisa.co.in/myapp/auth/create', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`,
                    },
                });
                resetForm();
            } catch (error) {
                console.error('Error submitting form:', error);
            }
        },
    });

    const inputHeight = '56px';

    return (
        <Container maxWidth="md" sx={{ marginTop: '9%' }}>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2} sx={{ marginTop: '5%' }}>
                    <Grid item xs={12} style={{marginBottom:'3%'}}>
                        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Payment Request</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="amount"
                            name="amount"
                            label="Amount"
                            value={formik.values.amount}
                            onChange={handleNumberChange}
                            onBlur={formik.handleBlur}
                            variant="outlined"
                            placeholder="Enter amount"
                            error={formik.touched.amount && Boolean(formik.errors.amount)}
                            helperText={formik.touched.amount && formik.errors.amount}
                            sx={{ marginBottom: '2%' }} // Margin between fields
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth error={formik.touched.bankAccount && Boolean(formik.errors.bankAccount)} sx={{ marginBottom: '2%' }}>
                            <InputLabel id="bank-account-label">Bank Account</InputLabel>
                            <Select
                                labelId="bank-account-label"
                                id="bankAccount"
                                name="bankAccount"
                                value={formik.values.bankAccount}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                label="Bank Account"
                            >
                                <MenuItem value="account1">Yes Bank</MenuItem>
                                {/* <MenuItem value="account2">SBI Bank</MenuItem>
                                <MenuItem value="account3">HDFC</MenuItem> */}
                            </Select>
                            {formik.touched.bankAccount && formik.errors.bankAccount && (
                                <Typography color="error">{formik.errors.bankAccount}</Typography>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth error={formik.touched.paymentMode && Boolean(formik.errors.paymentMode)} sx={{ marginBottom: '2%' }}>
                            <InputLabel id="payment-mode-label">Payment Mode</InputLabel>
                            <Select
                                labelId="payment-mode-label"
                                id="paymentMode"
                                name="paymentMode"
                                value={formik.values.paymentMode}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                label="Payment Mode"
                            >
                                <MenuItem value="mode1">NEFT/RTGS</MenuItem>
                                <MenuItem value="mode2">IMPS</MenuItem>
                                
                            </Select>
                            {formik.touched.paymentMode && formik.errors.paymentMode && (
                                <Typography color="error">{formik.errors.paymentMode}</Typography>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="utrNumber"
                            name="utrNumber"
                            label="UTR Number"
                            value={formik.values.utrNumber}
                            onChange={handleNumberChange}
                            onBlur={formik.handleBlur}
                            variant="outlined"
                            placeholder="Enter UTR number"
                            error={formik.touched.utrNumber && Boolean(formik.errors.utrNumber)}
                            helperText={formik.touched.utrNumber && formik.errors.utrNumber}
                            sx={{ marginBottom: '2%' }} // Margin between fields
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            sx={{ backgroundColor: '#027BFE', color: 'white', textTransform: 'none', height: '100%', fontSize: '15px', borderRadius: '9px', marginBottom: '2%', '&:hover': {
                                backgroundColor: '#027BFE', color: 'white'},
                            }}
                            component="label"
                            fullWidth
                        >
                            Upload Bank Receipt
                            <input
                                type="file"
                                name="bankReceipt"
                                hidden
                                onChange={(event) => {
                                    formik.setFieldValue('bankReceipt', event.currentTarget.files[0]);
                                }}
                            />
                        </Button>
                        {formik.values.bankReceipt && (
                            <Typography variant="body1">
                                Selected File: {formik.values.bankReceipt.name}
                            </Typography>
                        )}
                        {formik.touched.bankReceipt && formik.errors.bankReceipt && (
                            <Typography color="error">{formik.errors.bankReceipt}</Typography>
                        )}
                    </Grid>
                    <Grid item xs={12} container justifyContent="space-between" spacing={2}>
                        <Grid item xs={6}>
                            <Button
                                onClick={formik.handleReset}
                                sx={{backgroundColor: '#FF0000', color: '#ffffff', textTransform: 'none', width: '100%', borderRadius: '9px', height: inputHeight, '&:hover': {
                                    backgroundColor: '#FF0000', color: '#ffffff'}, }}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                type="submit"
                                sx={{ backgroundColor: '#027BFE', color: 'white', fontWeight: 'bold', textTransform: 'none', width: '100%', borderRadius: '9px', height: inputHeight, '&:hover': {
                                    backgroundColor: '#027BFE', color: 'white'}, }}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </Container>
    );
};

export default OfflineBankIssue;
