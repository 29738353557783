import React from 'react';
import { Box, Typography, Grid, Card, CardMedia, CardContent, Container } from '@mui/material';
import Image1 from '../Assets/website/Gallery/Image1.png';
import Image2 from '../Assets/website/Gallery/Image2.png';
import Image3 from '../Assets/website/Gallery/Image3.png';
import Image4 from '../Assets/website/Gallery/Image4.png';
import Image5 from '../Assets/website/Gallery/Image5.png';
import Image6 from '../Assets/website/Gallery/Image6.png';
import Footer from "./Footer";
import Header from "./Header";

const images = [
  { src: Image1, desc: 'Yoga Day' },
  { src: Image2, desc: 'Team Dinner' },
  { src: Image3, desc: 'Orientation Day' },
  { src: Image4, desc: 'Coffee With Hr' },
  { src: Image5, desc: 'Celebration for women’s Day' },
  { src: Image6, desc: 'Friendly Environment ' },

];

const Gallery = () => {
  return (
    <>
      <Header />
      <Grid marginBottom='6rem' >
        <Container
          sx={{
            backgroundColor: "white",
            padding: "2rem",
            marginTop: '3%'
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: "700",
              fontSize: { xs: "25px", sm: "30px", md: "45px" },
              textAlign: "center",
              color: "#0B1131",
              marginBottom: "2rem",
            }}
          >
            Gallery
          </Typography>
          <Grid container justifyContent="center">
            <Typography
              variant="h5"
              sx={{
                color: "#4D5156",
                fontSize: { xs: "14px", sm: "16px" },
                fontWeight: "500",
                lineHeight: "28px",
                maxWidth: "700px",
                textAlign: "start",
              }}
            >
              Stay informed and empowered with the latest insights, trends, and expert advice from the world of finance. Our blog is designed to be your go-to resource for navigating the complexities of personal and business finance. Whether you're looking to make smarter investment choices, manage your wealth more effectively, or stay updated on financial news, we have you covered.
            </Typography>
          </Grid>
          <Grid sx={{ padding: { xs: '0%', sm: '0% 15%' }, borderRadius: '16px', marginTop: '3%', backgroundColor: 'white' }}>
            <Grid container spacing={4} justifyContent="center">
              {images.map((image, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Card sx={{ borderRadius: '8px', margin: 'auto' }}>
                    <CardMedia
                      component="img"
                      height="250"
                      image={image.src}
                      alt={`Gallery Image ${index + 1}`}
                      sx={{ borderRadius: '8px' }}
                    />
                    <CardContent sx={{ position: 'relative', padding: '0 !important' }}>
                      <Typography
                        variant="subtitle1"
                        align="left"
                        sx={{
                          position: 'absolute',
                          bottom: 16,
                          left: 16,
                          color: 'white',
                          fontWeight: 'bold',
                          backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          borderRadius: '8px',
                          padding: '4px 8px',
                        }}
                      >
                        {image.desc}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Footer/>
    </>
  );
};

export default Gallery;
