import BalanceB_SVG from './balance_black.svg';
import Compensatiopn_SVG from './compansation_black.svg';
import partner_SVG from './partner_black.svg';
import ReportBlack_SVG from './report_black.svg';
import ServiceBlack from './services_black.svg';
import SettingBlack from './setting_black.svg';
import SupportBlack from './support_black.svg';
import TicketBlack from './ticket_black.svg';
import LogoutBlack from './logout_black.svg';
import DashboardBlack from './dashboard_black.svg';


export const BalanceBlackIcon = (hg, wd) => {
    return <img src={BalanceB_SVG} alt='BalanceB_SVG' style={{height:'23px'}}/>
}

export const DashboardBlackIcon = (hg, wd) => {
    return <img src={DashboardBlack} alt='DashboardBlack' style={{height:'23px'}}/>
}

export const CompensationBlackIcon = (hg, wd) => {
    return <img src={Compensatiopn_SVG} alt='Compensatiopn_SVG' style={{height:'23px'}}/>
}

export const PartnerBlackIcon = (hg, wd) => {
    return <img src={partner_SVG} alt='Partner_SVG' style={{height:'23px'}}/>
}

export const ReportBlackIcon = (hg, wd) => {
    return <img src={ReportBlack_SVG} alt='ReportBlack_SVG' style={{height:'23px'}}/>
}
export const ServiceBlackIcon = (hg, wd) => {
    return <img src={ServiceBlack} alt='ServiceBlack' style={{height:'23px'}}/>
}
export const SettingsBlackIcon = (hg, wd) => {
    return <img src={SettingBlack} alt='SettingBlack' style={{height:'23px'}}/>
}
export const SupportBlackIcon = (hg, wd) => {
    return <img src={SupportBlack} alt='SupportBlack' style={{height:'23px'}}/>
}
export const TicketBlackIcon = (hg, wd) => {
    return <img src={TicketBlack} alt='TicketBlack' style={{height:'23px'}}/>
}

export const LogoutBlackIcon = (hg, wd) => {
    return <img src={LogoutBlack} alt='LogoutBlack' style={{height:'23px'}}/>
}




