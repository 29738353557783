import React from 'react';
import { Container, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, TablePagination } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { makeStyles } from '@mui/styles';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import iPaisaLogo from '../Assets/iPaisaLogo/iPaisaLogo.jpg'; // Adjust this path if needed

const useStyles = makeStyles((theme) => ({
    tableRow: {
        '& > *': {
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
        },
    },
    evenRow: {
        backgroundColor: '#D0EFFF',
    },
    oddRow: {
        backgroundColor: '#FFFFFF',
    },
    creditText: {
        color: 'green',
    },
    debitText: {
        color: 'red',
    },
}));

const FTRTable = ({ debitData, creditData }) => {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const data = [...debitData, ...creditData]; // Merge debit and credit data

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const generatePDF = () => {
        const doc = new jsPDF();
        const logo = new Image();
        logo.src = iPaisaLogo;

        logo.onload = () => {
            doc.addImage(logo, 'PNG', 10, 10, 30, 30); // Adjust position and size as needed
            doc.setFont('helvetica', 'bold');
            doc.setFontSize(16);
            doc.text('Transaction Statement', 50, 20);
            doc.setFontSize(12);
            doc.text('24 Jun, 2024 - 24 Jul, 2024', 50, 30);

            const tableColumn = ["Date", "Transaction Details", "Type", "Amount"];
            const tableRows = [];

            data.forEach(row => {
                const rowData = [
                    new Date(row.date).toLocaleString(),
                    row.transactionType === 'DEBIT' 
                        ? `Paid to ${row.senderName}\nTransaction ID ${row.tranxid}\nUTR No. ${row.remark}\nPaid by ${row.senderNumber}`
                        : `Received from ${row.senderName}\nTransaction ID ${row.tranxid}\nUTR No. ${row.remark}\nCredited to ${row.senderNumber}`,
                    row.transactionType,
                    `${row.amount.toFixed(2)}`
                ];
                tableRows.push(rowData);
            });

            doc.autoTable({
                head: [tableColumn],
                body: tableRows,
                startY: 50,
                styles: {
                    fontSize: 10,
                    cellPadding: 3,
                    overflow: 'linebreak',
                },
                headStyles: {
                    fillColor: [22, 160, 133],
                    textColor: [255, 255, 255],
                    fontSize: 12,
                },
            });

            doc.save('Transaction_Statement.pdf');
        };
    };

    return (
        <Container maxWidth="lg" style={{ marginTop: '20px' }}>
            <Box sx={{ mb: 2, textAlign: 'right' }}>
                <Button
                    onClick={generatePDF}
                    variant="contained"
                    color="primary"
                    startIcon={<DownloadIcon />}
                >
                    Download PDF
                </Button>
            </Box>
            <Paper elevation={3}>
                <TableContainer>
                    <Table>
                        <TableHead style={{backgroundColor: '#027BFE', color: 'white'}}>
                            <TableRow>
                                <TableCell style={{color:'white'}} align="center">Sr. No.</TableCell>
                                <TableCell style={{color:'white'}} align="center">Sender Name</TableCell>
                                <TableCell style={{color:'white'}} align="center">Sender ID</TableCell>
                                <TableCell style={{color:'white'}} align="center">Transaction ID</TableCell>
                                <TableCell style={{color:'white'}} align="center">Amount</TableCell>
                                <TableCell style={{color:'white'}} align="center">Date</TableCell>
                                <TableCell style={{color:'white'}} align="center">Remark</TableCell>
                                <TableCell style={{color:'white'}} align="center">Sender Number</TableCell>
                                <TableCell style={{color:'white'}} align="center">Transaction Type</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                 <TableRow 
                                 key={index} 
                                 className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}>
                                 <TableCell align="center">{index + 1}</TableCell>
                                 <TableCell align="center">{row.senderName}</TableCell>
                                 <TableCell align="center">{row.senderId}</TableCell>
                                 <TableCell align="center">{row.tranxid}</TableCell>
                                 <TableCell align="center">{`₹${row.amount.toFixed(2)}`}</TableCell>
                                 <TableCell align="center">{new Date(row.date).toLocaleDateString()}</TableCell>
                                 <TableCell align="center">{row.remark}</TableCell>
                                 <TableCell align="center">{row.senderNumber}</TableCell>
                                 <TableCell 
                                     align="center" 
                                     className={row.transactionType === 'CREDIT' ? classes.creditText : classes.debitText}
                                 >
                                     {row.transactionType}
                                 </TableCell>
                             </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Container>
    );
};

export default FTRTable;
