import React from 'react'
import { Button, Grid, Paper, Typography } from '@mui/material'
import { CloseIcon, FailedPopIcon} from '../../res/icons'

const FailedPop = () => {
  return (
    <Grid style={{marginTop:'10%', padding:'2rem'}}>
    <Paper style={{width:'33%', height:'100%'}}>
        <Grid style={{padding:'1rem'}}>
        <Grid style={{display:'flex', justifyContent:'flex-end'}}>
        <CloseIcon/>
        </Grid>
        <Grid style={{display:'flex', justifyContent:'center', }}>
            <FailedPopIcon/>
        </Grid>
        <Grid style={{display:'flex', justifyContent:'center', marginTop:'6%'}}>
            <Typography style={{fontWeight:'bold', fontSize:'20px'}}>Fund Transfer Failed!</Typography>
        </Grid>
        <Grid style={{display:'flex', justifyContent:'center', marginTop:'6%'}}>
            <Typography color='textSecondary' style={{fontSize:'15px', textAlign:'center'}}>
            We regret to inform you that your fund transfer could not be completed.
            Reason: You cannot send a transaction to a sub-partner ID. 
            </Typography>
        </Grid>
        <Grid style={{display:'flex', justifyContent:'center', marginTop:'6%', marginBottom:'4%'}}>
            <Button style={{color:'white', backgroundColor:'#185ABD', width:'20%'}}>
                OK
            </Button>
        </Grid>
        </Grid>
    </Paper>
    </Grid>
  )
}

export default FailedPop
