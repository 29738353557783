import React, {useState} from 'react'
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputBase, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material'
import SupportsTopComp from './SupportsTopComp'
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from '@mui/styles';
import { DeleteUserIcon, ViewDetailsIcon } from '../../res/NewWebIcons';

const useStyles = makeStyles((theme) => ({
    evenRow: {
        backgroundColor: '#D0EFFF',
    },
    oddRow: {
        backgroundColor: '#FFFFFF',
    }
}));

const initialData = [
    { srNo: 1, ticket: 'FTS0001', date: '03-06-2024 04:14 PM', name: 'John Doe', usertype: 'DEMO', subject: 'none'},
    { srNo: 2, ticket: 'FTS0002', date: '03-06-2024 05:30 PM', name: 'Jane Doe', usertype: 'DEMO', subject: 'none'},
];


const NewUserCreation = () => {

    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [open, setOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [data, setData] = useState(initialData);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpen = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };


    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = (index) => {
        const newData = [...data];
        newData.splice(index + page * rowsPerPage, 1);
        setData(newData);
    };

    const filteredData = data.filter((row) => {
        return Object.values(row).some((value) =>
            value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        );
    });    

    const handleInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

  return (
    <Container style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
    <SupportsTopComp/>

        <Grid style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginTop:'1%'}}>
                <Grid>
                <Typography style={{fontWeight:'bold', fontFamily:'sans-serif', fontSize:'22px', color:'#343C6A'}}>User Creation Support </Typography>
                </Grid>
            
                <Grid style={{
                    display:'flex',
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    maxWidth: '60%',
                    borderRadius: 10,
                    border: '1px solid rgba(0, 0, 0, 0.3)',
                    }}>

                <IconButton>
                    <SearchIcon style={{ color: 'rgba(0, 0, 0, 0.5)' }} />
                </IconButton>
                <InputBase
                    style={{ color: 'rgba(0, 0, 0, 3)' }}
                    placeholder="Search"
                    sx={{ width: '100%'}}
                    value={searchQuery}
                    onChange={handleInputChange}
                />
                </Grid>
        </Grid>

        <Grid style={{ marginTop: '3%' }}>
            <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto', borderRadius:'12px 12px 0px 0px'  }}>
                    <Table>
                        <TableHead style={{ backgroundColor: '#027BFE' }}>
                            <TableRow>
                                <TableCell style={{color:'white' , textAlign: 'start' }}>Sr.No</TableCell>
                                <TableCell style={{color:'white' , textAlign: 'start' }}>Ticket ID</TableCell>
                                <TableCell style={{color:'white' , textAlign: 'start' }}>Date & Time</TableCell>
                                <TableCell style={{color:'white' , textAlign: 'start' }}>Name</TableCell>
                                <TableCell style={{color:'white' , textAlign: 'start' }}>User Type</TableCell>
                                <TableCell style={{color:'white' , textAlign: 'start' }}>Subject</TableCell>
                                <TableCell style={{color:'white' , textAlign: 'center' }}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredData
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const shouldDisplayRow = Object.values(row).some((value) =>
                                        value.toString().toLowerCase().includes(searchQuery.toLowerCase())
                                    );

                                    if (!shouldDisplayRow) {
                                        return null;
                                    }

                                    return (
                                        <TableRow key={index}
                                        className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}>
                                            <TableCell sx={{ textAlign: 'start' }}>{row.srNo}</TableCell>
                                            <TableCell sx={{ textAlign: 'start' }}>{row.ticket}</TableCell>
                                            <TableCell sx={{ textAlign: 'start' }}>{row.date}</TableCell>
                                            <TableCell sx={{ textAlign: 'start' }}>{row.name}</TableCell>
                                            <TableCell sx={{ textAlign: 'start' }}>{row.usertype}</TableCell>
                                            <TableCell sx={{ textAlign: 'start' }}>{row.subject}</TableCell>
                                        
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <IconButton color="primary" aria-label="view" onClick={() => handleClickOpen(row)}>
                                                    <ViewDetailsIcon/>
                                                </IconButton>
                                                <IconButton color="secondary" aria-label="delete" onClick={() => handleDelete(row)}>
                                                    <DeleteUserIcon/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
            </TableContainer>
                <Grid container justifyContent="flex-end">
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={data.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                </Grid>
        </Grid>
        
        <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
            <DialogTitle bgcolor={'#027BFE'} color={'#ffffff'} strong>User Details</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6} marginTop={5} borderRadius={10} border={1} boxShadow={3}>
                        {selectedRow && (
                            <div>
                                <p><strong>Ticket ID:</strong> {selectedRow.ticket}</p>
                                <p><strong>Name:</strong> {selectedRow.name}</p>
                                <p><strong>User Type:</strong> {selectedRow.usertype}</p>
                                <p><strong>Date & Time:</strong> {selectedRow.date}</p>
                                <p><strong>DOB:</strong> {'17-07-1998'}</p>
                                <p><strong>Address:</strong> {selectedRow.address}</p>
                                <p><strong>City:</strong> {selectedRow.address}</p>
                                <p><strong>State:</strong> {selectedRow.address}</p>
                                <p><strong>Pincode:</strong> {selectedRow.address}</p>
                                <p><strong>Wallet Balance:</strong> {selectedRow.walletBalance}</p>
                                <p><strong>Status:</strong> {selectedRow.status}</p>
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        <p><strong>Uploaded Agreement:</strong></p>
                        <iframe
                            src={`${process.env.PUBLIC_URL}/IpaisaApplication.pdf`}
                            width="100%"
                            height="400px"
                            style={{ border: 'none' }}
                            title="PDF Document"
                        ></iframe>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} style={{backgroundColor: '#FF0000', color: '#ffffff', textTransform:'none'}}>Close</Button>
            </DialogActions>
        </Dialog>

</Container>
  )
}

export default NewUserCreation
