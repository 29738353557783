import {
  FETCH_PARTNERS_REQUEST,
  FETCH_PARTNERS_SUCCESS,
  FETCH_PARTNERS_FAILURE,
  UPDATE_PARTNERS,
  DELETE_PARTNER,
  TOGGLE_PARTNER_STATUS,
} from '../actionTypes';

const initialState = {
  loading: false,
  partners: {
    CHANNELPARTNER: [],
    SUPERDISTRIBUTOR: [],
    MASTERDISTRIBUTOR: [],
    AREADISTRIBUTOR: [],
    RETAILER: [],
    ADMIN: [],
  },
  error: null,
};

const updatePartnerInCategory = (category, updatedPartner) =>
  category.map(partner => (partner.userid === updatedPartner.userid ? updatedPartner : partner));

const deletePartnerFromCategory = (category, userId) =>
  category.filter(partner => partner.userid !== userId);

const togglePartnerStatusInCategory = (category, userId, newStatus) =>
  category.map(partner =>
    partner.userid === userId ? { ...partner, status: newStatus } : partner
  );

const partnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PARTNERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_PARTNERS_SUCCESS:
      return {
        ...state,
        loading: false,
        partners: action.payload,
        error: null,
      };
    case FETCH_PARTNERS_FAILURE:
      return {
        ...state,
        loading: false,
        partners: initialState.partners,
        error: action.payload,
      };
    case UPDATE_PARTNERS:
      return {
        ...state,
        partners: {
          ...state.partners,
          [action.payload.utype]: updatePartnerInCategory(state.partners[action.payload.utype], action.payload),
        },
      };
    case DELETE_PARTNER:
      return {
        ...state,
        partners: Object.keys(state.partners).reduce((acc, key) => {
          acc[key] = deletePartnerFromCategory(state.partners[key], action.payload);
          return acc;
        }, {}),
      };
    case TOGGLE_PARTNER_STATUS:
      return {
        ...state,
        partners: Object.keys(state.partners).reduce((acc, key) => {
          acc[key] = togglePartnerStatusInCategory(state.partners[key], action.payload.cpId, action.payload.newStatus);
          return acc;
        }, {}),
      };
    default:
      return state;
  }
};

export default partnerReducer;
