import { Container, Grid, Typography, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, IconButton } from '@mui/material';
import React, {useState, useEffect} from 'react';
import NewPayInOutTopComp from './NewPayInOutTopComp';
import { makeStyles } from '@mui/styles';
import { styled, keyframes } from '@mui/system';


const useStyles = makeStyles(() => ({
    tableRow: {
        '& > *': {
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
        },
    },
    evenRow: {
        backgroundColor: '#D0EFFF',
    },
    oddRow: {
        backgroundColor: '#FFFFFF',
    }
}));

const NewSurchargeReport = () => {
  const classes = useStyles();
  const totalSurcharge = 12345.67;
  const totalPaid = 9876.54;

  const columns = [
      { id: 'srNo', label: 'Sr.No' },
      { id: 'date', label: 'Date' },
      { id: 'transactionId', label: 'Transaction Id' },
      { id: 'partnerName', label: 'Partner Name' },
      { id: 'partnerAccountNumber', label: 'Partner Account Number' },
      { id: 'SurchargeAmount', label: 'Surcharge Amount' },
      { id: 'totalSurchargeAmount', label: 'Total Surcharge Amount' },
      { id: 'description', label: 'Description' },
      { id: 'SurchargeStatus', label: 'Surcharge Status' },
      { id: 'senderName', label: 'Sender Name' },
  ];

  const rows = [
      {
          srNo: 1,
          date: '2024-05-01',
          transactionId: 'TXN123456',
          partnerName: 'Partner 1',
          partnerAccountNumber: 'ACC123456',
          SurchargeAmount: 100.0,
          totalSurchargeAmount: 12345.67,
          description: 'Surcharge for April',
          SurchargeStatus: 'Paid',
          senderName: 'Sender 1',
      },
      // Add more rows as needed
  ];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
      setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
  };



// const filteredRows = rows.filter(row => {
//     const fromDate = new Date(searchParams.fromDate);
//     const toDate = new Date(searchParams.toDate);
//     const transactionDate = new Date(row.transactionDate);

//     return (
//         (!searchParams.fromDate || transactionDate >= fromDate) &&
//         (!searchParams.toDate || transactionDate <= toDate) &&
//         (!searchParams.transactionId || row.transactionId.includes(searchParams.transactionId)) &&
//         (!searchParams.partnerId || row.partnerId.includes(searchParams.partnerId)) &&
//         (!searchParams.partnerName || row.partnerName.toLowerCase().includes(searchParams.partnerName.toLowerCase()))
//     );
// });

  return (
    <Container sx={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
      <NewPayInOutTopComp />

      <Grid sx={{ marginTop: '1%' }}>
        <Typography
          sx={{
            fontWeight: 'bold',
            fontFamily: 'sans-serif',
            fontSize: '19px',
            color: '#343C6A',
          }}
        >
          Surcharge Report
        </Typography>
      </Grid>

      <Grid container justifyContent="center" alignItems="center" sx={{ marginTop: '3%' }}>
        <Paper
          sx={{
            padding: '2rem',
            background: 'linear-gradient(135deg, #413DFF, #547AFF)',
            borderRadius: '12px',
            width: { xs: '100%', sm: '75%', md: '35%' },
            textAlign: 'center',
          }}
          elevation={3}
        >
          <Typography
            sx={{
              fontFamily: 'sans-serif',
              fontSize: '16px',
              color: 'white',
            }}
          >
            Total Surcharge
          </Typography>
          <Typography
            sx={{
              marginTop: '1rem',
              fontFamily: 'sans-serif',
              fontSize: '20px',
              color: 'white',
            }}
          >
            ₹ {totalSurcharge}
          </Typography>
        </Paper>
      </Grid>

      <Grid sx={{marginTop:'3%'}}>
         <Grid>
            <Grid sx={{display:'flex', justifyContent:'space-between', alignItems:'center', marginTop:'1%'}}>
                <Grid>
                <Typography sx={{fontWeight:'bold', fontFamily:'sans-serif', fontSize:'19px', color:'#343C6A'}}>History</Typography>
                </Grid>
            
                    {/* <Button sx={{textTransform:'none', backgroundColor:'#027BFE', color:'#FFFFFF', borderRadius:'10px', display:'flex', alignItems:'center', justifyContent:'center', fontFamily:'sans-serif', fontSize:'14px', width:'20%', gap:'5%'}}
                    onClick={generatePDF}
                    >
                    <DownloadIcon/>
                    Download PDF File</Button> */}
            </Grid>
        </Grid>

        <TableContainer component={Paper} sx={{ marginTop:'2%',maxWidth: '100%', overflowX: 'auto', borderRadius:'12px 12px 0px 0px'  }}>
                        <Table>
                            <TableHead sx={{backgroundColor: '#027BFE'}}>
                                <TableRow>
                                    <TableCell sx={{color:'white' , textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Sr. No.</TableCell>
                                    <TableCell sx={{color:'white' , textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Transaction Date</TableCell>
                                    <TableCell sx={{color:'white' , textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Transaction Id</TableCell>
                                    <TableCell sx={{color:'white' , textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Partner Name</TableCell>
                                    <TableCell sx={{color:'white' , textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Partner Account Number</TableCell>
                                    <TableCell sx={{color:'white' , textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Surcharge Amount</TableCell>
                                    <TableCell sx={{color:'white' , textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Total Surcharge Amount</TableCell>
                                    <TableCell sx={{color:'white' , textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Description</TableCell>
                                    <TableCell sx={{color:'white' , textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Surcharge Status</TableCell>
                                    <TableCell sx={{color:'white' , textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Sender Name</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                    <TableRow key={row.id} 
                                    className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}>
                                        <TableCell sx={{ textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>{index + 1}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>{row.date}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>{row.transactionId}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>{row.partnerName}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>{row.partnerAccountNumber}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>{row.SurchargeAmount}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>{row.totalSurchargeAmount}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>{row.description}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>{row.SurchargeStatus}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>{row.senderName}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
    </Grid>

      
    </Container>
  );
};

export default NewSurchargeReport;
