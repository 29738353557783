import React from 'react';
import { Container, Grid, Paper, Typography, Box } from '@mui/material';
import movieticket from '../Assets/Services/movieticket.svg';
import flightticket from '../Assets/Services/flightticket.svg';
import busticket from '../Assets/Services/busticket.svg';
import trainticket from '../Assets/Services/trainticket.svg';
import { useNavigate } from 'react-router-dom';


const bookAndBuyServices = [
  { name: 'Movie Tickets', icon: <img src={movieticket} alt="Movie Tickets" style={{ width: 40, height: 40 }} />, onClick: 'handleRMovieTicketClick' },
  { name: 'Flight Tickets', icon: <img src={flightticket} alt="Flight Tickets" style={{ width: 40, height: 40 }} />, onClick: 'handleRFlightTicketClick' },
  { name: 'Bus Tickets', icon: <img src={busticket} alt="Bus Tickets" style={{ width: 40, height: 40 }} />, onClick: 'handleRBusTicketsClick' },
  { name: 'Train Tickets', icon: <img src={trainticket} alt="Train Tickets" style={{ width: 40, height: 40 }} />, onClick: 'handleTrainTicketClick' },
];

const BookandBuy = () => {
  const navigate = useNavigate();

  const handleNavigation = (handler) => {
    switch (handler) {
      case 'handleRMovieTicketClick':
        navigate('/rmovieticket');
        break;
      case 'handleRFlightTicketClick':
        navigate('/rflightbook');
        break;
      case 'handleRBusTicketsClick':
        navigate('/rbustickets');
        break;
      case 'handleTrainTicketClick':
        navigate('/trainticket');
        break;
      default:
        break;
    }
  };

  return (
    <Container maxWidth="lg" sx={{ marginTop: '8%', marginBottom: '3%' }}>
      
      <Paper elevation={2} sx={{ padding: 4, minHeight: 100, height: '300px', borderRadius: 8, marginTop: '2%' }}>
        <Typography variant="h4" align="left" marginTop={3} gutterBottom>
          Book & Buy from iPaisa
        </Typography>
        <Grid container spacing={1} sx={{ display: 'flex', flexWrap: 'wrap', marginTop: '2%' }}>
          <Grid item xs={12} container sx={{ display: 'flex', justifyContent: 'space-evenly', overflowX: 'auto', marginBottom: 0 }}>
            {bookAndBuyServices.map((service, index) => (
              <Grid item key={index} sx={{ width: 150, height: '220px' }}>
                <Paper
                  elevation={3}
                  style={{ padding: '20px', textAlign: 'center', backgroundColor: '#EDF6FF', height: '160px' }}
                  onClick={() => handleNavigation(service.onClick)}
                >
                  <Box display="flex" flexDirection="column" alignItems="center" height={153} marginTop={4}>
                    {service.icon}
                    <Typography variant="h6" style={{ marginTop: '10px' }}>
                      {service.name}
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default BookandBuy;
