import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { useNavigate } from "react-router-dom";

// Add the Toastify CSS file to your project to style notifications

import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Grid,
  TablePagination,
  Switch,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  MenuItem,
} from "@mui/material";
import { Visibility, Edit, Delete, Add } from "@mui/icons-material";
import axios from "axios";

// Add the ToastContainer component from react-toastify to your App.js or root component

const initialData = [
  {
    srNo: 1,
    date: "25-06-2024",
    blogtitle: "Funday",
    blogby: "Tripathi",
    status: "Active",
  },
  {
    srNo: 2,
    date: "25-06-2024",
    blogtitle: "Funday",
    blogby: "Jane Doe",
    status: "Inactive",
  },
];

const validationSchema = Yup.object().shape({
  // Add validation rules for firstName
  firstName: Yup.string().required("First Name is required"),
  // Add validation rules for middleName
  middleName: Yup.string().required("Middle Name is required"),
  // Add validation rules for lastName
  lastName: Yup.string().required("Last Name is required"),
  // Add validation rules for dob (Date of Birth)
  dob: Yup.date().required("Date of Birth is required"),
  // Add validation rules for mobileNumber
  mobileNumber: Yup.string().required("Mobile Number is required"),
  // Add validation rules for alternateMobileNumber
  alternateMobileNumber: Yup.string().required(
    "Alternate Mobile Number is required"
  ),
  // Add validation rules for email
  email: Yup.string().email("Invalid email").required("Email is required"),
});

const BlogsUpdater = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [editRow, setEditRow] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState(initialData);
  const [newUser, setNewUser] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    dob: "",
    mobileNumber: "",
    alternateMobileNumber: "",
    email: "",
    country: null,
    state: null,
    city: null,
  });
  const navigate = useNavigate();

  const handleAddBlogsClick = () => {
    navigate("/blogsuploader");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpen = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleEditOpen = (row) => {
    setEditRow(row);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
    setEditRow(null);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (editOpen) {
      setEditRow({ ...editRow, [name]: value });
    } else {
      setNewUser({ ...newUser, [name]: value });
    }
  };

  const handleDelete = async (cpId) => {
    try {
      const token = localStorage.getItem("jwtToken");
      // Make an API call to delete the user with the given cpId
      await axios.delete(`/api/users/${cpId}`, {
        Authorization: `Bearer ${token}`, // Add Bearer token to the headers
      });

      // Remove the deleted row from the local data state
      const newData = data.filter((row) => row.cpId !== cpId);
      setData(newData);

      toast.success("User deleted successfully");
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error("Error deleting user. Please try again later.");
    }
  };

  const handleStatusToggle = async (cpId, currentStatus) => {
    try {
      const token = localStorage.getItem("jwttoken");

      // Determine the new status based on the current status
      const newStatus = currentStatus === "Active" ? "Inactive" : "Active";

      // Make an API call to update the user's status
      const response = await axios.put(`/api/users/changeStatus/${cpId}`, {
        Authorization: `Bearer ${token}`, // Add Bearer token to the headers
      });

      // Update the status in the local data state
      setData((prevData) => {
        return prevData.map((user) => {
          if (user.cpId === cpId) {
            return { ...user, status: newStatus };
          }
          return user;
        });
      });

      toast.success(`User status changed to ${newStatus}`);
    } catch (error) {
      console.error("Error toggling user status:", error);
      toast.error("Error toggling user status. Please try again later.");
    }
  };

  const filteredData = data.filter((row) => {
    return Object.values(row).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    );
  });
  return (
    <Container maxWidth="xl" style={{ marginTop: "7%" }}>
      <Box mt={4} mb={2}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography style={{ fontWeight: "bold", fontSize: "25px" }}>
              Blogs
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              style={{ marginBottom: "2%", backgroundColor: "green" }}
              onClick={handleAddBlogsClick}
            >
              {" "}
              <ControlPointIcon
                style={{ marginLeft: "-5%", paddingRight: "2%" }}
              />{" "}
              Add New
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="flex-end"
          paddingTop="2%"
          alignItems="center"
        >
          <Grid item>
            <TextField
              label="Search"
              variant="outlined"
              value={searchQuery}
              onChange={handleInputChange}
              style={{ marginBottom: "2%", width: "100%" }}
            />
          </Grid>
        </Grid>
        <TableContainer
          component={Paper}
          style={{ marginTop: "2%", justifyContent: "space-between" }}
        >
          <Table>
            <TableHead style={{ backgroundColor: '#027BFE'}}>
              <TableRow>
                <TableCell style={{color:'white'}}>Sr.No</TableCell>
                <TableCell style={{color:'white'}}>Date</TableCell>
                <TableCell style={{color:'white'}}>Blog Title</TableCell>
                <TableCell style={{color:'white'}}>Blog By</TableCell>
                <TableCell style={{color:'white'}}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const shouldDisplayRow = Object.values(row).some((value) =>
                    value
                      .toString()
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase())
                  );

                  if (!shouldDisplayRow) {
                    return null;
                  }

                  return (
                    <TableRow key={index}>
                      <TableCell>{row.srNo}</TableCell>
                      <TableCell>{row.date}</TableCell>
                      <TableCell>{row.blogtitle}</TableCell>
                      <TableCell>{row.blogby}</TableCell>
                      <TableCell>
                        <IconButton
                          color="primary"
                          aria-label="view"
                          onClick={() => handleClickOpen(row)}
                        >
                          <Visibility />
                        </IconButton>
                        <IconButton
                          color="primary"
                          aria-label="edit"
                          onClick={() => handleEditOpen(row)}
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          color="secondary"
                          aria-label="delete"
                          onClick={() => handleDelete(row.cpId)}
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container justifyContent="flex-end">
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Box>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle bgcolor={"#D1C4E9"} strong>
          User Details
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid
              item
              xs={6}
              marginTop={5}
              borderRadius={10}
              border={1}
              boxShadow={3}
            >
              {selectedRow && (
                <div>
                  <p>
                    <strong>CP ID:</strong> {selectedRow.cpId}
                  </p>
                  <p>
                    <strong>Parent ID:</strong> {selectedRow.parentId}
                  </p>
                  <p>
                    <strong>Name:</strong> {selectedRow.name}
                  </p>
                  <p>
                    <strong>Business Name:</strong> {selectedRow.businessName}
                  </p>
                  <p>
                    <strong>Mobile Number:</strong> {selectedRow.mobileNumber}
                  </p>
                  <p>
                    <strong>DOB:</strong> {"17-07-1998"}
                  </p>
                  <p>
                    <strong>Address:</strong> {selectedRow.address}
                  </p>
                  <p>
                    <strong>City:</strong> {selectedRow.address}
                  </p>
                  <p>
                    <strong>State:</strong> {selectedRow.address}
                  </p>
                  <p>
                    <strong>Pincode:</strong> {selectedRow.address}
                  </p>
                  <p>
                    <strong>Wallet Balance:</strong> {selectedRow.walletBalance}
                  </p>
                  <p>
                    <strong>Status:</strong> {selectedRow.status}
                  </p>
                </div>
              )}
            </Grid>
            <Grid item xs={6}>
              <p>
                <strong>Uploaded Agreement:</strong>
              </p>
              <iframe
                src={`${process.env.PUBLIC_URL}/IpaisaApplication.pdf`}
                width="100%"
                height="400px"
                style={{ border: "none" }}
                title="PDF Document"
              ></iframe>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default BlogsUpdater;
