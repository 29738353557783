import axios from 'axios';
import {
  FETCH_PARTNERS_REQUEST,
  FETCH_PARTNERS_SUCCESS,
  FETCH_PARTNERS_FAILURE,
  UPDATE_PARTNERS,
  DELETE_PARTNER,
  TOGGLE_PARTNER_STATUS,
} from '../actionTypes';

export const fetchPartnersRequest = () => ({
  type: FETCH_PARTNERS_REQUEST,
});

export const fetchPartnersSuccess = (partners) => ({
  type: FETCH_PARTNERS_SUCCESS,
  payload: partners,
});

export const fetchPartnersFailure = (error) => ({
  type: FETCH_PARTNERS_FAILURE,
  payload: error,
});

export const fetchPartners = () => {
  return async (dispatch) => {
    dispatch(fetchPartnersRequest());
    try {
      const token = localStorage.getItem('jwtToken');

      if (!token) {
        throw new Error('No JWT token found in local storage');
      }

      const response = await axios.get('https://api.ipaisa.co.in/myapp/auth/list', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const partnerMap = {
          CHANNELPARTNER: [],
          SUPERDISTRIBUTOR: [],
          MASTERDISTRIBUTOR: [],
          AREADISTRIBUTOR: [],
          RETAILER: [],
          ADMIN: [],
        };

        // Map the partners based on `utype`
        response.data.users.forEach((partner) => {
          if (partner.utype && partnerMap[partner.utype]) {
            partnerMap[partner.utype].push(partner);
          } else {
            console.warn(`Unknown utype: ${partner.utype}`);
          }
        });

        dispatch(fetchPartnersSuccess(partnerMap));
      } else {
        throw new Error('Failed to fetch partners');
      }
    } catch (error) {
      console.error('API Error:', error);
      dispatch(fetchPartnersFailure(error.message || 'An error occurred while fetching the partner list.'));
    }
  };
};

export const updatePartners = (partners) => ({
  type: UPDATE_PARTNERS,
  payload: partners,
});

export const deletePartner = (cpId) => ({
  type: DELETE_PARTNER,
  payload: cpId,
});

export const togglePartnerStatus = (cpId) => ({
  type: TOGGLE_PARTNER_STATUS,
  payload: { cpId },
});
