import React from 'react'
import { Button, Container, Grid, IconButton, InputBase, TextField, Typography } from '@mui/material'
import {PartnerArrowIcon} from '../../res/NewWebIcons';


const OthersTopComponent = () => {
  return (
    <Grid>
        <Grid style={{display:'flex', alignItems:'center', gap:'1%', justifyContent:'start'}}>
            <Typography color='textSecondary' style={{fontFamily:'sans-serif', fontSize:'13px'}}>Partner</Typography>
            <PartnerArrowIcon/>
            <Typography color='textSecondary' style={{fontFamily:'sans-serif', fontSize:'13px'}}>Partners</Typography>
        </Grid>

        <Grid style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginTop:'1%'}}>
            <Grid>
            <Typography style={{fontWeight:'bold', fontFamily:'sans-serif', fontSize:'19px', color:'#343C6A'}}>Partners</Typography>
            </Grid>
          
        </Grid>
    </Grid>
  )
}

export default OthersTopComponent
