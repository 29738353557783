import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  TextField, Button, Container, Grid, Typography, FormControl, InputLabel, Select, MenuItem, Card, CardContent, Paper, Box
} from "@mui/material";
import { Walllet_Icon } from "../../res/icons";
import SearchIcon from "@mui/icons-material/Search";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BalanceTopComponent from './BalanceTopComponent';
import WalletBalanceComponent from "./WalletBalanceComponent";

const NewFundTransfer = () => {
  const [walletBalance, setWalletBalance] = useState(0);
  const [payoutType, setPayoutType] = useState(""); // State for dropdown selection

  const validationSchema = Yup.object().shape({
    receiverId: Yup.string()
      .required("Receiver ID is required")
      .matches(/^[a-zA-Z0-9]+$/, "Receiver ID should only contain alphanumeric characters"),
      amount: Yup.string()
      .matches(/^[0-9]*$/, "Amount should only contain numbers")
      .test('is-positive', 'Amount must be a positive number', (value) => {
        return Number(value) > 0;
      })
      .required('Amount is required'),
  });

  const formik = useFormik({
    initialValues: {
      receiverId: "",
      receiverName: "",
      receiverType: "",
      amount: "",
      remark: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (values.amount > walletBalance) {
        toast.error("Insufficient wallet balance.");
        return;
      }
      try {
        const token = localStorage.getItem("jwtToken");
        const response = await axios.post(
          "https://api.ipaisa.co.in/myapp/auth/transaction",
          values,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.status === "Balance is not sufficient") {
          toast.error("Balance is not sufficient");
        } else {
          if (response.data.statusCode === "OK") {
            toast.success(
              response.data.message || "Funds transferred successfully"
            );
          }
          else {
            toast.error("Please enter valid User ID");
          }

          fetchBalance(); // Reload the wallet balance after successful transaction
        }
      } catch (error) {
        const errorMessage =
          error.response?.data?.message || "Error transferring funds";
        toast.error(errorMessage);
      }
    },
  });

  const fetchBalance = async () => {
    try {
      const token = localStorage.getItem("jwtToken");
      const response = await axios.get(
        "https://api.ipaisa.co.in/myapp/auth/getwalletbalance",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setWalletBalance(response.data.walletBalance || 0);
    } catch (error) {
      console.error("Error fetching wallet balance:", error);
    }
  };

  useEffect(() => {
    fetchBalance();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, value);
  };

  const handleAmountChange = (e) => {
    const { name, value } = e.target;
  
    let validValue = value.replace(/[^0-9]/g, '');
  
    if (validValue.startsWith('0') && validValue.length > 1) {
      validValue = validValue.replace(/^0+/, '');
    }
  
    if (validValue === '0') {
      validValue = '';
    }
  
    formik.setFieldValue(name, validValue);
  };
  

  const fetchReceiverDetails = async () => {
    try {
      const token = localStorage.getItem("jwtToken");
      const response = await axios.get(
        `https://api.ipaisa.co.in/myapp/auth/reciverUnderSender/${formik.values.receiverId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { firstName, lastName, uType } = response.data;
      const receiverName = `${firstName} ${lastName}`; // Concatenate first and last names
      const receiverType = uType; // Assign uType to receiverType
      formik.setValues({
        ...formik.values,
        receiverName,
        receiverType,
      });
    } catch (error) {
      console.log("Error fetching receiver details:", error);
    }
  };

  const handlePayoutTypeChange = (event) => {
    setPayoutType(event.target.value);
  };

  const lineHeight = '50px';

  return (
    <Container style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
      <BalanceTopComponent />

      <Grid container spacing={2} style={{ marginTop: '3%' }}>
        {/* Wallet Balance Card */}
        <WalletBalanceComponent/>

        {/* Fund Transfer Form */}
        <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
          <Paper style={{ padding: '2rem', borderRadius: '8px' }}>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined" required>
                    <InputLabel>Pay Type</InputLabel>
                    <Select
                      value={payoutType}
                      onChange={handlePayoutTypeChange}
                      label="Payout Type"
                    >
                      <MenuItem value="payout">Single Pay</MenuItem>
                      <MenuItem value="bulk-payout">Bulk Pay</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Receiver ID"
                    name="receiverId"
                    value={formik.values.receiverId}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    InputProps={{
                      endAdornment: (
                        <Button
                          onClick={fetchReceiverDetails}
                          style={{ marginLeft: 8, backgroundColor: "#027BFE", color: '#FFFFFF' }}
                        >
                          <SearchIcon />
                        </Button>
                      ),
                    }}
                    error={
                      formik.touched.receiverId &&
                      Boolean(formik.errors.receiverId)
                    }
                    helperText={
                      formik.touched.receiverId && formik.errors.receiverId
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Receiver Name"
                    name="receiverName"
                    value={formik.values.receiverName}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      style: { height: "2.5em" },
                    }}
                    error={
                      formik.touched.receiverName &&
                      Boolean(formik.errors.receiverName)
                    }
                    helperText={
                      formik.touched.receiverName && formik.errors.receiverName
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    required
                    error={
                      formik.touched.receiverType &&
                      Boolean(formik.errors.receiverType)
                    }
                  >
                    <InputLabel>Receiver Type</InputLabel>
                    <Select
                      name="receiverType"
                      value={formik.values.receiverType}
                      onChange={handleInputChange}
                      label="Receiver Type"
                      readOnly
                      disabled
                    >
                      <MenuItem value="CHANNELPARTNER">Channel Partner</MenuItem>
                      <MenuItem value="SUPERDISTRIBUTOR">Super Distributor</MenuItem>
                      <MenuItem value="MASTERDISTRIBUTOR">Master Distributor</MenuItem>
                      <MenuItem value="AREADISTRIBUTOR">Area Distributor</MenuItem>
                      <MenuItem value="RETAILER">Retailer</MenuItem>
                    </Select>
                    {formik.touched.receiverType &&
                      formik.errors.receiverType && (
                        <Typography color="error" variant="caption">
                          {formik.errors.receiverType}
                        </Typography>
                      )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Amount"
                    name="amount"
                    type="text"
                    autoComplete="off"
                    value={formik.values.amount}
                    onChange={handleAmountChange}
                    fullWidth
                    required
                    error={
                      formik.touched.amount && Boolean(formik.errors.amount)
                    }
                    helperText={formik.touched.amount && formik.errors.amount}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Remark"
                    name="remark"
                    value={formik.values.remark}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    multiline
                    rows={4}
                    error={
                      formik.touched.remark && Boolean(formik.errors.remark)
                    }
                    helperText={formik.touched.remark && formik.errors.remark}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="end" gap={1} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
                    <Button
                      sx={{
                        border: '1.5px solid #FF0000',
                        height: lineHeight,
                        fontSize: '16px',
                        color: '#FF0000',
                        textTransform: 'none',
                        width: { xs: '100%', md: '18%' },
                        borderRadius: '8px'
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      sx={{
                        backgroundColor: "#027BFE",
                        height: lineHeight,
                        color: '#FFFFFF',
                        fontSize: '16px',
                        textTransform: 'none',
                        width: { xs: '100%', md: '18%' },
                        borderRadius: '8px',
                        '&:hover': {
                          backgroundColor: "#027BFE",
                          color: '#FFFFFF',
                      },
                      }}
                    >
                      Transfer Fund
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default NewFundTransfer;
