import React, { useState } from "react";
import { Container, Box, Typography, TextField, Button, CircularProgress, Grid, CssBaseline } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

const ChangeParentSupport = () => {
  const formik = useFormik({
    initialValues: {
      oldParent: '',
      newParent: '',
    },
    validationSchema: Yup.object({
      oldParent: Yup.string().required('Old Parent is required')
      .test(
        'not-only-zero',
        'Old Parent cannot be only zero',
        value => value && !/^[0]+$/.test(value)
      ),
      newParent: Yup.string()
        .required('New Parent is required')
        .test(
          'not-only-zero',
          'New Parent cannot be only zero',
          value => value && !/^[0]+$/.test(value)
        ),
    }),
    onSubmit: (values, { setSubmitting, setErrors }) => {
      // Simulate a delay to demonstrate loading state
      setTimeout(() => {
        // Log the form values to the console

        // Reset the submitting state
        setSubmitting(false);

        
      }, 1000);
    },
  });

  const handleVerifyUser = async (user) => {
    try {
      if (!formik.values.oldParent) {
        // If oldParent field is empty, show error message
        formik.setFieldError('oldParent', 'Please enter the Old Parent');
        return;
      }

      // Simulate fetching data from the database
      const parentData = {
        oldParent: formik.values.oldParent,
      };

      // Update the formik values with the fetched data
      formik.setValues({
        ...formik.values,
        oldParent: parentData.oldParent,
      });

      // Clear any previous errors
      formik.setFieldError('oldParent', undefined);
    } catch (error) {
      // Handle errors, e.g., display an error message to the user
    }
  };

  return (
    <>
      <CssBaseline />
      <Box style={{padding:'10px 50px 50px 50px'}}>
        <Container maxWidth="xl">
          <Box sx={{ padding: '30px 60px 60px 60px', boxShadow: 2, borderRadius: 2, marginTop:"12%"}}>
            <Typography variant="h5" gutterBottom sx={{ textAlign: 'start', fontWeight:'bold' }}>
              Change Parent Support
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="oldParent"
                    name="oldParent"
                    label="Old Parent"
                    variant="outlined"
                    margin="normal"
                    value={formik.values.oldParent}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.oldParent && Boolean(formik.errors.oldParent)}
                    helperText={formik.touched.oldParent && formik.errors.oldParent}
                  />
                  <Button 
                    sx={{
                      height: '30px',
                      width: '12%',
                      fontSize: '65%',
                      textTransform: 'none',
                      borderRadius: '8px',
                      backgroundColor: "#027BFE",
                      color: 'white',
                      mt: 1,
                      '&:hover': {
                        backgroundColor: '#027BFE',
                      },
                    }}                    
                    onClick={() => handleVerifyUser(formik.values.oldParent)} 
                  >
                    Verify User
                  </Button>                
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="newParent"
                    name="newParent"
                    label="New Parent"
                    variant="outlined"
                    margin="normal"
                    value={formik.values.newParent}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.newParent && Boolean(formik.errors.newParent)}
                    helperText={formik.touched.newParent && formik.errors.newParent}
                  />
                </Grid>
              </Grid>
              <Box sx={{ display: 'flex', justifyContent: 'start', mt: 1}}>
                <Button 
                  type="submit" 
                  style={{textTransform:'none', width:'30%', borderRadius:'8px', backgroundColor:"#027BFE", color:'white'}}  
                  disabled={formik.isSubmitting}
                >
                  {formik.isSubmitting ? <CircularProgress size={24} /> : 'Assign'}
                </Button>
              </Box>
            </form>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default ChangeParentSupport;
