import { Grid, Typography } from '@mui/material'
import React from 'react'
import { PartnerArrowIcon } from '../../res/NewWebIcons'

const SupportsTopComp = () => {
  return (
    <Grid style={{display:'flex', alignItems:'center', gap:'1%', justifyContent:'start'}}>
        <Typography color='textSecondary' style={{fontFamily:'sans-serif', fontSize:'14px'}}>Support</Typography>
            <PartnerArrowIcon/>
        <Typography color='textSecondary' style={{fontFamily:'sans-serif', fontSize:'14px'}}>Support</Typography>
    </Grid>
  )
}

export default SupportsTopComp
