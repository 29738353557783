import React, { useState } from "react";
import {
    Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Box, Grid, TablePagination, Switch, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField
} from '@mui/material';
import { Visibility, Delete } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    evenRow: {
        backgroundColor: '#C1E0FB',
    },
    oddRow: {
        backgroundColor: '#FFFFFF',
    }
}));

const initialData = [
    { srNo: 1, ticket: 'UCS0001', date: '03-06-2024 04:14 PM', usertype: 'DEMO', subject: 'none', status: 'Active' },
    { srNo: 2, ticket: 'UCS0002', date: '03-06-2024 05:30 PM', usertype: 'DEMO', subject: 'none', status: 'Inactive' },
];

const UserCreation = () => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [open, setOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [editRow, setEditRow] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [data, setData] = useState(initialData);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpen = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };


    const handleClose = () => {
        setOpen(false);
    };

    const handleInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleSave = () => {
        handleClose();
    };

    const handleDelete = (index) => {
        const newData = [...data];
        newData.splice(index + page * rowsPerPage, 1);
        setData(newData);
    };

    const filteredData = data.filter((row) => {
        return Object.values(row).some((value) =>
            value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        );
    });

    return (
        <Container maxWidth="xl" style={{ marginTop: '10%' }}>
            <Box mt={4} mb={2}>
                <Grid container justifyContent="space-between" alignItems="center" >
                    <Grid item >
                        <TextField
                            label="Search"
                            variant="outlined"
                            value={searchQuery}
                            onChange={handleInputChange}
                            style={{ marginBottom: '2%', width: '100%' }}
                        />
                    </Grid>
                   
                </Grid>
                <TableContainer component={Paper} style={{ marginTop: '2%' }}>
                    <Table>
                        <TableHead style={{ backgroundColor: '#027BFE' }}>
                            <TableRow>
                                <TableCell style={{color:'white' , textAlign: 'center' }}>Sr.No</TableCell>
                                <TableCell style={{color:'white' , textAlign: 'center' }}>Ticket ID</TableCell>
                                <TableCell style={{color:'white' , textAlign: 'center' }}>Date & Time</TableCell>
                                <TableCell style={{color:'white' , textAlign: 'center' }}>User Type</TableCell>
                                <TableCell style={{color:'white' , textAlign: 'center' }}>Subject</TableCell>
                                <TableCell style={{color:'white' , textAlign: 'center' }}>Status</TableCell>
                                <TableCell style={{color:'white' , textAlign: 'center' }}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredData
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const shouldDisplayRow = Object.values(row).some((value) =>
                                        value.toString().toLowerCase().includes(searchQuery.toLowerCase())
                                    );

                                    if (!shouldDisplayRow) {
                                        return null;
                                    }

                                    return (
                                        <TableRow key={index}
                                        className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}>
                                            <TableCell sx={{ textAlign: 'center' }}>{row.srNo}</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>{row.ticket}</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>{row.date}</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>{row.usertype}</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>{row.subject}</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                {row.status === 'Active' ? (
                                                    <Switch color="success" defaultChecked={true} style={{ display: 'none' }} />
                                                ) : (
                                                    <Switch color="default" style={{ display: 'none' }} />
                                                )}
                                            </TableCell>
                                           
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <IconButton color="primary" aria-label="view" onClick={() => handleClickOpen(row)}>
                                                    <Visibility />
                                                </IconButton>
                                                <IconButton color="secondary" aria-label="delete" onClick={() => handleDelete(row)}>
                                                    <Delete />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>

                    </Table>
                </TableContainer>
                <Grid container justifyContent="flex-end">
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Grid>
            </Box>

            <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
                <DialogTitle bgcolor={'#027BFE'} color={'#ffffff'}  strong>User Details</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6} marginTop={5} borderRadius={10} border={1} boxShadow={3}>
                            {selectedRow && (
                                <div>
                                    <p><strong>Ticket ID:</strong> {selectedRow.ticket}</p>
                                    <p><strong>User Type:</strong> {selectedRow.usertype}</p>
                                    <p><strong>Date & Time:</strong> {selectedRow.date}</p>
                                    <p><strong>DOB:</strong> {'17-07-1998'}</p>
                                    <p><strong>Address:</strong> {selectedRow.address}</p>
                                    <p><strong>City:</strong> {selectedRow.address}</p>
                                    <p><strong>State:</strong> {selectedRow.address}</p>
                                    <p><strong>Pincode:</strong> {selectedRow.address}</p>
                                    <p><strong>Wallet Balance:</strong> {selectedRow.walletBalance}</p>
                                    <p><strong>Status:</strong> {selectedRow.status}</p>
                                </div>
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            <p><strong>Uploaded Agreement:</strong></p>
                            <iframe
                                src={`${process.env.PUBLIC_URL}/IpaisaApplication.pdf`}
                                width="100%"
                                height="400px"
                                style={{ border: 'none' }}
                                title="PDF Document"
                            ></iframe>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} style={{backgroundColor: '#FF0000', color: '#ffffff', textTransform:'none'}}>Close</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default UserCreation;
