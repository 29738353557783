import React from 'react'
import { CloseIcon, SuccessPopIcon } from '../../res/icons'
import { Button, Grid, Paper, Typography, Dialog } from '@mui/material'

const UserCreationSuccessPopup = ({  handleClose }) => {
  return (
    <Dialog open={true} onClose={handleClose}>
      <Grid>   
          <Grid style={{ padding: '2rem' }}>
            <Grid style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <CloseIcon onClick={handleClose} />
            </Grid>
            <Grid style={{ display: 'flex', justifyContent: 'center' }}>
              <SuccessPopIcon />
            </Grid>
            <Grid style={{ display: 'flex', justifyContent: 'center', marginTop: '6%' }}>
              <Typography style={{ fontWeight: 'bold', fontSize: '20px' }}>User Created Successfully!</Typography>
            </Grid>
            <Grid style={{ display: 'flex', justifyContent: 'center', marginTop: '6%' }}>
              <Typography color='textSecondary' style={{ fontSize: '16px', textAlign: 'center' }}>
                The user account has been created successfully.
              </Typography>
            </Grid>
            <Grid style={{ display: 'flex', justifyContent: 'center', marginTop: '6%', marginBottom: '4%' }}>
              <Button style={{ color: 'white', backgroundColor: '#185ABD', width: '20%' }} onClick={handleClose}>
                OK
              </Button>
            </Grid>
          </Grid>
        </Grid>
      
    </Dialog>
  )
}

export default UserCreationSuccessPopup
