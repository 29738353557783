import Services_SVG from './services.svg';
import Bank_SVG from './bankIcon.svg';
import Compensation_SVG from './compensationIcon.svg'
import Report_SVG from './reportIcon.svg'
import CreateTicket_SVG from './createticketIcon.svg'
import Setting_SVG from './settingIcon.svg'
import Support_SVG from './supportIcon.svg'
import Dashboard_SVG from './dashboardIcon.svg'
import Logout_SVG from './logoutIcon.svg'
import Wallet_SVG from './walletIcon.svg'
import MadeInIndia_SVG from './MadeInIndiaIcon.svg'
import AxisBank_SVG from './axisBankIcon.svg'
import AUBank_SVG from './AUBankIcon.svg'
import IDFCBank_SVG from './idfcBankIcon.svg'
import YesBank_SVG from './yesBankIcon.svg'
import UnionBank_SVG from './unionBankIcon.svg'
import AddCard_SVG from './AddCardIcon.svg'
import Location_SVG from './LocationIcon.svg'
import LinkedIn_SVG from './LinkedInIcon.svg'
import Facebook_SVG from './FacebookIcon.svg'
import Instagram_SVG from './InstagramIcon.svg'
import Email_SVG from './EmailIcon.svg'
import PhoneCall_SVG from './PhoneCallIcon.svg'
import Email2_SVG from './EmailIcon2.svg'
import Location2_SVG from './LocationIcon2.svg'
import LinkedIn2_SVG from './LinkedInIcon2.svg'
import Instagram2_SVG from './InstagramIcon2.svg'
import Facebook2_SVG from './FacebookIcon2.svg'
import WalletAboutusIcon_SVG from './WalletAboutusIcon.svg'
import Call_SVG from './CallIcon.svg'
import Game_SVG from './GameIcon.svg'
import TeamUser_SVG from './3userIcon.svg'
import WalletService_SVG from './WalletServiceIcon.svg'
import Edit_SVG from './EditIcon.svg'
import SuccessPop_SVG from './SuccessPopIcon.svg'
import FailedPop_SVG from './FailedPopIcon.svg'
import Close_SVG from './CloseIcon.svg'
import Edsom_SVG from './EdsomIcon.svg'

export const LinkedInIcon = (hg, wd) => {
    return <img src={LinkedIn_SVG} alt='LinkedIn_SVG' style={{height:'30px'}}/>
}
export const EdsomIcon = (hg, wd) => {
    return <img src={Edsom_SVG} alt='Edsom_SVG' style={ {height: {xs: '25px', md: '50px'}}}/>
}
export const CloseIcon = (hg, wd) => {
    return <img src={Close_SVG} alt='Close_SVG' style={{height:'25px'}}/>
}
export const WalletServiceIcon = (hg, wd) => {
    return <img src={WalletService_SVG} alt='WalletService_SVG' style={{height:'100px'}}/>
}
export const CallIcon = (hg, wd) => {
    return <img src={Call_SVG} alt='Call_SVG' style={{height:'30px'}}/>
}
export const GameIcon = (hg, wd) => {
    return <img src={Game_SVG} alt='Game_SVG' style={{height:'35px'}}/>
}
export const TeamUserIcon = (hg, wd) => {
    return <img src={TeamUser_SVG} alt='TeamUser_SVG' style={{height:'30px'}}/>
}
export const FacebookIcon = (hg, wd) => {
    return <img src={Facebook_SVG} alt='Facebook_SVG' style={{height:'30px'}}/>
}
export const InstagramIcon = (hg, wd) => {
    return <img src={Instagram_SVG} alt='Instagram_SVG' style={{height:'30px'}}/>
}
export const EmailIcon = (hg, wd) => {
    return <img src={Email_SVG} alt='Email_SVG' style={{height:'30px'}}/>
}
export const PhoneCallIcon = (hg, wd) => {
    return <img src={PhoneCall_SVG} alt='PhoneCall_SVG' style={{height:'23px'}}/>
}
export const EmailIcon2 = (hg, wd) => {
    return <img src={Email2_SVG} alt='Email2_SVG' style={{height:'23px'}}/>
}
export const LocationIcon2 = (hg, wd) => {
    return <img src={Location2_SVG} alt='Location2_SVG' style={{height:'23px'}}/>
}
export const LinkedInIcon2 = (hg, wd) => {
    return <img src={LinkedIn2_SVG} alt='LinkedIn2_SVG' style={{height:'30px',  }}/>
}
export const FacebookIcon2 = (hg, wd) => {
    return <img src={Facebook2_SVG} alt='Facebook2_SVG' style={{height:'30px', }}/>
}
export const InstagramIcon2 = (hg, wd) => {
    return <img src={Instagram2_SVG} alt='Instagram2_SVG' style={{height:'30px'}}/>
}
export const WalletAboutIcon = (hg, wd) => {
    return <img src={WalletAboutusIcon_SVG} alt="WalletAboutusIcon_SVG" style={{height: '200px'}}/>
}
export const Services_Icon = (hg,wd) => {
    return <img src={Services_SVG} alt="Services_SVG" style={{height: '22px'}}/>
}
export const Bank_Icon = (hg,wd) => {
    return <img src={Bank_SVG} alt="Bank_SVG" style={{height: '22px'}}/>
}
export const Compensation_Icon = (hg,wd) => {
    return <img src={Compensation_SVG} alt="Compensation_SVG" style={{height: '22px'}}/>
}
export const Report_Icon = (hg,wd) => {
    return <img src={Report_SVG} alt="Report_SVG" style={{height: '22px'}}/>
}
export const CreateTicket_Icon = (hg,wd) => {
    return <img src={CreateTicket_SVG} alt="CreateTicket_SVG" style={{height: '22px'}}/>
}
export const Setting_Icon = (hg,wd) => {
    return <img src={Setting_SVG} alt="Setting_SVG" style={{height: '22px'}}/>
}
export const Support_Icon = (hg,wd) => {
    return <img src={Support_SVG} alt="Support_SVG" style={{height: '22px'}}/>
}
export const Dashboard_Icon = (hg,wd) => {
    return <img src={Dashboard_SVG} alt="Dashboard_SVG" style={{height: '22px'}}/>
}
export const Logout_Icon = (hg,wd) => {
    return <img src={Logout_SVG} alt="Logout_SVG" style={{height: '22px'}}/>
}
export const Walllet_Icon = (hg,wd) => {
    return <img src={Wallet_SVG} alt="Wallet_SVG" style={{height: '80px'}}/>
}
export const MadeInIndiaIcon = (hg,wd) => {
    return <img src={MadeInIndia_SVG} alt="MadeInIndia_SVG" style={{height: '50px'}}/>
}
export const AxisBankIcon = (hg,wd) => {
    return <img src={AxisBank_SVG} alt="AxisBank_SVG" style={{height: '50px'}}/>
}
export const UnionBankIcon = (hg,wd) => {
    return <img src={UnionBank_SVG} alt="UnionBank_SVG" style={{height: '50px'}}/>
}
export const AUBankIcon = (hg,wd) => {
    return <img src={AUBank_SVG} alt="AUBank_SVG" style={{height: '50px'}}/>
}
export const IDFCBankIcon = (hg,wd) => {
    return <img src={IDFCBank_SVG} alt="IDFCBank_SVG" style={{height: '50px'}}/>
}
export const YesBankIcon = (hg,wd) => {
    return <img src={YesBank_SVG} alt="YesBank_SVG" style={{height: '50px'}}/>
}
export const AddCardIcon = (hg,wd) => {
    return <img src={AddCard_SVG} alt="AddCard_SVG" style={{height: '50px'}}/>
}
export const LocationIcon = (hg,wd) => {
    return <img src={Location_SVG} alt="Location_SVG" style={{height: '30px'}}/>
}
export const EditIcon = (hg, wd) => {
    return <img src={Edit_SVG} alt='Edit_SVG' style={{height: '40px'}}/>
}
export const SuccessPopIcon = (hg, wd) => {
    return <img src={SuccessPop_SVG} alt='SuccessPop_SVG' style={{height: '75px'}}/>
}
export const FailedPopIcon = (hg, wd) => {
    return <img src={FailedPop_SVG} alt='FailedPop_SVG' style={{height: '75px'}}/>
}


