
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux"; // Import Provider from react-redux
import store from "./redux/store"; // Import your Redux store
import Layout from "./Layout";
import Aboutus from "./components/WebSite/Aboutus";
import Login from "./components/Login/Login";
import SdListTable from "./components/Partner/SdList";
import MdlistTable from "./components/Partner/MdList";
import DlistTable from "./components/Partner/DList";
import RetailerlistTable from "./components/Partner/RetailerList";
import TechSupport from "./components/Partner/TechSupport";
import CreateTicket from "./components/CreateTicket";
import KYCVerification from "./components/Partner/KycVerification";
import ChangeParent from "./components/Partner/ChangeParent";
import FTR from "./components/Reports/FTR";
import TransactionReport from "./components/Reports/TransactionReport";
import TransactionHistoryReport from "./components/Reports/TransactionHistoryReport";
import CommissionReport from "./components/Reports/ComissionReport";
import SurchargeReport from "./components/Reports/SurchargeReport";
import BalanceReqHisReport from "./components/Reports/BalanceReqHis";
import UserCashback from "./components/Reports/UserCashback";
import FixCommission from "./components/Compensetion/FixCommission";
import VariableCommission from "./components/Compensetion/VariableCommission";
import AddMoneyForm from "./components/Balance/AddMoney";
import ReverseMoney from "./components/Balance/ReverseMoney";
import AddWalletBalance from "./components/Balance/AddWalletBalance";
import FundTransfer from "./components/Support/FundTransferSupport";
import TicketHandling from "./components/Support/TicketHandlingSupport";
import UserCreation from "./components/Support/UserCreationSupport";
import AddBeneficiary from "./components/Balance/AddBeneficiary";
import SendSms from "./components/Setting/SendSms";
import GeneralSettings from "./components/Setting/GeneralSetting";
import Flights from "./components/retailerservicespages/Flights";
import BookingComponent from "./components/retailerservicespages/BookingComponent";
import Sprintnxt from "./components/Sprintnxt";
import OngoingTicket from "./components/Support/OngoingTicket";
import CloseTicket from "./components/Support/CloseTicket";
import PendingTicket from "./components/Support/PendingTicket";
import RBusTicketsBook from "./components/Services/BusTicketBook/RBusTicketsBook";
import DetailedBusComponent from "./components/Services/BusTicketBook/DetailedBusComponent";
import SetMpin from "./components/Login/SetMpin";
import SeatSelection from "./components/Services/BusTicketBook/SeatSelection";
import RRecharge from "./components/Services/RRecharge";
import GasCylinderBooking from "./components/Services/GasCylinderBooking";
import BroadBand from "./components/Services/BroadBand";
import LandLine from "./components/Services/LandLine";
import PostPaidRecharge from "./components/Services/PostPaidRecharge";
import RetailerServices from "./components/Services/RetailerServices";
import DthRecharge from "./components/Services/DthRecharge";
import RetailerWater from "./components/Services/RetailerWater";
import RPipedGas from "./components/Services/RPipedGas";
import RMuncipalTax from "./components/Services/RMuncipalTax";
import REducationFees from "./components/Services/REducationFees";
import RElectricity from "./components/Services/RElectricity";
import FASTag from "./components/Services/FASTag";
import RPayLoanHouse from './components/Services/RHouseRent';
import PassengerDetail from "./components/Services/TrainTicketBooking/PassengerDetail";
import EditUserID from "./components/Services/TrainTicketBooking/EditUserID";
import TrainTicketBooking from "./components/Services/TrainTicketBooking/TrainTicketBooking";
import Home from "./components/WebSite/Home";
import Dashboard from "./components/Dashboard/Dashboard";
import SignIn from "./components/WebSite/SignIn";
import Blogs from "./components/WebSite/Blogs";
import ContactUs from "./components/WebSite/ContactUs";
import Career from "./components/WebSite/Career";
import Gallery from "./components/WebSite/Gallery";
import Services from "./components/WebSite/Services";
import BlogsUpdater from "../src/components/Setting/BlogsUpdater";
import BlogsUploader from "../src/components/Setting/BlogsUploader";
import ChangeParentSupport from "./components/Support/ChangeParentSupport";
import RCableTv from "./components/Services/RCableTv";
import CplistTable from './components/Partner/CpList';
import ProtectedRoute from "./ProtectedRoute"; // Import the ProtectedRoute component
import PayoutForm from "./components/Balance/PayoutForm";
import Privacypolicy from "./components/WebSite/Privacypolicy";
import TermsandConditions from './components/WebSite/TermsandConditions';
import Faq from "./components/WebSite/Faq";
import NotFound from "./NotFound";
import RefundandCancel from "./components/WebSite/RefundandCancel";
import TransactionSuccessFull from "./components/Balance/TransactionSuccessfull";
import TransactionFailed from "./components/Balance/TransactionFailed";
import OfflineBankIssue from "./components/OfflineBankIssue";
import PayInndOut from "./components/Balance/PayInAndOut";
import OurTeam from "./components/WebSite/OurTeam";
import UserProfile from "./components/Dashboard/UserProfile";
import SuccessPop from './components/Balance/SuccessPopup';
import FailedPop from "./components/Balance/FailedPopup";
import UserCreationSuccessPopup from "./components/Partner/UserCreationSuccessPopup";
import UserCreationFailedPopup from "./components/Partner/UserCreationFailedPopup";
import SignInK from './components/WebSite/SignInK';
import BankChargesTable from "./components/Partner/BankChargesTable";
import PayIn from "./components/Reports/PayIn";
import PayOut from "./components/Reports/Payout";
import RetailerLoanPay from "./components/Services/RetailerLoanPay";
import RetailerMetro from './components/Services/RetailerMetro';
import RetailerToll from "./components/Services/RetailerToll";
import Testpayin from "./components/Balance/testpayin";
import PartnersTableTop from "./components/Partner/PartnersTableTop";
import CPL from './components/Partner/CPL';
import SDL from './components/Partner/SDL';

// import Navbar from './components/Dashboard/Navbar';
import DeepLinkPage from './components/Balance/deeplinkpage';
import MDL from "./components/Partner/MDL";
import ADL from "./components/Partner/ADL";
import RList from "./components/Partner/RList";
import APIHandling from "./components/Balance/ManageAPI";
import APITable from "./components/Balance/APITable";
import TechS from "./components/Partner/TechS";
import ChanegeParentNew from './components/Partner/ChangeParentNew';
import OthersTopComponent from "./components/Partner/OthersTopComponent";
import NewKycVerification from "./components/Partner/NewKycVerification";
import NewFundTransfer from "./components/Balance/NewFundTransfer";
import BalanceTopComponent from "./components/Balance/BalanceTopComponent";
import NewAddMoney from "./components/Balance/NewAddMoney";
import WalletBalanceComponent from "./components/Balance/WalletBalanceComponent";
import NewPayout from "./components/Balance/NewPayout";
import NewAddBeneficiary from "./components/Balance/NewAddBeneficiary";
import NewReverseMoney from "./components/Balance/NewReverseMoney";
import NewBankCharges from "./components/Balance/NewBankCharges";
import Dashboard2 from "./components/Dashboard/Dashboard2";
import NewFixandVariableCom from "./components/Compensetion/NewFixandVariableCom";
import NewPayIn from "./components/Reports/NewPayIn";
import NewPayOutReport from './components/Reports/NewPayOutReport';
import NewPayInOutTopComp from "./components/Reports/NewPayInOutTopComp";
import PayBills from "./components/Services/PayBills";
import BookandBuy from "./components/Services/BookandBuy";
import NewFundTReport from "./components/Reports/NewFTR";
import NewFTRTable from "./components/Reports/NewFTRTable";
import DeepPayout from "./components/Balance/DeepPayout";
import NewTransactionalReport from "./components/Reports/NewTransactionalReport";
import NewTransactionalTable from "./components/Reports/NewTransactionalTable";
import NewUserCashback from "./components/Reports/NewUserCashback";
import NewTransactionalHistoryR from "./components/Reports/NewTransactionalHistoryR";
import NewBalanceReqHis from "./components/Reports/NewBalanceReqHis";
import NewSurchargeReport from "./components/Reports/NewSurchargeReport";
import NewCommissionReport from "./components/Reports/NewCommissionReport";
import NewPaymentRequest from "./components/NewPaymentRequest";
import NewCreateTicket from "./components/NewCreateTicket";
import NewFundTransferTicket from "./components/Support/NewFundTransferTicket";
import NewUserCreation from "./components/Support/NewUserCreation";
import NewChangePSupport from "./components/Support/NewChangePSupport";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Layout>
          <Routes>

          <Route path="/" element={<Home />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path='/signinK' element={<SignInK/>}/>
            <Route path="/aboutus" element={<Aboutus />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/career" element={<Career />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/ourteam" element={<OurTeam/>}/>
            <Route path="/services" element={<Services />} />
            <Route path="/deeplinkpage/*" element={<DeepLinkPage />} />
            <Route path="/deeplinkpayout/*" element={<DeepPayout />} />

            {/* Footer */}
            <Route path="/privacypolicy" element={<Privacypolicy />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/refundandcancel" element={<RefundandCancel/>}/>
            <Route path="/termsandconditions" element={<TermsandConditions/>} />

            {/* New Website */}
            <Route path="/ChannelPartners" element={<CPL/>} />
            <Route path="/SuperDistributors" element={<SDL/>} />
            <Route path="/MasterDistributors" element={<MDL/>} />
            <Route path="/AreaDistributors" element={<ADL/>}/>
            <Route path="/Retailers" element={<RList/>} />
            <Route path="/TechSupportNew" element={<TechS/>}/>
            <Route path="/ChangeParentNew" element={<ChanegeParentNew/>}/>
            <Route path="/OthersTopComponent" element={<OthersTopComponent/>}/>
            <Route path="/NewKycVerification" element={<NewKycVerification/>}/>
            <Route path="/BalanceTopComponent" element={<BalanceTopComponent/>}/>
            <Route path="/WalletBalance" element={<WalletBalanceComponent/>}/>
            <Route path="/NewFundTransfer" element={<NewFundTransfer/>}/>
            <Route path="/NewAddMoney" element={<NewAddMoney/>}/>
            <Route path="/NewPayout" element={<NewPayout/>}/>
            <Route path="/NewAddBeneficiary" element={<NewAddBeneficiary/>}/>
            <Route path="/NewReverseMoney" element={<NewReverseMoney/>}/>
            <Route path="/NewBankCharges" element={<NewBankCharges/>}/>
            <Route path="/NewFixandVariable" element={<NewFixandVariableCom/>}/>
            <Route path="/NewPayIn" element={<NewPayIn/>}/>
            <Route path="/NewPayOutReport" element={<NewPayOutReport/>}/>
            <Route path="/PayInOutTopCom" element={<NewPayInOutTopComp/>}/>
            <Route path="/NewFTR" element={<NewFundTReport/>}/>
            <Route path="/NewFTRTable" element={<NewFTRTable/>}/>
            <Route path="/NewTransactionalReport" element={<NewTransactionalReport/>}/>
            <Route path="/NewTransactionalHistoryReport" element={<NewTransactionalHistoryR/>}/>
            <Route path="/NewTransactionalTable" element={<NewTransactionalTable/>}/>
            <Route path="/NewUserCashback" element={<NewUserCashback/>}/>    
            <Route path="/NewBalanceRequestHistory" element={<NewBalanceReqHis/>}/>
            <Route path="/NewSurchargeReport" element={<NewSurchargeReport/>}/>
            <Route path="/NewCommissionReport" element={<NewCommissionReport/>}/>
            <Route path="/NewPaymentRequest" element={<NewPaymentRequest/>}/>
            <Route path="/NewCreateTicket" element={<NewCreateTicket/>}/>
            <Route path="/NewFundTransferTicket" element={<NewFundTransferTicket/>}/>
            <Route path="NewUserCreation" element={<NewUserCreation/>}/>
            <Route path="/NewChangeParentSupport" element={<NewChangePSupport/>}/>


            <Route path="/BookandBuy" element={<BookandBuy/>}/>
            <Route path="/PayBills" element={<PayBills/>}/>

          

            <Route path="/payinndout" element={<ProtectedRoute><PayInndOut /></ProtectedRoute>} />
            <Route path="/testpayin" element={<ProtectedRoute><Testpayin /></ProtectedRoute>} />

            <Route path="/payinreport" element={<ProtectedRoute><PayIn /></ProtectedRoute>} />
            <Route path="/payoutreport" element={<ProtectedRoute><PayOut /></ProtectedRoute>} />

            <Route path="/userprofile" element={<ProtectedRoute><UserProfile/></ProtectedRoute>} />
            {/* <Route path="/navbar" element={<Navbar/>} /> */}
            <Route path="/successpop" element={<SuccessPop/>} />
            <Route path="/failedpop" element={<FailedPop/>} />
            <Route path="/usercreationsuccess" element={<UserCreationSuccessPopup/>}/>
            <Route path="/usercreationfailed" element={<UserCreationFailedPopup/>}/>
            <Route path="/dashboard2" element={<Dashboard2 />} />

            <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
            <Route path="/bankcharges" element={<ProtectedRoute><BankChargesTable /></ProtectedRoute>} />
            <Route path="/transactionsuccess" element={<ProtectedRoute><TransactionSuccessFull/></ProtectedRoute>} />
            <Route path="/transactionfailed" element={<ProtectedRoute><TransactionFailed /></ProtectedRoute>} />
            <Route path="/cplist" element={<ProtectedRoute><CplistTable /></ProtectedRoute>} />
            <Route path="/sdlist" element={<ProtectedRoute><SdListTable /></ProtectedRoute>} />
            <Route path="/mdlist" element={<ProtectedRoute><MdlistTable /></ProtectedRoute>} />
            <Route path="/adlist" element={<ProtectedRoute><DlistTable /></ProtectedRoute>} />
            <Route path="/retailer" element={<ProtectedRoute><RetailerlistTable /></ProtectedRoute>} />
            <Route path="/techsupport" element={<ProtectedRoute><TechSupport /></ProtectedRoute>} />
            <Route path="/requestpaymentissue" element={<ProtectedRoute><OfflineBankIssue /></ProtectedRoute>} />
            <Route path="/createticket" element={<ProtectedRoute><CreateTicket /></ProtectedRoute>} />
            <Route path="/kycverfication" element={<ProtectedRoute><KYCVerification /></ProtectedRoute>} />
            <Route path="/changeparent" element={<ProtectedRoute><ChangeParent /></ProtectedRoute>} />
            <Route path="/ftr" element={<ProtectedRoute><FTR /></ProtectedRoute>} />
            <Route path="/transactional" element={<ProtectedRoute><TransactionReport /></ProtectedRoute>} />
            <Route path="/transactionalhistory" element={<ProtectedRoute><TransactionHistoryReport /></ProtectedRoute>} />
            <Route path="/commissionreport" element={<ProtectedRoute><CommissionReport /></ProtectedRoute>} />
            <Route path="/surchargereport" element={<ProtectedRoute><SurchargeReport /></ProtectedRoute>} />
            <Route path="/balReqHisReport" element={<ProtectedRoute><BalanceReqHisReport /></ProtectedRoute>} />
            <Route path="/userCashbackReport" element={<ProtectedRoute><UserCashback /></ProtectedRoute>} />
            <Route path="/fixCommission" element={<ProtectedRoute><FixCommission /></ProtectedRoute>} />
            <Route path="/variablecommission" element={<ProtectedRoute><VariableCommission /></ProtectedRoute>} />
            <Route path="/fundtransfer" element={<ProtectedRoute><AddWalletBalance /></ProtectedRoute>} />
            <Route path="/APITable" element={<APITable/>}/>
            <Route path="/reversemoney" element={<ProtectedRoute><ReverseMoney /></ProtectedRoute>} />
            <Route path="/addmoney" element={<ProtectedRoute><AddMoneyForm /></ProtectedRoute>} />
            <Route path="/payout" element={<ProtectedRoute><PayoutForm /></ProtectedRoute>} />
            <Route path="/fundtransferticket" element={<ProtectedRoute><FundTransfer /></ProtectedRoute>} />
            <Route path="/addBeneficiary" element={<AddBeneficiary />} />
            <Route path="/usercreationticket" element={<ProtectedRoute><UserCreation /></ProtectedRoute>} />
            <Route path="/tickethandling" element={<ProtectedRoute><TicketHandling /></ProtectedRoute>} />
            <Route path="/sendsms" element={<ProtectedRoute><SendSms /></ProtectedRoute>} />
            <Route path="/generalsetting" element={<ProtectedRoute><GeneralSettings /></ProtectedRoute>} />
            <Route path="/flights" element={<ProtectedRoute><Flights /></ProtectedRoute>} />
            <Route path="/flights/booking" element={<ProtectedRoute><BookingComponent /></ProtectedRoute>} />
            <Route path="/ongoingticket" element={<ProtectedRoute><OngoingTicket /></ProtectedRoute>} />
            <Route path="/closeticket" element={<ProtectedRoute><CloseTicket /></ProtectedRoute>} />
            <Route path="/pendingticket" element={<ProtectedRoute><PendingTicket /></ProtectedRoute>} />
            <Route path="/retailerservices" element={<ProtectedRoute><RetailerServices /></ProtectedRoute>} />
            <Route path="/rapartments" element={<ProtectedRoute><RPayLoanHouse/></ProtectedRoute>} />
            <Route path="/dthrecharge" element={<ProtectedRoute><DthRecharge /></ProtectedRoute>} />
            <Route path="/rectricityrecharge" element={<ProtectedRoute><RElectricity /></ProtectedRoute>} />
            <Route path="/retailerwater" element={<ProtectedRoute><RetailerWater /></ProtectedRoute>} />
            <Route path="/rpipegas" element={<ProtectedRoute><RPipedGas /></ProtectedRoute>} />
            <Route path="/rmuncipaltax" element={<ProtectedRoute><RMuncipalTax /></ProtectedRoute>} />
            <Route path="/reducationfees" element={<ProtectedRoute><REducationFees /></ProtectedRoute>} />
            <Route path="/rflightbook" element={<ProtectedRoute><Flights /></ProtectedRoute>} />
            <Route path="/rrecharge" element={<ProtectedRoute><RRecharge /></ProtectedRoute>} />
            <Route path="/gascylinderbooking" element={<ProtectedRoute><GasCylinderBooking /></ProtectedRoute>} />
            <Route path="/rbroadband" element={<ProtectedRoute><BroadBand /></ProtectedRoute>} />
            <Route path="/landline" element={<ProtectedRoute><LandLine /></ProtectedRoute>} />
            <Route path='/retailerLoanPay' element={<ProtectedRoute><RetailerLoanPay/></ProtectedRoute>} />
            <Route path='/retailerCableTv' element={<ProtectedRoute><RCableTv/></ProtectedRoute>} />
            <Route path="/metro" element={<ProtectedRoute><RetailerMetro/></ProtectedRoute>} />
            <Route path='/toll' element={<ProtectedRoute><RetailerToll/></ProtectedRoute>} />
            <Route path="/postpaidrecharge" element={<ProtectedRoute><PostPaidRecharge /></ProtectedRoute>} />
            <Route path="/fasttagrecharge" element={<ProtectedRoute><FASTag /></ProtectedRoute>} />
            <Route path="/DetailedBusComponent" element={<ProtectedRoute><DetailedBusComponent /></ProtectedRoute>} />
            <Route path="/SetMpin" element={<ProtectedRoute><SetMpin /></ProtectedRoute>} />
            <Route path="/rbustickets" element={<ProtectedRoute><RBusTicketsBook /></ProtectedRoute>} />
            <Route path="/SeatSelection" element={<ProtectedRoute><SeatSelection /></ProtectedRoute>} />
            <Route path="/sprintnxt" element={<ProtectedRoute><Sprintnxt /></ProtectedRoute>} />
            <Route path="/trainticket" element={<ProtectedRoute><TrainTicketBooking /></ProtectedRoute>} />
            <Route path="/passengerdetails" element={<ProtectedRoute><PassengerDetail /></ProtectedRoute>} />
            <Route path="/edituserid" element={<ProtectedRoute><EditUserID /></ProtectedRoute>} />
            <Route path="/blogsupdater" element={<ProtectedRoute><BlogsUpdater /></ProtectedRoute>} />
            <Route path="/blogsuploader" element={<ProtectedRoute><BlogsUploader /></ProtectedRoute>} />
            <Route path="/changeparentsupport" element={<ProtectedRoute><ChangeParentSupport /></ProtectedRoute>} />
            <Route path="*" element={<SignIn />} />
          </Routes>
        </Layout>
      </Router>
    </Provider>
  );
}

export default App;
