import React, { useState } from 'react';
import { styled, keyframes } from '@mui/system';
import {
    Container,
    Box,
    Grid,
    Card,
    CardContent,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TablePagination,
    Paper,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    tableRow: {
        '& > *': {
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
        },
    },
    evenRow: {
        backgroundColor: '#C1E0FB',
    },
    oddRow: {
        backgroundColor: '#FFFFFF',
    }
}));

// Keyframe animation for fading in elements
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Styled components using Material-UI's styling solution
const StyledContainer = styled(Container)(({ theme }) => ({
    marginTop: theme.spacing(4),
}));

const StyledCard = styled(Card)(({ theme }) => ({
    width: '772px',
    height: '200px',
    gap: '0px',
    borderRadius: '12px',
    opacity: '0px',
    animation: `${fadeIn} 1.9s ease-out`,
    transition: 'transform 2.9s',
    '&:hover': {
        transform: 'scale(1.05)',
    },
    textAlign: 'center',
    padding: theme.spacing(2),
    background: 'linear-gradient(332.55deg, #121A4A 22.12%, #2B3EB0 77.43%)',
    color: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    fontSize: 14,
    textAlign: 'center',
}));

const CenteredBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100vh',
});

const VerticalBorder = styled(Box)(({ theme }) => ({
    width: '2px',
    height: '80px',
    backgroundColor: '#FFFFFF',
    opacity: 0.6,
    margin: '0 16px',
}));

// Main component function
const CommissionReport = () => {
    const classes = useStyles();
    const totalCommission = 100000.0;
    const totalPaid = 100000.54;

    const columns = [
        { id: 'srNo', label: 'Sr No' },
        { id: 'date', label: 'Date' },
        { id: 'transactionId', label: 'Transaction Id' },
        { id: 'partnerName', label: 'Partner Name' },
        { id: 'partnerAccountNumber', label: 'Account Number' },
        { id: 'commissionAmount', label: 'Commission Amount' },
        { id: 'totalCommissionAmount', label: 'Total Commission Amount' },
        { id: 'description', label: 'Description' },
        { id: 'commissionStatus', label: 'Status' },
        { id: 'senderName', label: 'Sender Name' },
    ];

    const rows = [
        {
            srNo: 1,
            date: '2024-05-01',
            transactionId: 'TXN123456',
            partnerName: 'Partner 1',
            partnerAccountNumber: 'ACC123456',
            commissionAmount: 100.0,
            totalCommissionAmount: 12345.67,
            description: 'Commission for April',
            commissionStatus: 'Paid',
            senderName: 'Sender 1',
        },
        // Add more rows as needed
    ];

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Paper>
            <CenteredBox>
                <StyledContainer maxWidth="lg">
                    <Grid container spacing={3} justifyContent="center">
                        <Grid item>
                            <StyledCard>
                                <CardContent>
                                    <Grid container alignItems="center" justifyContent="center">
                                        <Grid item>
                                            <StyledTypography gutterBottom>
                                                Total Commission
                                            </StyledTypography>
                                            <Typography variant="h5" component="div">
                                                ₹ {totalCommission.toFixed(2)}
                                            </Typography>
                                        </Grid>
                                        <VerticalBorder />
                                        <Grid item>
                                            <StyledTypography gutterBottom>
                                                Total Paid
                                            </StyledTypography>
                                            <Typography variant="h5" component="div">
                                                ₹ {totalPaid.toFixed(2)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </StyledCard>
                        </Grid>
                    </Grid>

                    <Box mt={4} width="100%">
                        <Paper sx={{ borderRadius: 2 }}>
                        <Table sx={{ borderRadius: 12 }}>
                            <TableHead>
                                <TableRow sx={{ backgroundColor: '#027BFE' }}>
                                    {columns.map((column) => (
                                        <TableCell key={column.id} sx={{ color: '#FFFFFF', textAlign: 'center' }}>{column.label}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                    <TableRow key={index} 
                                    className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}>
                                        {columns.map((column) => (
                                            <TableCell style={{textAlign: 'center'}} key={column.id} >{row[column.id]}</TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Box>
                </StyledContainer>
            </CenteredBox>
        </Paper>
    );
};

export default CommissionReport;
