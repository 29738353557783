import { Card, CardContent, Grid, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from "react";
import { Walllet_Icon } from '../../res/icons'
import axios from 'axios';

const WalletBalanceComponent = () => {
    const [walletBalance, setWalletBalance] = useState(0);

    const fetchWalletBalance = async () => {
        try {
          const token = localStorage.getItem("jwtToken");
          const response = await axios.get(
            "https://api.ipaisa.co.in/myapp/auth/getwalletbalance",
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setWalletBalance(response.data.walletBalance);
        } catch (error) {
          console.error("Error fetching wallet balance:", error);
        }
      };
      useEffect(()=>{
        fetchWalletBalance();
      }, [walletBalance]);

  return (
    <Grid item xs={12} md={4} order={{ xs: 1, md: 2 }}>
    <Paper style={{ padding: '2rem' }}>
      <Card
        style={{
          width: "100%",
          height: "280px",
          borderRadius: "12px",
          alignContent: "center",
          textAlign: 'center',
          backgroundImage: "linear-gradient(220.55deg, #547AFF 25.12%, #413DFF 70.43%)",
        }}
      >
        <CardContent>
          <Walllet_Icon />
          <Typography
            color="textSecondary"
            sx={{ color: "#FFFFFF", fontSize: "24px" }}
            gutterBottom
          >
            Wallet Balance
          </Typography>
          <Typography
            variant="h5"
            sx={{ color: "#FFFFFF", fontSize: "48px" }}
            component="h2"
          >
            ₹ {walletBalance.toFixed(2)}
          </Typography>
        </CardContent>
      </Card>
    </Paper>
  </Grid>
  )
}

export default WalletBalanceComponent
