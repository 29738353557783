import React from 'react';
import { Container, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, TablePagination } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import jsPDF from 'jspdf';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    tableRow: {
        '& > *': {
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
        },
    },
    evenRow: {
        backgroundColor: '#C1E0FB',
    },
    oddRow: {
        backgroundColor: '#FFFFFF',
    }
}));

const TransactionalTable = ({ searchParams }) => {
    const classes = useStyles();

    const rows = [
        { id: 1, transactionId: '123456', transactionDate: '2024-05-01 14:30', transactionAmount: 110.00, partnerId: 'P001', partnerName: 'John Doe', partnerEmail: 'john.doe@example.com', transactionStatus: 'Completed', remark: 'Some remark' },
        { id: 2, transactionId: '789012', transactionDate: '2024-05-02 09:15', transactionAmount: 165.00, partnerId: 'P002', partnerName: 'Jane Smith', partnerEmail: 'jane.smith@example.com', transactionStatus: 'Pending', remark: 'Another remark' },
    ];

    const filteredRows = rows.filter(row => {
        const fromDate = new Date(searchParams.fromDate);
        const toDate = new Date(searchParams.toDate);
        const transactionDate = new Date(row.transactionDate);

        return (
            (!searchParams.fromDate || transactionDate >= fromDate) &&
            (!searchParams.toDate || transactionDate <= toDate) &&
            (!searchParams.transactionId || row.transactionId.includes(searchParams.transactionId)) &&
            (!searchParams.partnerId || row.partnerId.includes(searchParams.partnerId)) &&
            (!searchParams.partnerName || row.partnerName.toLowerCase().includes(searchParams.partnerName.toLowerCase()))
        );
    });

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDownload = (row) => {
        const doc = new jsPDF();
        doc.text(`Transaction Details`, 10, 10);
        doc.text(`Transaction ID: ${row.transactionId}`, 10, 20);
        doc.text(`Transaction Date: ${row.transactionDate}`, 10, 30);
        doc.text(`Transaction Amount: $${row.transactionAmount.toFixed(2)}`, 10, 40);
        doc.text(`Partner ID: ${row.partnerId}`, 10, 50);
        doc.text(`Partner Name: ${row.partnerName}`, 10, 60);
        doc.text(`Partner Email: ${row.partnerEmail}`, 10, 70);
        doc.text(`Transaction Status: ${row.transactionStatus}`, 10, 80);
        doc.text(`Remark: ${row.remark}`, 10, 90);
        doc.save(`transaction_${row.transactionId}.pdf`);
    };

    return (
        <Container maxWidth="lg">
            <Box mt={4}>
                <Paper elevation={2} style={{marginBottom:'5%', borderRadius:'10px'}}>
                    <TableContainer>
                        <Table>
                            <TableHead style={{backgroundColor: '#027BFE'}}>
                                <TableRow>
                                    <TableCell style={{color:'white' , textAlign: 'center' }}>Sr. No.</TableCell>
                                    <TableCell style={{color:'white' , textAlign: 'center' }}>Transaction ID</TableCell>
                                    <TableCell style={{color:'white' , textAlign: 'center' }}>Transaction Date</TableCell>
                                    <TableCell style={{color:'white' , textAlign: 'center' }}>Transaction Amount</TableCell>
                                    <TableCell style={{color:'white' , textAlign: 'center' }}>Partner ID</TableCell>
                                    <TableCell style={{color:'white' , textAlign: 'center' }}>Partner Name</TableCell>
                                    <TableCell style={{color:'white' , textAlign: 'center' }}>Partner Email</TableCell>
                                    <TableCell style={{color:'white' , textAlign: 'center' }}>Transaction Status</TableCell>
                                    <TableCell style={{color:'white' , textAlign: 'center' }}>Remark</TableCell>
                                    <TableCell style={{color:'white' , textAlign: 'center' }}>Invoice</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                    <TableRow key={row.id} 
                                    className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}>
                                        <TableCell sx={{ textAlign: 'center' }}>{index + 1}</TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>{row.transactionId}</TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>{row.transactionDate}</TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>{row.transactionAmount.toFixed(2)}</TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>{row.partnerId}</TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>{row.partnerName}</TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>{row.partnerEmail}</TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>{row.transactionStatus}</TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>{row.remark}</TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>
                                            <IconButton onClick={() => handleDownload(row)}>
                                                <DownloadIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={filteredRows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Box>
        </Container>
    );
};

export default TransactionalTable;
