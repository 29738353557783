import React, { useState, useEffect } from 'react';
import { Container, Box, Grid, TextField, Button, Paper, Typography } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FTRTable from './FTRTable';
import axios from 'axios';

const FTR = () => {
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [debitData, setDebitData] = useState([]);
    const [creditData, setCreditData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const validationSchema = Yup.object({
        fromDate: Yup.date().required('Required'),
        toDate: Yup.date().required('Required'),
    });

    const fetchDebitData = async () => {
        try {
            const token = localStorage.getItem("jwtToken");
            const response = await axios.get(
                "https://api.ipaisa.co.in/myapp/auth/wtransReportDebt",
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setDebitData(response.data);
        } catch (error) {
            console.error("Error fetching debit data:", error);
            setError(error);
        }
    };

    const fetchCreditData = async () => {
        try {
            const token = localStorage.getItem("jwtToken");
            const response = await axios.get(
                "https://api.ipaisa.co.in/myapp/auth/wtransReportCred",
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setCreditData(response.data);
        } catch (error) {
            console.error("Error fetching credit data:", error);
            setError(error);
        }
    };

    useEffect(() => {
        setLoading(true);
        fetchDebitData();
        fetchCreditData();
        setLoading(false);
    }, []);

    const handleInputChange = (event, setFieldValue) => {
        const { name, value } = event.target;
        setFieldValue(name, value);
    };

    const handleSearch = (values) => {
        try {
            const { fromDate, toDate } = values;
            const filteredDebitData = debitData.filter((item) => item.date >= fromDate && item.date <= toDate);
            const filteredCreditData = creditData.filter((item) => item.date >= fromDate && item.date <= toDate);
            setFilteredData([...filteredDebitData, ...filteredCreditData]);
        } catch (errors) {
            console.log('Form validation errors:', errors);
        }
    };

    return (
        <>
            <Container maxWidth="lg" style={{ marginTop: '10%', padding: '0px 50px' }}>
            <Grid item xs={12}>
                <Typography variant="h4" align="start" gutterBottom style={{fontWeight:'bold'}}>
                Fund Transfer Reports
                </Typography>
            </Grid>
                <Box sx={{ mt: 4 }}>
                    <Paper elevation={2} sx={{ p: 3, borderRadius: 2 }}>
                        <Formik
                            initialValues={{ fromDate: '', toDate: '' }}
                            validationSchema={validationSchema}
                            onSubmit={(values) => {
                                handleSearch(values);
                            }}
                        >
                            {({ errors, touched, setFieldValue, handleBlur, values }) => (
                                <Form>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                id="from-date"
                                                name="fromDate"
                                                label="From Date"
                                                type="date"
                                                onChange={(event) => handleInputChange(event, setFieldValue)}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    style: { paddingTop: '0.6rem' },
                                                }}
                                                error={touched.fromDate && Boolean(errors.fromDate)}
                                                helperText={touched.fromDate && errors.fromDate}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                id="to-date"
                                                name="toDate"
                                                label="To Date"
                                                type="date"
                                                onChange={(event) => handleInputChange(event, setFieldValue)}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    style: { paddingTop: '0.6rem' },
                                                }}
                                                error={touched.toDate && Boolean(errors.toDate)}
                                                helperText={touched.toDate && errors.toDate}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                sx={{
                                                    borderRadius: '7px',
                                                    backgroundColor: '#027BFE',
                                                    color: 'white',
                                                    textTransform: 'none',
                                                    fontSize: '15px',
                                                    '&:hover': {
                                                        backgroundColor: '#027BFE',
                                                        color: 'white'
                                                    },
                                                }}
                                                type='submit'
                                                fullWidth
                                            >
                                                Search
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Paper>
                </Box>
            </Container>
            {loading && <Typography variant="h6" sx={{ textAlign: 'center', mt: 2 }}>Loading...</Typography>}
            {error && <Typography color="error" sx={{ textAlign: 'center', mt: 2 }}>{error}</Typography>}
            {!loading && !error && (
                <FTRTable
                    debitData={filteredData.length ? filteredData : debitData}
                    creditData={filteredData.length ? filteredData : creditData}
                />
            )}
        </>
    );
};

export default FTR;
