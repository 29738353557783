import React, { useState } from "react";
import {
  Button,
  TextField,
  Container,
  Grid,
  Typography,
  MenuItem,
  Box,
  CardMedia,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import BBPSLogo from "../Assets/BBPSLogo/BBPS.png";
import logo from "../Assets/login/iPaisaLogo.jpg";

export default function RElectricity() {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const formik = useFormik({
    initialValues: {
      consumerNumber: "",
      operator: "",
      amount: "",
    },
    validationSchema: Yup.object({
      consumerNumber: Yup.string()
        .matches(/^[0-9]{9}$/, "Please enter a valid 9-digit consumer number")
        .test(
          "not-all-zeros",
          "consumer number cannot be all zeros",
          (value) => value !== "0000000000"
        )
        .test(
          "not-eight-consecutive-zeros",
          "consumer number cannot contain eight consecutive zeros",
          (value) => value !== undefined && !/00000000/.test(value)
        )
        .required("Consumer number is required"),
      operator: Yup.string().required("Operator is required"),
      amount: Yup.string()
        .matches(
          /^[1-9][0-9]*$/,
          "Amount must be a positive number without leading zeros"
        )
        .required("Amount is required"),
    }),
    onSubmit: (values) => {
      // Perform form submission logic here
      setSubmitSuccess(true);
    },
  });

  const inputHeight = '56px'; 

  return (
    <Container maxWidth="lg" style={{ marginTop: '12%', marginBottom: '3%' }}>
      <Box
        mt={4}
        p={4}
        boxShadow={2}
        borderRadius={8}
        sx={{
          background: 'linear-gradient(135deg, #0B1E59 0%, #1840BF 100%)',
          color: 'white',
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} style={{ marginTop: "1%" }}>
          <CardMedia
              component="img"
              image={logo}
              alt="iPaisa Logo"
              style={{ backgroundColor: "transparent", borderRadius: "50%", marginBottom:'4%' }}
              sx={{
                height: { xs: 50, md: 90 },
                width: { xs: 50, md: 90 },
                mt: { xs: 1, md: 1 },
                marginRight: { xs: "10px", md: "10px" }, // Adjusted to ensure space between logo and text
              }}
            /> 
            <Box alignItems="center" mb={2}>
              <Typography variant="h4" sx={{ fontWeight: 'bold', fontSize: '35px' }}>
                Ingenious Electricity Bill Settlement
              </Typography>
            </Box>
            <Typography variant="body1" paragraph sx={{ fontSize: '20px', marginTop: '3%' }}>
              Empower your life with the efficient management of electricity bills, ensuring uninterrupted power supply for your world of possibilities.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box p={4} bgcolor="white" boxShadow={2} borderRadius={8}>
              <Typography variant="h4" color="#027BFE" sx={{ fontWeight: 'bold', fontSize: '30px' }}>
                Electricity Bill Payment
              </Typography>
              <Box display="flex" justifyContent="flex-end">
                <img src={BBPSLogo} alt="BBPS Logo" style={{ marginLeft: '25.5rem', width: '13%', height: '20%' }} />
              </Box>
              <TextField
                select
                fullWidth
                name="operator"
                label="Operator"
                value={formik.values.operator}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.operator && Boolean(formik.errors.operator)}
                helperText={formik.touched.operator && formik.errors.operator}
                style={{ marginTop: "20px" }}
              >
                <MenuItem value="">Select Operator</MenuItem>
                <MenuItem value="MAH">Maharashtra State Electricity Distbn Co Ltd</MenuItem>
                <MenuItem value="Adani">Adani Electricity Mumbai Limited</MenuItem>
              </TextField>
              {formik.touched.operator && formik.errors.operator && (
                <Typography variant="body2" color="error">
                  {formik.errors.operator}
                </Typography>
              )}
              <TextField
                fullWidth
                type="text"
                label="Consumer Number"
                name="consumerNumber"
                value={formik.values.consumerNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.consumerNumber && Boolean(formik.errors.consumerNumber)}
                helperText={formik.touched.consumerNumber && formik.errors.consumerNumber}
                inputProps={{
                  maxLength: 10,
                  pattern: "[0-9]*",
                  title: "Please enter a 10-digit number",
                }}
                style={{ marginTop: "20px" }}
              />
              <Button
                fullWidth
                type="submit"
                onClick={formik.handleSubmit}
                style={{ 
                  marginTop: "4%", 
                  backgroundColor: '#027BFE', 
                  color: 'white', 
                  textTransform: 'none', 
                  height: inputHeight ,
                  fontSize: 20
                }}
              >
                Confirm
              </Button>
              {submitSuccess && (
                <Typography
                  variant="body2"
                  style={{ color: "green", marginTop: "20px" }}
                >
                  Bill successfully submitted!
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
