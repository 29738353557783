import React, { useState } from 'react';
import { Container, Box, Grid, TextField, Button, Paper, IconButton } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import TransactionalTable from './TransactionTable';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
const TransactionReport = () => {
    const [searchParams, setSearchParams] = useState({
        fromDate: '',
        toDate: '',
        transactionId: '',
        partnerId: '',
        partnerName: '',
    });
    const validationSchema = Yup.object({
        fromDate: Yup.date().required('Required'),
        toDate: Yup.date().required('Required'),
    });
    const handleSearch = (values) => {
        setSearchParams(values);
    };
    return (
        <>
        <Grid style={{padding:'10px', width:'86.5%', marginLeft:"7%"}}>
            <Container maxWidth="70%" sx={{marginTop:'10%'}}>
                <Box sx={{ mt: 4 }}>
                    <Paper elevation={2} sx={{ borderRadius: 2 , padding:'30px'}}>
                        <Formik
                            initialValues={{ fromDate: '', toDate: '' }}
                            validationSchema={validationSchema}
                            onSubmit={handleSearch}
                        >
                            {({ errors, touched, setFieldValue, values }) => (
                                <Form>
                                    <Grid container spacing={4} alignItems="center">
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                id="from-date"
                                                name="fromDate"
                                                label="From Date"
                                                type="date"
                                                value={values.fromDate}
                                                onChange={(event) => setFieldValue('fromDate', event.target.value)}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    style: { paddingTop: '0.6rem' },
                                                }}
                                                error={touched.fromDate && Boolean(errors.fromDate)}
                                                helperText={touched.fromDate && errors.fromDate}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                id="to-date"
                                                name="toDate"
                                                label="To Date"
                                                type="date"
                                                value={values.toDate}
                                                onChange={(event) => setFieldValue('toDate', event.target.value)}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    style: { paddingTop: '0.6rem' },
                                                }}
                                                error={touched.toDate && Boolean(errors.toDate)}
                                                helperText={touched.toDate && errors.toDate}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button
                                                style={{borderRadius:'7px', backgroundColor: '#027BFE', color:'white', textTransform:'none', fontSize:'15px'}}
                                                type="submit"
                                                fullWidth
                                                onClick={() => handleSearch(values)}
                                            >
                                                Search
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Paper>
                </Box>
            </Container>
            </Grid>
            <TransactionalTable searchParams={searchParams} />
        </>
    );
};
export default TransactionReport;







