import { Box, Button, Container, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField } from '@mui/material'
import React, { useState } from "react";
import axios from "axios";
import BalanceTopComponent from './BalanceTopComponent'

const NewReverseMoney = () => {
    
    const [formData, setFormData] = useState({
        receiverId: "",
        receiverName: "",
        receiverType: "",
        amount: "",
        walletBalance: "", // Example wallet balance
        previousBalance: "4500", // Example previous balance
      });
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const response = await axios.post("https://api.ipaisa.co.in/myapp/admin/reversebalance", formData);
          console.log("Response:", response.data);
          // Add your logic to handle successful response (e.g., show success message)
        } catch (error) {
          console.error("There was an error reversing the balance!", error);
          // Add your logic to handle errors (e.g., show error message)
        }
      };


    const lineHeight = '50px';

  return (
    <Container style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
    <BalanceTopComponent />

    <Grid spacing={2} style={{ marginTop: '3%' }}>
        <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
            <Paper style={{ padding: '2rem', borderRadius: '8px' }}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} sx={{ marginTop: { xs: '0%', md:'1.5%'} }}>
                        <TextField
                            label="Receiver ID"
                            name="receiverId"
                            value={formData.receiverId}
                            onChange={handleInputChange}
                            fullWidth
                            required
                        />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ marginTop: { xs: '0%', md:'1.5%'} }}>
                        <TextField
                            label="Receiver Name / Business Name"
                            name="receiverName"
                            value={formData.receiverName}
                            onChange={handleInputChange}
                            fullWidth
                            required
                        />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ marginTop: { xs: '0%', md:'1.5%'} }}>
                        <FormControl fullWidth required>
                            <InputLabel>Receiver Type</InputLabel>
                            <Select
                            name="receiverType"
                            value={formData.receiverType}
                            onChange={handleInputChange}
                            label="Receiver Type"
                            >
                            <MenuItem value="Channel Partner">Channel Partner</MenuItem>
                            <MenuItem value="Super Distributor">Super Distributor</MenuItem>
                            <MenuItem value="Master Distributor">Master Distributor</MenuItem>
                            <MenuItem value="Area Distributor">Area Distributor</MenuItem>
                            <MenuItem value="Retailer">Retailer</MenuItem>
                            </Select>
                        </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ marginTop: { xs: '0%', md:'1.5%'} }}>
                        <TextField
                            label="Amount"
                            name="amount"
                            type="string"
                            value={formData.amount}
                            onChange={handleInputChange}
                            fullWidth
                            required
                        />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ marginTop: { xs: '0%', md:'1.5%'} }}>
                        <TextField
                            label="Wallet Balance"
                            value={formData.walletBalance}
                            InputProps={{
                            readOnly: true,
                            }}
                            fullWidth
                        />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ marginTop: { xs: '0%', md:'1.5%'} }}>
                        <TextField
                            label="Previous Balance"
                            value={formData.previousBalance}
                            InputProps={{
                            readOnly: true,
                            }}
                            fullWidth
                        />
                        </Grid>

                        <Grid item xs={12} sx={{ marginTop: { xs: '0%', md:'1%'} }}>
                            <Box display="flex" justifyContent="end" gap={1} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
                                <Button
                                    sx={{
                                        border: '1.5px solid #FF0000',
                                        height: lineHeight,
                                        fontSize: '16px',
                                        borderRadius: '8px',
                                        color: '#FF0000',
                                        textTransform: 'none',
                                        width: { xs: '100%', md: '18%' },
                                    }}
                                    >
                                    Cancel
                                </Button>        
                                <Button
                                    type="submit"
                                    fullWidth
                                    sx={{
                                        height: lineHeight,
                                        fontSize: '16px',
                                        textTransform:'none',
                                        width: { xs: '100%', md: '18%' },
                                        borderRadius: '8px',
                                        backgroundColor: "#027BFE",
                                        color: '#FFFFFF',
                                        '&:hover': {
                                            backgroundColor: "#027BFE",
                                            color: '#FFFFFF',
                                        },
                                    }}
                                >
                                    Reverse Money
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Grid>
    </Grid>
    </Container>
  )
}

export default NewReverseMoney
