import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  Paper,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Walllet_Icon } from '../../res/icons';
import { toast } from "react-toastify";

const AddMoney = () => {
  const [walletBalance, setWalletBalance] = useState(0);

  const fetchWalletBalance = async () => {
    try {
      const token = localStorage.getItem("jwtToken");
      const response = await axios.get(
        "https://api.ipaisa.co.in/myapp/auth/getwalletbalance",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setWalletBalance(response.data.walletBalance);
    } catch (error) {
      console.error("Error fetching wallet balance:", error);
    }
  };

  useEffect(() => {
    fetchWalletBalance();
  }, []);

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .typeError("Amount must be a number")
      .positive("Amount must be positive")
      .required("Amount is required"),
    remark: Yup.string().required("Remark is required"),
  });

  const formik = useFormik({
    initialValues: {
      amount: "",
      remark: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // Directly initiate payment
      initiatePayment(values);
    },
  });

  const initiatePayment = async (values) => {
    try {
      const paymentData = {
        txnid: "iPaisa" + new Date().getTime(),
        amount: values.amount,
        productinfo: "Add Money",
        firstname: "Edsom",
        phone: "8378054080",
        email: "edsomfintech@gmail.com",
        surl: "https://ipaisa.co.in/signin",
        furl: "https://ipaisa.co.in/signin",
      };

      const token = localStorage.getItem("jwtToken");
      const response = await axios.post(
        "https://api.ipaisa.co.in/myapp/auth/easebuzz/initiate-payment",
        paymentData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseBody = response.data;

      if (responseBody.status === 1) {
        const accessKey = responseBody.data;
        const paymentUrl = `https://pay.easebuzz.in/pay/${accessKey}`;

        const newWindow = window.open(paymentUrl, "_blank");

        if (newWindow) {
          const checkWindowUrl = setInterval(() => {
            try {
              const currentUrl = newWindow.location.href;
              const successUrl = "https://ipaisa.co.in/signin";
              const failureUrl = "https://ipaisa.co.in/signin";

              if (currentUrl === successUrl || currentUrl === failureUrl) {
                clearInterval(checkWindowUrl);
                newWindow.close();
                setTimeout(() => {
                  handlePaymentStatus(paymentData.txnid);
                }, 1000);
              }
            } catch (e) {
              // Catch and ignore cross-origin errors
            }
          }, 1000);

          setTimeout(() => {
            if (!newWindow.closed) {
              newWindow.close();
              console.log("Payment window closed after 2 minutes");
            }
          }, 120000);
        }
      } else {
        console.error("Access key not received from backend");
      }
    } catch (error) {
      console.error("Error initiating payment:", error);
    }
  };

  const handlePaymentStatus = async (txnid) => {
    try {
      const token = localStorage.getItem("jwtToken");

      const response = await axios.post(
        "https://api.ipaisa.co.in/myapp/auth/transaction-api-v2",
        { txnid },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      handlePaymentSuccess(response.data);

      await fetchWalletBalance();

      if (response.data) {
        toast.success("Funds Added Successfully");
      }
    } catch (error) {
      console.error("Error verifying payment:", error);
      handlePaymentSuccess({ status: 0, message: "Error verifying payment" });
    }
  };

  const handlePaymentSuccess = (response) => {
    console.log("Payment success response:", response);
    if (response.msg?.status === "userCancelled") {
      console.log("User cancelled the payment");
    }
    if (response.status === 1) {
      toast.success("Payment successful");
    } else {
      // toast.error("Payment failed: " + response.message);
    }
  };

  return (
    <div style={{ height: "100vh", backgroundColor: "#F2F5FA" }}>
      <Container
        maxWidth="lg"
        style={{
          width: "90%",
          marginTop: "7%",
          padding: "2%",
          height: "80%",
          overflow: "hidden",
        }}
      >
        <Paper
          elevation={3}
          style={{ padding: "2%", borderRadius: "12px", height: "70%" }}
        >
          <Grid container alignItems="center" justifyContent="space-evenly">
            <Grid item xs={12} sm={6} md={6}>
              <Typography
                gutterBottom
                style={{ fontSize: "24px", fontWeight: "bold", color: "black" }}
              >
                Add Money
              </Typography>
              <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h7">Amount</Typography>
                    <TextField
                      name="amount"
                      type="number"
                      value={formik.values.amount}
                      onChange={formik.handleChange}
                      fullWidth
                      required
                      InputLabelProps={{
                        style: { height: "2.5em" },
                      }}
                      error={formik.touched.amount && Boolean(formik.errors.amount)}
                      helperText={formik.touched.amount && formik.errors.amount}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h7">Remark</Typography>
                    <TextField
                      name="remark"
                      value={formik.values.remark}
                      onChange={formik.handleChange}
                      fullWidth
                      required
                      multiline
                      rows={4}
                      InputLabelProps={{
                        style: { height: "3.5em" },
                      }}
                      error={formik.touched.remark && Boolean(formik.errors.remark)}
                      helperText={formik.touched.remark && formik.errors.remark}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      style={{
                        textAlign: "center",
                        color: '#D9D9D9',
                        width: '100%',
                        height: '60px',
                        borderRadius: '12px'
                      }}
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={<AddCircleOutlineIcon />}
                    >
                      Add Money
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Grid item xs={12} sm={6} md={3} textAlign="center">
              <Card
                style={{
                  width: '330px',
                  height: '280px',
                  borderRadius: '12px',
                  alignContent: 'center',
                  backgroundImage: 'linear-gradient(332.55deg, #121A4A 22.12%, #2B3EB0 77.43%)',
                }}
              >
                <CardContent>
                  <Walllet_Icon />
                  <Typography sx={{ color: '#FFFFFF', fontSize: '24px' }} gutterBottom>
                    Wallet Balance
                  </Typography>
                  <Typography variant="h5" sx={{ color: '#FFFFFF', fontSize: '48px' }} component="h2">
                    ₹ {walletBalance.toFixed(2)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </div>
  );
};

export default AddMoney;
