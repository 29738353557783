import PartnersArrow_SVG from './PartnersArrowIcon.svg'
import AddUser_SVG from './AddUserIcon.svg'
import Filter_SVG from './filterIcon.svg'
import Download_SVG from './DownloadIcon.svg'
import Plus_SVG from './PlusIcon.svg'
import ViewDetails_SVG from './ViewDetailIcon.svg'
import DeleteUser_SVG from './DeleteUserIcon.svg'


export const PartnerArrowIcon = (hg, wd) => {
    return <img src={PartnersArrow_SVG} alt='PartnersArrow_SVG' style={{height:'10px'}}/>
}
export const AddUserIcon = (hg, wd) => {
    return <img src={AddUser_SVG} alt='AddUser_SVG' style={{height:'25px'}}/>
}
export const FilterIcon = (hg, wd) => {
    return <img src={Filter_SVG} alt='Filter_SVG' style={{height:'30px'}}/>
}
export const DownloadIcon = (hg, wd) => {
    return <img src={Download_SVG} alt='Download_SVG' style={{height:'20px'}}/>
}
export const PlusIcon = (hg, wd) => {
    return <img src={Plus_SVG} alt='Plus_SVG' style={{height:'18px'}}/>
}
export const ViewDetailsIcon = (hg, wd) => {
    return <img src={ViewDetails_SVG} alt='ViewDetails_SVG' style={{height:'20px'}}/>
}
export const DeleteUserIcon = (hg, wd) => {
    return <img src={DeleteUser_SVG} alt='DeleteUser_SVG' style={{height:'20px'}}/>
}
