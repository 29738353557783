import React, { useState } from 'react';
import { Container, Box, Grid, TextField, Button, Paper, IconButton } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import TransactionalTable from './TransactionTable';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LocationSelector from '../Partner/LocationSelector';

const TransactionHistoryReport = () => {
    const [showAddressAndPincode, setShowAddressAndPincode] = useState(true);
    const [searchParams, setSearchParams] = useState({
        fromDate: '',
        toDate: '',
        transactionId: '',
        partnerId: '',
        partnerName: '',
        country: null,
        state: null,
        city: null,
        partnerType: '',
    });

    const validationSchema = Yup.object({
        fromDate: Yup.date().required('Required'),
        toDate: Yup.date().required('Required'),
    });

    const handleSearch = (values) => {
        setSearchParams(values);
    };

    const handleLocationChange = (location) => {
        setSearchParams((prevParams) => ({ ...prevParams, ...location }));
    };
console.log(showAddressAndPincode,"inside transaction history")
    return (
        <>
            <Container maxWidth="lg" sx={{marginTop:'9%'}}>
                <Box sx={{ mt: 4 }}>
                    <Paper elevation={2} sx={{ p: 3, borderRadius: 2}}>
                        <Formik
                            initialValues={{
                                fromDate: '',
                                toDate: '',
                                country: '',
                                state: '',
                                city: '',
                                partnerType: ''
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleSearch}
                        >
                            {({ errors, touched, setFieldValue, values }) => (
                                <Form style={{paddingLeft:'2.5%'}}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                id="from-date"
                                                name="fromDate"
                                                label="From Date"
                                                type="date"
                                                value={values.fromDate}
                                                onChange={(event) => setFieldValue('fromDate', event.target.value)}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    style: { paddingTop: '0.6rem' },
                                                }}
                                                error={touched.fromDate && Boolean(errors.fromDate)}
                                                helperText={touched.fromDate && errors.fromDate}
                                            />
                                        </Grid>
                                        <Grid item xs={5.7}>
                                            <TextField
                                                fullWidth
                                                id="to-date"
                                                name="toDate"
                                                label="To Date"
                                                type="date"
                                                value={values.toDate}
                                                onChange={(event) => setFieldValue('toDate', event.target.value)}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    style: { paddingTop: '0.6rem' },
                                                }}
                                                error={touched.toDate && Boolean(errors.toDate)}
                                                helperText={touched.toDate && errors.toDate}
                                            />
                                        </Grid>
                                    </Grid>
                                </Form>
                                
                            )}
                            
                        </Formik>
                        <LocationSelector newUser={searchParams} setNewUser={handleLocationChange} showAddressAndPincode={showAddressAndPincode}/>
                        <Grid item style={{marginLeft:'2.6%'}}>
                        <Button
                            style={{borderRadius:'7px', backgroundColor: '#027BFE', color:'white', textTransform:'none', fontSize:'15px', width:'31.7%', height:'70%'}}
                            type="submit"
                            variant="contained"
                        >
                        Search
                        </Button>
                    </Grid>
                    </Paper>
                </Box>

            </Container>
            <Box sx={{ mt: 4 }}>
                <TransactionalTable searchParams={searchParams} />
            </Box>
        </>
    );
};

export default TransactionHistoryReport;
