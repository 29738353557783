import React from 'react';
import { Card, CardMedia, Container, Grid, Typography } from '@mui/material';
import { PartnerCards } from './PartnerCards'; // Import the PartnerCards component
import "@fontsource/open-sans";
import bannergif from '../Assets/Dashboard/urban-202.gif';

const Dashboard2 = () => {
  return (
    <>
      <Container>
        <Grid
          container
          marginTop={10}
          style={{ minHeight: '80vh' }}
          spacing={2}
        >
          {/* First Grid Item */}
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              width: '70px',
              height: '110px',
              borderRadius: '16px',
              opacity: '0px',
              backgroundColor: '#3F64E4',
              boxShadow: '0px 4px 5px 0px rgba(63, 100, 228, 0.3)',
              position: 'relative',
              padding: '16px',
            }}
          >
            <Grid
              container
              direction="column"
              alignItems="flex-start"
              flexWrap="nowrap"
              justifyContent="center"
              sx={{
                height: '100%',
                width: '100%',
              }}
            >
              <Grid item>
                <Typography
                  variant="h1"
                  sx={{
                    fontFamily: 'Open Sans',
                    fontSize: '28px',
                    fontWeight: 800,
                    lineHeight: '38.13px',
                    color: '#F0F0F0',
                    marginTop: '4%',
                  }}
                >
                  Hello, Prateek!
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: 'Open Sans',
                    fontSize: '12px',
                    fontWeight: 350,
                    lineHeight: '38.13px',
                    color: '#F0F0F0',
                  }}
                >
                  Welcome, Admin: Ready to Take Charge
                </Typography>
              </Grid>
            </Grid>
            <Card
              sx={{
                position: 'absolute',
                width: '124.81px',
                height: '79px',
                marginLeft: '83%',
                opacity: '0.8',
                boxShadow: 'none',
                borderRadius: '0',
                overflow: 'hidden',
                backgroundColor: 'transparent',
              }}
            >
              <CardMedia
                component="img"
                src={bannergif}
                alt="Banner GIF"
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                }}
              />
            </Card>
          </Grid>

          {/* Separate Container for the Second Grid Item */}
          <Container>
            <Grid
              item
              xs={12}
              container
              marginTop={-10}
              direction="column"
              justifyContent="space-between"
              sx={{
                width: '650px',
                height: '300px',
                borderRadius: '16px',
                border: '1px solid #D9D9D91A',
                backgroundColor: '#FFFFFF',
                boxShadow: '4px 4px 15px 15px rgba(0.1, 0.1, 0.1, 0.1)',
              }}
            >
              <Grid
                container
                direction="column"
                justifyContent="space-evenly"
                alignItems="center"
                sx={{
                  gap: '1%',
                  width: '100%',
                  height: '100%',
                  borderRadius: '16px',
                  position: 'relative',
                }}
              >
                <Grid
                  item
                  xs={6}
                  sx={{
                    width: '301px',
                    height: '164px',
                    borderRadius: '8px',
                    backgroundColor: '#00178D',
                    opacity: '0px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {/* Optional: Content inside the inner Grid */}
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    width: '301px',
                    height: '164px',
                    borderRadius: '8px',
                    backgroundColor: '#008D39',
                    opacity: '0px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {/* Optional: Content inside the inner Grid */}
                </Grid>
              </Grid>
            </Grid>

            {/* Add PartnerCards Component Here */}
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              sx={{
                marginTop: '20px',
              }}
            >
              <PartnerCards />
            </Grid>
          </Container>
        </Grid>
      </Container>
    </>
  );
};

export default Dashboard2;
