import React, { useState, useEffect } from 'react';
import { Container, Grid, Paper, Typography, TextField, Button} from '@mui/material'
import NewPayInOutTopComp from './NewPayInOutTopComp'
import * as Yup from 'yup';
import axios from 'axios';
import { Formik, Form } from 'formik';
import NewFTRTable from './NewFTRTable';

const NewFundTReport = () => {

  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [debitData, setDebitData] = useState([]);
  const [creditData, setCreditData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const validationSchema = Yup.object({
      fromDate: Yup.date().required('Required'),
      toDate: Yup.date().required('Required'),
  });

  const fetchDebitData = async () => {
      try {
          const token = localStorage.getItem("jwtToken");
          const response = await axios.get(
              "https://api.ipaisa.co.in/myapp/auth/wtransReportDebt",
              {
                  headers: {
                      Authorization: `Bearer ${token}`,
                  },
              }
          );
          setDebitData(response.data);
      } catch (error) {
        console.error("Error fetching debit data:", error);
        setError(error.message); // Extract the message
    }
  };

  const fetchCreditData = async () => {
      try {
          const token = localStorage.getItem("jwtToken");
          const response = await axios.get(
              "https://api.ipaisa.co.in/myapp/auth/wtransReportCred",
              {
                  headers: {
                      Authorization: `Bearer ${token}`,
                  },
              }
          );
          setCreditData(response.data);
      } catch (error) {
          console.error("Error fetching credit data:", error);
          setError(error.message); // Extract the message
        }
  };

  useEffect(() => {
      setLoading(true);
      fetchDebitData();
      fetchCreditData();
      setLoading(false);
  }, []);

  const handleInputChange = (event, setFieldValue) => {
      const { name, value } = event.target;
      setFieldValue(name, value);
  };

  const handleSearch = (values) => {
      try {
          const { fromDate, toDate } = values;
          const filteredDebitData = debitData.filter((item) => item.date >= fromDate && item.date <= toDate);
          const filteredCreditData = creditData.filter((item) => item.date >= fromDate && item.date <= toDate);
          setFilteredData([...filteredDebitData, ...filteredCreditData]);
      } catch (errors) {
          console.log('Form validation errors:', errors);
      }
  };

  const lineHeight = '50px';
  return (
    <>

    <Container style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
    <NewPayInOutTopComp/>

             <Grid style={{marginTop:'1%'}}>
                <Typography style={{fontWeight:'bold', fontFamily:'sans-serif', fontSize:'19px', color:'#343C6A'}}>Fund Transfer Report</Typography>
             </Grid>

             <Grid style={{ marginTop: '3%' }}>
                <Paper elevation={2} sx={{ p: 3, borderRadius: 2 }}>
                            <Formik
                                initialValues={{ fromDate: '', toDate: '' }}
                                validationSchema={validationSchema}
                                onSubmit={(values) => {
                                    handleSearch(values);
                                }}
                            >
                                {({ errors, touched, setFieldValue, handleBlur, values }) => (
                                    <Form>
                                        <Grid container spacing={2} sx={{marginTop: { xs: '0%', md:'1%'} }}>
                                        <Grid item xs={12} md={6}>
                                                <TextField
                                                    fullWidth
                                                    id="from-date"
                                                    name="fromDate"
                                                    label="From Date"
                                                    type="date"
                                                    onChange={(event) => handleInputChange(event, setFieldValue)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        style: { paddingTop: '0.6rem' },
                                                    }}
                                                    error={touched.fromDate && Boolean(errors.fromDate)}
                                                    helperText={touched.fromDate && errors.fromDate}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    fullWidth
                                                    id="to-date"
                                                    name="toDate"
                                                    label="To Date"
                                                    type="date"
                                                    onChange={(event) => handleInputChange(event, setFieldValue)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        style: { paddingTop: '0.6rem' },
                                                    }}
                                                    error={touched.toDate && Boolean(errors.toDate)}
                                                    helperText={touched.toDate && errors.toDate}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sx={{display:'flex', justifyContent:'end', marginTop: { xs: '0%', md:'1%'} }}>
                                                <Button
                                                    sx={{
                                                      height: lineHeight,
                                                      fontSize: '16px',
                                                      textTransform:'none',
                                                      width: { xs: '100%', md: '18%' },
                                                      borderRadius: '8px',
                                                      backgroundColor: "#027BFE",
                                                      color: '#FFFFFF',
                                                      '&:hover': {
                                                          backgroundColor: "#027BFE",
                                                          color: '#FFFFFF',
                                                      },
                                                    }}
                                                    type='submit'
                                                    fullWidth
                                                >
                                                    Search
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                            </Formik>
                </Paper>
             </Grid>

             {loading && <Typography variant="h6" sx={{ textAlign: 'center', mt: 2 }}>Loading...</Typography>}
            {error && <Typography color="error" sx={{ textAlign: 'center', mt: 2 }}>{error}</Typography>}
            {!loading && !error && (
                <NewFTRTable
                    debitData={filteredData.length ? filteredData : debitData}
                    creditData={filteredData.length ? filteredData : creditData}
                />
            )}
    </Container>
    </>


  );
};

export default NewFundTReport
