import React, { useState, useEffect } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  TextField,
  Button,
  Typography,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { toast } from "react-toastify";
import { BrowserView, MobileView } from "react-device-detect";
import { makeStyles } from '@mui/styles';
import { getBankDetails, addBeneficiary, verifyBankDetails } from "./api";

const useStyles = makeStyles((theme) => ({
  evenRow: {
    backgroundColor: '#C1E0FB',
  },
  oddRow: {
    backgroundColor: '#FFFFFF',
  }
}));

const AddBeneficiary = () => {
  const classes = useStyles();
  const [bankDetails, setBankDetails] = useState([]);
  const [statusMessage, setStatusMessage] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [verifyResult, setVerifyResult] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getBankDetails(setBankDetails, setStatusMessage);
  }, []);

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    bankName: Yup.string().required("Bank Name is required"),
    branch: Yup.string().required("Branch is required"),
    ifsc: Yup.string().matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Invalid IFSC code"),
    accountNumber: Yup.string().required("Account Number is required"),
  });

  const initialValues = {
    name: "",
    bankName: "",
    branch: "",
    ifsc: "",
    accountNumber: "",
  };

  const onSubmit = (values, { setSubmitting }) => {
    addBeneficiary(values, setSubmitting, () => getBankDetails(setBankDetails, setStatusMessage));
  };

  const handleCancel = () => {
    // navigate("/navbar");
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleVerifyBank = async (accountNumber, ifsc) => {
    try {
      const result = await verifyBankDetails(accountNumber, ifsc);
      setVerifyResult(result.status);

      if (result.status === "Transaction Successful") {
        toast.success("Account Verification Successful");
      } else {
        toast.error("Transaction Failed: " + result.status);
      }
    } catch (error) {
      toast.error("Failed to verify bank details");
    }
  };

  const filteredBankDetails = bankDetails.filter((bank) =>
    (bank.name && bank.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (bank.user.userid && bank.user.userid.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (bank.user.mobileNumber && bank.user.mobileNumber.includes(searchQuery)) ||
    (bank.bankName && bank.bankName.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (bank.branch && bank.branch.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (bank.ifsc && bank.ifsc.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (bank.accountNumber && bank.accountNumber.toString().includes(searchQuery))
  );

  const renderForm = (values, handleChange, handleBlur, isSubmitting) => (
    <Form>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            variant="outlined"
            id="name"
            name="name"
            label="Name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={<ErrorMessage name="name" component="div" style={{ color: "red" }} />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            variant="outlined"
            id="bankName"
            name="bankName"
            label="Bank Name"
            value={values.bankName}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={<ErrorMessage name="bankName" component="div" style={{ color: "red" }} />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            variant="outlined"
            id="branch"
            name="branch"
            label="Branch"
            value={values.branch}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={<ErrorMessage name="branch" component="div" style={{ color: "red" }} />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            variant="outlined"
            id="ifsc"
            name="ifsc"
            label="IFSC"
            value={values.ifsc}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={<ErrorMessage name="ifsc" component="div" style={{ color: "red" }} />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            variant="outlined"
            id="accountNumber"
            name="accountNumber"
            label="Account Number"
            value={values.accountNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={<ErrorMessage name="accountNumber" component="div" style={{ color: "red" }} />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            style={{
              backgroundColor: "#0288D1",
              color: "white",
              fontWeight: "bold",
              textTransform: "none",
              width: "100%",
              borderRadius: "9px",
            }}
            onClick={() => handleVerifyBank(values.accountNumber, values.ifsc)}
          >
            Verify Bank
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" gap={2} height="135%">
            <Button
              style={{
                backgroundColor: '#FF0000',
                color: '#ffffff',
                textTransform: 'none',
                width: "50%",
                borderRadius: "9px",
              }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              style={{
                backgroundColor: "#027BFE",
                color: "white",
                fontWeight: "bold",
                textTransform: "none",
                width: "50%",
                borderRadius: "9px",
              }}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Form>
  );

  return (
    <>
      <BrowserView>
        <Container
          maxWidth="xl"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            padding: "80px",
            marginBottom: '5% '
          }}
        >
          <Grid
            elevation={3}
            style={{
              padding: "20px",
              borderRadius: "20px",
              width: "100%",
              height: "95%",
            }}
          >
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ marginBottom: "20px" }}
            >
              <Typography
                variant="h5"
                style={{
                  color: "#0077FF",
                  fontWeight: "bold",
                  marginBottom: "10px",
                }}
              >
                Add Beneficiary
              </Typography>
            </Grid>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ values, handleChange, handleBlur, isSubmitting }) =>
                renderForm(values, handleChange, handleBlur, isSubmitting)
              }
            </Formik>
            <Box marginTop={2}>
              <TextField
                label="Search"
                variant="outlined"
                value={searchQuery}
                onChange={handleSearchChange}
                fullWidth
              />
              <Box maxHeight="400px" overflow="auto" marginTop={2}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>User ID</TableCell>
                      <TableCell>Mobile Number</TableCell>
                      <TableCell>Bank Name</TableCell>
                      <TableCell>Branch</TableCell>
                      <TableCell>IFSC</TableCell>
                      <TableCell>Account Number</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredBankDetails.map((bank, index) => (
                      <TableRow
                        key={bank.id}
                        className={index % 2 === 0 ? classes.evenRow : classes.oddRow}
                      >
                        <TableCell>{bank.name}</TableCell>
                        <TableCell>{bank.user?.userid}</TableCell>
                        <TableCell>{bank.user?.mobileNumber}</TableCell>
                        <TableCell>{bank.bankName}</TableCell>
                        <TableCell>{bank.branch}</TableCell>
                        <TableCell>{bank.ifsc}</TableCell>
                        <TableCell>{bank.accountNumber}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
              {statusMessage && <Typography>{statusMessage}</Typography>}
              {/* {verifyResult && <Typography>Verification Result: {verifyResult}</Typography>} */}
            </Box>
          </Grid>
        </Container>
      </BrowserView>
      <MobileView>
        <Container maxWidth="sm" style={{ padding: "20px", height: "100vh" }}>
          <Grid
            elevation={3}
            style={{
              padding: "20px",
              borderRadius: "10px",
              width: "100%",
              height: "100%",
            }}
          >
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ marginBottom: "20px" }}
            >
              <Typography
                variant="h5"
                component="h1"
                style={{ fontWeight: "bold" }}
              >
                Add Beneficiary
              </Typography>
            </Grid>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, handleChange, handleBlur, values }) =>
                renderForm(values, handleChange, handleBlur, isSubmitting)
              }
            </Formik>
            <Box mt={4}>
              <Typography variant="h6" style={{ marginBottom: "10px" }}>
                
              </Typography>
              {statusMessage ? (
                <Typography></Typography>
              ) : (
                <>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    style={{ marginBottom: "20px" }}
                  />
                  <Table>
                    <TableHead style={{ backgroundColor: '#027BFE' }}>
                      <TableRow>
                        <TableCell sx={{ color: '#FFFFFF', textAlign: 'center' }} >Sr.No</TableCell>
                        <TableCell sx={{ color: '#FFFFFF', textAlign: 'center' }} >Name</TableCell>
                        <TableCell sx={{ color: '#FFFFFF', textAlign: 'center' }} >User ID</TableCell>
                        <TableCell sx={{ color: '#FFFFFF', textAlign: 'center' }} >Mobile Number</TableCell>
                        <TableCell sx={{ color: '#FFFFFF', textAlign: 'center' }} >Bank Name</TableCell>
                        <TableCell sx={{ color: '#FFFFFF', textAlign: 'center' }} >Branch</TableCell>
                        <TableCell sx={{ color: '#FFFFFF', textAlign: 'center' }} >IFSC</TableCell>
                        <TableCell sx={{ color: '#FFFFFF', textAlign: 'center' }} >Account Number</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredBankDetails.length > 0 ? (
                        filteredBankDetails.map((bank, index, row) => (
                          <TableRow key={bank.id}
                            className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}>
                            <TableCell sx={{ textAlign: 'center' }}>{index + 1}</TableCell>
                            <TableCell sx={{ textAlign: 'center' }} >{bank.name}</TableCell>
                            <TableCell sx={{ textAlign: 'center' }} >{bank.user.userid}</TableCell>
                            <TableCell sx={{ textAlign: 'center' }} >{bank.user.mobileNumber}</TableCell>
                            <TableCell sx={{ textAlign: 'center' }} >{bank.bankName}</TableCell>
                            <TableCell sx={{ textAlign: 'center' }} >{bank.branch}</TableCell>
                            <TableCell sx={{ textAlign: 'center' }} >{bank.ifsc}</TableCell>
                            <TableCell sx={{ textAlign: 'center' }} >{bank.accountNumber}</TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={7}>No bank details found.</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </>
              )}
            </Box>
          </Grid>
        </Container>
      </MobileView>
    </>
  );
};

export default AddBeneficiary;
