import React from 'react';
import {
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Box,
    Typography
} from '@mui/material';

const ViewUserDialog = ({ open, handleClose, user }) => {
    const handleCancel = () => {
        handleClose(); // Close the dialog
        console.log("close dialog")
    };

    return (
        <Dialog
            open={open}
            onClose={handleCancel} // Handle close event
            fullWidth
            maxWidth="lg"
        >
            <DialogTitle>User Details</DialogTitle>
            <DialogContent style={{marginTop:'2rem'}} >
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            label="CP ID"
                            value={user?.userid || ''}
                            fullWidth
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Parent ID"
                            value={user?.parentId || ''}
                            fullWidth
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Name"
                            value={`${user?.firstName || ''} ${user?.middleName || ''} ${user?.lastName || ''}`}
                            fullWidth
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Business Name"
                            value={user?.bussinessName || ''}
                            fullWidth
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Mobile Number"
                            value={user?.mobileNumber || ''}
                            fullWidth
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="DOB"
                            type="date"
                            value={user?.dob || ''}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Wallet Balance"
                            value={user?.walletBalance || 0}
                            fullWidth
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Status"
                            value={user?.status || ''}
                            fullWidth
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ width: '100%', height: '100%' }}>
                        <Box mt={2}>
                            <Typography variant="subtitle1">Uploaded Agreement</Typography>
                            <Box
                                sx={{
                                    border: '1px solid #000',
                                    borderRadius: '8px',
                                    padding: 2,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '500px'
                                }}
                            >
                                <iframe
                                    src={`${process.env.PUBLIC_URL}/IpaisaApplication.pdf`}
                                    width="100%"
                                    height="500px"
                                    style={{ border: 'none' }}
                                    title="PDF Document"
                                ></iframe>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between' }}>
                <Button
                    onClick={handleCancel}
                    color="primary"
                    sx={{
                        width: '150px',
                        height: '54px',
                        borderRadius: '12px',
                        backgroundColor: '#FF0000',
                                color: '#ffffff',
                                '&:hover': {
                                    backgroundColor: '#FF0000', color: '#ffffff'
                                },
                    }}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ViewUserDialog;
