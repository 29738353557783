import React from 'react';
import { Box, Typography, Modal, Card, CardContent, IconButton } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SuccessGif from '../Assets/TransactionSuccessandFailed/SuccessGif.gif';
import CloseIcon from '@mui/icons-material/Close';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  borderRadius: 2,
  outline: 'none',
};

const successIconStyle = {
  backgroundColor: '#027BFE',
  borderRadius: '50%',
  padding: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 60,
  height: 60,
  margin: '-40px auto 0',
  border: '5px solid #027BFE',
};

const TransactionSuccessFull = ({ open, handleClose }) => {
  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="transaction-success-title">
      <Card sx={{ ...modalStyle, width: '25%', background: 'linear-gradient(to bottom, white 50%, #027BFE 50%)' }}>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton onClick={handleClose} sx={{ p: 0 }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ textAlign: 'center', mb: 2 }}>
            <Typography id="transaction-success-title" variant="h6" sx={{ mt: 5, mb: 8, color: '#027BFE', fontWeight: 'bold' }}>
              Transaction Successful
            </Typography>
            <Box sx={successIconStyle} > 
              <img src={SuccessGif} style={{height:'100%', width:'100%'}} />
            </Box>
          </Box>
          <Typography sx={{ textAlign: 'center', px: 2, color: 'white' }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </Typography>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default TransactionSuccessFull;
