import React, { useState } from 'react';
import { Container, Box, Grid, TextField, Button, Paper, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DownloadIcon from '@mui/icons-material/Download';
import jsPDF from 'jspdf';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    evenRow: {
        backgroundColor: '#C1E0FB',
    },
    oddRow: {
        backgroundColor: '#FFFFFF',
    }
}));

const UserCashback = () => {
    const classes = useStyles();
    const [searchParams, setSearchParams] = useState({
        fromDate: '',
        toDate: '',
    });

    const [tableData, setTableData] = useState([]); // State to hold table data

    const validationSchema = Yup.object({
        fromDate: Yup.date().required('Required'),
        toDate: Yup.date().required('Required'),
    });

    const handleSearch = (values) => {
        setSearchParams(values);
        // Fetch data based on the search params
        const data = fetchTableData(values);
        setTableData(data);
    };

    const fetchTableData = (params) => {
        // This is a mock function. Replace it with your data fetching logic.
        return [
            {
                transactionId: '12345',
                date: '2023-01-01',
                partnerRequestId: '54321',
                requestedBy: 'John Doe',
                amount: 1000,
                status: 'Approved',
                remarks: 'Transaction successful',
                approvedDate: '2023-01-02',
                processedBy: 'Jane Doe',
                paymentMethod: 'Bank Transfer',
            },
            // Add more data objects as needed
        ];
    };

    const handleDownload = (row) => {
        const doc = new jsPDF();
        doc.text(`Transaction Details`, 10, 10);
        doc.text(`Transaction ID: ${row.transactionId}`, 10, 20);
        doc.text(`Date: ${row.date}`, 10, 30);
        doc.text(`Partner Request ID: ${row.partnerRequestId}`, 10, 40);
        doc.text(`Requested By: ${row.requestedBy}`, 10, 50);
        doc.text(`Amount: $${row.amount.toFixed(2)}`, 10, 60);
        doc.text(`Status: ${row.status}`, 10, 70);
        doc.text(`Remarks: ${row.remarks}`, 10, 80);
        doc.text(`Approved Date: ${row.approvedDate}`, 10, 90);
        doc.text(`Processed By: ${row.processedBy}`, 10, 100);
        doc.text(`Payment Method: ${row.paymentMethod}`, 10, 110);
        doc.save(`transaction_${row.transactionId}.pdf`);
    };

    return (
        <Container maxWidth="lg" sx={{marginTop:'7%'}}>
            <Box sx={{ mt: 4 }}>
            <Paper elevation={2} sx={{ p: 3, borderRadius: 2 }}>
                    <Formik
                        initialValues={{ fromDate: '', toDate: '' }}
                        validationSchema={validationSchema}
                        onSubmit={handleSearch}
                    >
                        {({ errors, touched, setFieldValue, values }) => (
                           <Form>
                           <Grid container spacing={4} alignItems="center">
                               <Grid item xs={6}>
                                   <TextField
                                       fullWidth
                                       id="from-date"
                                       name="fromDate"
                                       label="From Date"
                                       type="date"
                                       value={values.fromDate}
                                       onChange={(event) => setFieldValue('fromDate', event.target.value)}
                                       InputLabelProps={{
                                           shrink: true,
                                       }}
                                       inputProps={{
                                           style: { paddingTop: '0.6rem' },
                                       }}
                                       error={touched.fromDate && Boolean(errors.fromDate)}
                                       helperText={touched.fromDate && errors.fromDate}
                                   />
                               </Grid>
                               <Grid item xs={6}>
                                   <TextField
                                       fullWidth
                                       id="to-date"
                                       name="toDate"
                                       label="To Date"
                                       type="date"
                                       value={values.toDate}
                                       onChange={(event) => setFieldValue('toDate', event.target.value)}
                                       InputLabelProps={{
                                           shrink: true,
                                       }}
                                       inputProps={{
                                           style: { paddingTop: '0.6rem' },
                                       }}
                                       error={touched.toDate && Boolean(errors.toDate)}
                                       helperText={touched.toDate && errors.toDate}
                                   />
                               </Grid>
                               <Grid item xs={6}>
                                   <Button
                                       style={{borderRadius:'7px', backgroundColor: '#027BFE', color:'white', textTransform:'none', fontSize:'15px'}}
                                       type="submit"
                                       fullWidth
                                       onClick={() => handleSearch(values)}
                                   >
                                       Search
                                   </Button>
                               </Grid>
                           </Grid>
                       </Form>
                        )}
                    </Formik>
                </Paper>
            </Box>

            <Box sx={{ mt: 4 }}>
                <Paper elevation={2} sx={{ p: 3, borderRadius: 2 }} style={{marginBottom:'5%', borderRadius:'10px'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead style={{backgroundColor: '#027BFE'}}>
                                        <TableRow>
                                            <TableCell style={{color:'white', textAlign: 'center' }}>Sr. No</TableCell>
                                            <TableCell style={{color:'white', textAlign: 'center' }}>Transaction ID</TableCell>
                                            <TableCell style={{color:'white', textAlign: 'center' }}>Date</TableCell>
                                            <TableCell style={{color:'white', textAlign: 'center' }}>Partner Request ID</TableCell>
                                            <TableCell style={{color:'white', textAlign: 'center' }}>Requested By</TableCell>
                                            <TableCell style={{color:'white', textAlign: 'center' }}>Amount</TableCell>
                                            <TableCell style={{color:'white', textAlign: 'center' }}>Status</TableCell>
                                            <TableCell style={{color:'white', textAlign: 'center' }}>Remarks</TableCell>
                                            <TableCell style={{color:'white', textAlign: 'center' }}>Approved Date</TableCell>
                                            <TableCell style={{color:'white', textAlign: 'center' }}>Processed By</TableCell>
                                            <TableCell style={{color:'white', textAlign: 'center' }}>Payment Method</TableCell>
                                            <TableCell style={{color:'white', textAlign: 'center' }}>Download</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tableData.map((row, index) => (
                                            <TableRow key={row.transactionId}
                                            className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}>
                                                <TableCell sx={{ textAlign: 'center' }}>{index + 1}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{row.transactionId}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{row.date}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{row.partnerRequestId}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{row.requestedBy}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{row.amount}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{row.status}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{row.remarks}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{row.approvedDate}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{row.processedBy}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{row.paymentMethod}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>
                                                    <IconButton onClick={() => handleDownload(row)}>
                                                        <DownloadIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Container>
    );
};

export default UserCashback;
