import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, TextField, Button, MenuItem, Grid, Card, CardContent, Paper, CircularProgress, Container } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Walllet_Icon } from '../../res/icons';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { BrowserView, MobileView } from 'react-device-detect';
import Papa from 'papaparse';
import BalanceTopComponent from './BalanceTopComponent';
import WalletBalanceComponent from './WalletBalanceComponent';



const payoutModes = [
    { value: 'NEFT', label: 'NEFT' },
    { value: 'RTGS', label: 'RTGS' },
    { value: 'IMPS', label: 'IMPS' },
    // Add more options as needed
];

const PayoutSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    payeeaccountNumber: Yup.string().required('Payee Account Number is required'),
    bankIfsc: Yup.string().required('Bank IFSC is required'),
    transferMode: Yup.string().required('Transfer Mode is required'),
    transferAmount: Yup.number().required('Transfer Amount is required').positive('Amount must be positive'),
    remarks: Yup.string(),
    // purpose: Yup.string(),
});

const BulkPayoutSchema = Yup.object().shape({
    file: Yup.mixed().required('File is required'),
});

const NewPayout = () => {

    const [walletBalance, setWalletBalance] = useState(0);
    const [showBulkPayout, setShowBulkPayout] = useState(false);
    const bulkPayout = localStorage.getItem("bulkPayout") === 'true';
    
    const [bankDetails, setBankDetails] = useState([]);
    
    const fetchWalletBalance = async () => {
        try {
            const token = localStorage.getItem("jwtToken");
            const response = await axios.get(
                "https://api.ipaisa.co.in/myapp/auth/getwalletbalance",
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setWalletBalance(response.data.walletBalance || 0);
        } catch (error) {
            console.error("Error fetching wallet balance:", error);
        }
    };
    
    const getBankDetails = async () => {
        try {
            const token = localStorage.getItem("jwtToken");
            const response = await axios.get('https://api.ipaisa.co.in/myapp/auth/getBankDetail',
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            // return response.data.body;
            const bankData = response?.data?.body || [];
            setBankDetails(bankData);
        } catch (error) {
            console.error('Error fetching bank details:', error);
        }
    };
    
    useEffect(() => {
        fetchWalletBalance();
        getBankDetails();
    }, []);
    
    const getLocation = async () => {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => resolve(position.coords),
                    (error) => reject(error)
                );
            } else {
                reject(new Error('Geolocation is not supported by this browser.'));
            }
        });
    };
    
    const handleSubmit = async (values, { setSubmitting }) => {
        if (walletBalance <= 0) {
            toast.error('Insufficient balance');
            setSubmitting(false);
            return;
        }
        try {
            const location = await getLocation();
            const token = localStorage.getItem('jwtToken');
            const payeraccountNumber = '120664700000021';
            const payload = {
                ...values,
                payeraccountNumber,
                latitude: location.latitude.toFixed(4),
                longitude: location.longitude.toFixed(4),
            };
    
            await axios.post(
                'https://api.ipaisa.co.in/myapp/auth/instantpay/payout',
                payload,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
    
            toast.success('Payout successful');
            fetchWalletBalance();
        } catch (error) {
            console.error('Payout failed:', error);
            toast.error('Payout failed');
        } finally {
            setSubmitting(false);
        }
    };
    
    const handleBulkSubmit = async (values, { setSubmitting }) => {
    try {
        const token = localStorage.getItem('jwtToken');
        const file = values.file;
    
        // Parse the CSV file using PapaParse
        Papa.parse(file, {
            header: true,
            complete: (result) => {
                const rows = result.data;
    
                // Process each row, converting payeeaccountNumber to a regular number format
                const processedRows = rows.map((row, index) => {
                    if (row.payeeaccountNumber) {
                        // Convert payeeaccountNumber from scientific notation to regular number format
                        row.payeeaccountNumber = Number(row.payeeaccountNumber).toFixed(0);
                    }
                    console.log(`Processed Row ${index + 1}:`, row);
                    return row;
                });
    
                // Generate a new CSV file from the processed data
                const csvString = Papa.unparse(processedRows);
    
                // Convert the CSV string to a Blob
                const newCsvFile = new Blob([csvString], { type: 'text/csv' });
    
                // Create FormData and append the generated CSV file
                const formData = new FormData();
                formData.append('file', newCsvFile, 'processed_payout.csv');
    
                // Submit the FormData with the generated CSV file to the API
                fetch('https://api.ipaisa.co.in/myapp/auth/launchjob', {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    body: formData,
                })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to submit bulk payout');
                    }
                    alert('Bulk Payout successful');
                })
                .catch(error => {
                    console.error('Bulk Payout failed:', error);
                    alert('Bulk Payout failed');
                })
                .finally(() => {
                    setSubmitting(false);
                });
            },
            error: (error) => {
                console.error('Error parsing CSV:', error);
                setSubmitting(false);
            }
        });
    } catch (error) {
        console.error('Bulk Payout failed:', error);
        alert('Bulk Payout failed');
        setSubmitting(false);
    }
    };
    
    
    const inputHeight = '56px';
    
    const BulkFileUpload = ({ setFieldValue }) => {
        const [selectedFile, setSelectedFile] = useState(null);
        const [error, setError] = useState('');
    
        const { getRootProps, getInputProps } = useDropzone({
            accept: '.csv',
            onDrop: (acceptedFiles, rejectedFiles) => {
                if (acceptedFiles.length > 0) {
                    setFieldValue('file', acceptedFiles[0]);
                    setSelectedFile(acceptedFiles[0]);
                    setError('');
                } else if (rejectedFiles.length > 0) {
                    setError('Only CSV files are allowed');
                }
            },
        });
    
        const handleRemoveFile = () => {
            setFieldValue('file', null);
            setSelectedFile(null);
            setError('');
        };
    
        console.log('bankDetails', bankDetails)
        return (
            <div>
                <div
                    {...getRootProps()}
                    style={{
                        border: '2px dashed #CCCCCC',
                        padding: '20px',
                        textAlign: 'center',
                        cursor: 'pointer',
                        marginBottom: '16px',
                    }}
                >
                    <input {...getInputProps()} />
                    <Typography>Drag and Drop a CSV file here or click to select a file</Typography>
                </div>
                {error && (
                    <Typography color="error" variant="body2">
                        {error}
                    </Typography>
                )}
                {selectedFile && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <Typography>{selectedFile.name}</Typography>
                            <Button variant="outlined" color="error" onClick={handleRemoveFile}>
                                Remove
                            </Button>
                        </Box>
                    </Box>
                )}
                <Typography sx={{ mt: 1 }}>
                    <a href="/data.csv" download="sample.csv" style={{ textDecoration: 'none', color: '#007bff' }}>
                        Sample CSV file
                    </a>
                </Typography>
            </div>
        );
    };
    


    return (
    <Container style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
    <BalanceTopComponent />

    <Grid container spacing={2} style={{ marginTop: '3%' }}>
     {/* Wallet Balance Card */}
     <WalletBalanceComponent/>

     <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
     <Paper style={{ padding: '2rem', borderRadius: '8px' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mb: 2 }}>
                                    <Button
                                        onClick={() => setShowBulkPayout(false)}
                                        style={{
                                            borderBottom: showBulkPayout ? 'none' : '2px solid #027BFE',
                                            textTransform: 'none',
                                            color: showBulkPayout ? '#808080' : '#027BFE',
                                            marginRight: '10px',
                                            fontSize: '16px'
                                        }}
                                    >
                                        Payout
                                    </Button>
                                    {bulkPayout && (
                                        <Button
                                            onClick={() => setShowBulkPayout(true)}
                                            style={{
                                                borderBottom: showBulkPayout ? '2px solid #027BFE' : 'none',
                                                textTransform: 'none',
                                                color: showBulkPayout ? '#027BFE' : '#808080',
                                                fontSize: '16px'
                                            }}
                                        >
                                            Bulk Payout
                                        </Button>
                                    )}
                                </Box>

                                {bulkPayout && showBulkPayout ? (
                                    <Formik
                                        initialValues={{ file: null }}
                                        validationSchema={BulkPayoutSchema}
                                        onSubmit={handleBulkSubmit}
                                    >
                                        {({ isSubmitting, setFieldValue, errors, touched }) => (
                                            <Form>
                                                <BulkFileUpload setFieldValue={setFieldValue} />
                                                {errors.file && touched.file ? (
                                                    <div style={{ color: 'red' }}>{errors.file}</div>
                                                ) : null}
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }} gap={2}>
                                                    <Button
                                                        style={{
                                                            backgroundColor: '#FF0000',
                                                            color: '#ffffff',
                                                            textTransform: "none",
                                                            width: "50%",
                                                            borderRadius: "9px",
                                                            fontSize: '20px',
                                                            height: '48px', // Example height, adjust as needed
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        type="submit"
                                                        style={{
                                                            backgroundColor: "#027BFE",
                                                            color: "white",
                                                            textTransform: "none",
                                                            width: "50%",
                                                            borderRadius: "9px",
                                                            fontSize: '20px',
                                                            height: '48px', // Example height, adjust as needed
                                                        }}
                                                        disabled={isSubmitting}
                                                    >
                                                        {isSubmitting ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Pay'}

                                                    </Button>
                                                </Box>
                                            </Form>
                                        )}
                                    </Formik>

                                ) : (
                                    <Formik
                                        initialValues={{
                                            name: '',
                                            payeeaccountNumber: '',
                                            bankIfsc: '',
                                            transferMode: '',
                                            transferAmount: '',
                                            remarks: '',
                                            purpose: 'REIMBURSEMENT',
                                        }}
                                        validationSchema={PayoutSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {({ isSubmitting, errors, touched, setFieldValue, values }) => (
                                            <Form>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={6} >
                                                        <Field
                                                            as={TextField}
                                                            name="name"
                                                            label="Name"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        {bankDetails?.length > 0 ? (
                                                            <Field
                                                                as={TextField}
                                                                label="Account Number"
                                                                name="payeeaccountNumber"
                                                                select
                                                                fullWidth
                                                                variant="outlined"
                                                                error={touched.payeeaccountNumber && Boolean(errors.payeeaccountNumber)}
                                                                helperText={touched.payeeaccountNumber && errors.payeeaccountNumber}
                                                                onChange={(event) => {
                                                                    const selectedAccountNumber = event.target.value;
                                                                    setFieldValue('payeeaccountNumber', selectedAccountNumber);

                                                                    const selectedBankDetail = bankDetails.find(
                                                                        (bankDetail) => bankDetail.accountNumber === selectedAccountNumber
                                                                    );

                                                                    if (selectedBankDetail) {
                                                                        setFieldValue('bankIfsc', selectedBankDetail.ifsc);
                                                                        setFieldValue('name', selectedBankDetail.name);
                                                                        // Add any other fields you want to auto-fill here
                                                                    }
                                                                }}
                                                            >
                                                                {bankDetails.map((bankDetail) => (
                                                                    <MenuItem
                                                                        key={bankDetail.accountNumber}
                                                                        value={bankDetail.accountNumber}
                                                                    >
                                                                        {`${bankDetail.accountNumber} - ${bankDetail.name}`}
                                                                    </MenuItem>
                                                                ))}
                                                            </Field>
                                                        ) : (
                                                            <Typography variant="body1" color="error" style={{ marginTop: '16px' }}>
                                                                No bank details added
                                                            </Typography>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <Field
                                                            as={TextField}
                                                            name="bankIfsc"
                                                            label="Bank IFSC"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <Field
                                                            as={TextField}
                                                            name="transferMode"
                                                            label="Transfer Mode"
                                                            select
                                                            variant="outlined"
                                                            fullWidth
                                                        >
                                                            {payoutModes.map((mode) => (
                                                                <MenuItem key={mode.value} value={mode.value}>
                                                                    {mode.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Field>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <Field
                                                            as={TextField}
                                                            name="transferAmount"
                                                            label="Transfer Amount"
                                                            variant="outlined"
                                                            type="number"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <Field
                                                            as={TextField}
                                                            name="remarks"
                                                            label="Remarks"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} style={{marginTop:'3%'}}>
                                                <Box display="flex" justifyContent="end" gap={1} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
                                                    <Button
                                                         sx={{
                                                            border: '1.5px solid #FF0000',
                                                            color: '#FF0000',
                                                            textTransform: "none",
                                                            width: { xs: '100%', md: '18%' },
                                                            fontSize: '16px',
                                                            borderRadius: '8px',
                                                            height: inputHeight
                                                          }}>Cancel</Button>
                                                    <Button
                                                        type="submit"
                                                        sx={{
                                                            backgroundColor: "#027BFE",
                                                            color: "white",
                                                            textTransform: "none",
                                                            width: { xs: '100%', md: '18%' },
                                                            fontSize: '16px',
                                                            borderRadius: '8px',
                                                            height: inputHeight,
                                                            '&:hover': {
                                                                backgroundColor: "#027BFE",
                                                                color: '#FFFFFF',
                                                            },
                                                        }}
                                                        disabled={isSubmitting}
                                                    >
                                                        {isSubmitting ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Pay'}

                                                    </Button>
                                                </Box>
                                                </Grid>
                                            </Form>
                                        )}
                                    </Formik>
                                )}
    </Paper>
    </Grid>
     </Grid>

     </Container>
  )
}

export default NewPayout
