import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Container,
  Grid,
  TextField,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  CardMedia
} from "@mui/material";

import BBPSLogo from "../Assets/BBPSLogo/BBPS.png"
import logo from "../Assets/login/iPaisaLogo.jpg";



const FASTag = () => {
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const formik = useFormik({
    initialValues: {
      fastagBank: "",
      vehicleNumber: ""
    },
    validationSchema: Yup.object({
      fastagBank: Yup.string().required("FASTag Issuing Bank is required"),
      vehicleNumber: Yup.string()
        .matches(/^[A-Z]{2}[0-9]{2}[A-Z]{2}[0-9]{4}$/, 'Please enter a valid Vehicle Registration Number')
        .required('Vehicle Registration Number / Wallet ID is required'),
    }),
    onSubmit: async (values) => {
      // Simulate API call or handle form submission logic here
      setSubmitSuccess(true);
    }
  });

  const handleKeyPress = (e) => {
    const charCode = e.charCode || e.keyCode;
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  };

  const inputHeight = '56px'; 

  return (
    <Container maxWidth="lg" style={{ marginTop: '12%', marginBottom: '3%' }}>
      <Box mt={4} p={4} boxShadow={2} bgcolor="#DDF1F5" borderRadius={8}
      sx={{
        background: 'linear-gradient(135deg, #0B1E59 0%, #1840BF 100%)',
        color: 'white',
      }}>
        <Grid container spacing={4}>
        <Grid item xs={12} sm={6} style={{marginTop:"1%"}}>
        <CardMedia
              component="img"
              image={logo}
              alt="iPaisa Logo"
              style={{ backgroundColor: "transparent", borderRadius: "50%", marginBottom:'4%' }}
              sx={{
                height: { xs: 50, md: 90 },
                width: { xs: 50, md: 90 },
                mt: { xs: 1, md: 1 },
                marginRight: { xs: "10px", md: "10px" }, // Adjusted to ensure space between logo and text
              }}
            /> 
            <Typography variant="h4" sx={{ fontWeight: 'bold', fontSize: '35px' }} gutterBottom>
              Streamlined FastTag Recharge
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ fontSize: '20px', marginTop: '4%' }}>
              Effortlessly handle your FastTag bills, keeping your online world running smoothly.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box p={4} bgcolor="white" boxShadow={2} borderRadius={8}>
              <Typography variant="h5" color= "#027BFE" gutterBottom sx={{ fontWeight: 'bold', fontSize: '30px' }}>
                FASTag Recharge
              </Typography>
              <Box display="flex" justifyContent="flex-end">
              <img src={BBPSLogo} alt="BBPS Logo" style={{ marginLeft: '25.5rem', width: '13%', height: '20%' }} />
              </Box>
              <FormControl fullWidth margin="normal" error={formik.touched.fastagBank && Boolean(formik.errors.fastagBank)}>
                <InputLabel id="fastagBank-label">FASTag Issuing Bank</InputLabel>
                <Select
                  labelId="fastagBank-label"
                  id="fastagBank"
                  name="fastagBank"
                  label="FASTag Issuing Bank"
                  value={formik.values.fastagBank}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.fastagBank && Boolean(formik.errors.fastagBank)}
                >
                  <MenuItem value="">Select Bank</MenuItem>
                  <MenuItem value="SBI">State Bank of India</MenuItem>
                  <MenuItem value="ICICI">ICICI Bank</MenuItem>
                  <MenuItem value="HDFC">HDFC Bank</MenuItem>
                  <MenuItem value="Axis">Axis Bank</MenuItem>
                  <MenuItem value="Kotak">Kotak Mahindra Bank</MenuItem>
                  <MenuItem value="PNB">Punjab National Bank</MenuItem>
                  <MenuItem value="IndusInd">IndusInd Bank</MenuItem>
                  <MenuItem value="IDFC">IDFC First Bank</MenuItem>
                  <MenuItem value="Yes">Yes Bank</MenuItem>
                  <MenuItem value="Union">Union Bank of India</MenuItem>
                  <MenuItem value="Canara">Canara Bank</MenuItem>
                  <MenuItem value="Bank of Baroda">Bank of Baroda</MenuItem>
                  <MenuItem value="Syndicate">Syndicate Bank</MenuItem>
                  <MenuItem value="Federal">Federal Bank</MenuItem>
                  <MenuItem value="South Indian Bank">South Indian Bank</MenuItem>
                  <MenuItem value="Karur Vysya Bank">Karur Vysya Bank</MenuItem>
                  <MenuItem value="City Union Bank">City Union Bank</MenuItem>
                  <MenuItem value="Dhanlaxmi Bank">Dhanlaxmi Bank</MenuItem>
                  <MenuItem value="Nagpur Nagarik Sahakari Bank">Nagpur Nagarik Sahakari Bank</MenuItem>
                  <MenuItem value="Punjab & Sind Bank">Punjab & Sind Bank</MenuItem>
                </Select>
                {formik.touched.fastagBank && formik.errors.fastagBank && (
                  <Typography color="error">{formik.errors.fastagBank}</Typography>
                )}
              </FormControl>

              {formik.values.fastagBank && (
                <FormControl fullWidth margin="normal" error={formik.touched.vehicleNumber && Boolean(formik.errors.vehicleNumber)}>
                  <TextField
                    id="vehicleNumber"
                    name="vehicleNumber"
                    label="Vehicle Registration Number / Wallet ID"
                    variant="outlined"
                    value={formik.values.vehicleNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.vehicleNumber && Boolean(formik.errors.vehicleNumber)}
                    helperText={formik.touched.vehicleNumber && formik.errors.vehicleNumber}
                    InputProps={{
                      inputProps: {
                        maxLength: 10,
                        pattern: "[A-Z]{2}[0-9]{2}[A-Z]{2}[0-9]{4}",
                        title: "Please enter a valid vehicle registration number or wallet ID"
                      }
                    }}
                  />
                </FormControl>
              )}

              <Button
                fullWidth
                type="submit"
                onClick={formik.handleSubmit}
                style={{ 
                  marginTop: "4%", 
                  backgroundColor: '#027BFE', 
                  color: 'white', 
                  textTransform: 'none', 
                  height: inputHeight ,
                  fontSize: 20
                }}
              >
                Confirm
              </Button>
              {submitSuccess && (
                <Typography variant="body1" style={{ marginTop: "0.5rem", color: "green" }}>
                  Payment successfully submitted!
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default FASTag;
