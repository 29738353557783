import React, { useState } from "react";
import { Button, Grid, InputBase, Paper, Typography } from '@mui/material';

const ManageAPI = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [showSearchInput, setShowSearchInput] = useState(false);

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>
                    API Handling
                </Typography>
                <Typography style={{ fontWeight: 'bold', color: '#343C6A', fontSize: '22px', paddingTop: '1%' }}>
                    Manage API
                </Typography>
            </Grid>
            <Grid item>
                <Paper style={{ borderRadius: '10px', padding: '1.5rem' }}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Typography style={{ color: '#454545', fontSize: '13px', fontFamily: 'sans-serif' }}>
                                Search
                            </Typography>
                        </Grid>
                        <Grid item container spacing={2} alignItems="center">
                            <Grid item xs={12} sm={8} md={6} lg={6}>
                                <InputBase
                                    fullWidth
                                    style={{
                                        borderRadius: '6px',
                                        border: '1px solid black',
                                        paddingLeft: '2%',
                                        height: '40px',
                                    }}
                                    id="fullWidth"
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    onClick={() => setShowSearchInput(!showSearchInput)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={3} lg={2}>
                                <Button
                                    fullWidth
                                    style={{
                                        textTransform: 'none',
                                        backgroundColor: '#027BFE',
                                        borderRadius: '6px',
                                        color: '#FFFFFF',
                                        height: '40px',
                                        fontFamily: 'sans-serif',
                                    }}
                                >
                                    Search
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default ManageAPI;
