import React, { useState } from "react";
import { Container, Box, Typography, TextField, Button, CircularProgress, Grid, CssBaseline } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

const ChangeParent = () => {
  const formik = useFormik({
    initialValues: {
      oldParentId: '',
      newParentId: '',
    },
    validationSchema: Yup.object({
      oldParentId: Yup.string().required('Old Parent is required')
        .test(
          'not-only-zero',
          'Old Parent cannot be only zero',
          value => value && !/^[0]+$/.test(value)
        ),
      newParentId: Yup.string().required('New Parent is required')
        .test(
          'not-only-zero',
          'New Parent cannot be only zero',
          value => value && !/^[0]+$/.test(value)
        ),
    }),
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        const token = localStorage.getItem("jwtToken");
        const response = await axios.put('https://api.ipaisa.co.in/myapp/admin/changeParentOfPartners', values, {
          headers: {
            Authorization: `Bearer ${token}`, // Replace with your actual token
          },
        });
        console.log('API Response:', response.data);
        
        setSubmitting(false);
      } catch (error) {
        console.error('API Error:', error);
        setErrors({ submit: 'An error occurred' });
        setSubmitting(false);
      }
    },
  });

  const handleVerifyUser = async (user) => {
    try {
      if (!formik.values.oldParentId) {
        // If oldParentId field is empty, show error message
        formik.setFieldError('oldParentId', 'Please enter the Old Parent');
        return;
      }

      // Simulate fetching data from the database
      const parentData = {
        oldParentId: formik.values.oldParentId,
      };

      // Update the formik values with the fetched data
      formik.setValues({
        ...formik.values,
        oldParentId: parentData.oldParentId,
      });

      // Clear any previous errors
      formik.setFieldError('oldParentId', undefined);
    } catch (error) {
      // Handle errors, e.g., display an error message to the user
    }
  };

  return (
    <>
      <CssBaseline />
      <Box style={{ padding: '10px 50px 50px 50px' }}>
        <Container maxWidth="xl">
          <Box sx={{ padding: '30px 60px 60px 60px', boxShadow: 2, borderRadius: 2, marginTop: "12%" }}>
            <Typography variant="h5" gutterBottom sx={{ textAlign: 'start', fontWeight: 'bold' }}>
              Change Parent
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="oldParentId"
                    name="oldParentId"
                    label="Old Parent"
                    variant="outlined"
                    margin="normal"
                    value={formik.values.oldParentId}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.oldParentId && Boolean(formik.errors.oldParentId)}
                    helperText={formik.touched.oldParentId && formik.errors.oldParentId}
                  />
                  <Button
                    sx={{
                      height: '30px',
                      width: '12%',
                      fontSize: '65%',
                      textTransform: 'none',
                      borderRadius: '8px',
                      backgroundColor: "#027BFE",
                      color: 'white',
                      mt: 1,
                      '&:hover': {
                        backgroundColor: '#027BFE',
                      },
                    }}
                    onClick={() => handleVerifyUser(formik.values.oldParentId)}
                  >
                    Verify User
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="newParentId"
                    name="newParentId"
                    label="New Parent"
                    variant="outlined"
                    margin="normal"
                    value={formik.values.newParentId}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.newParentId && Boolean(formik.errors.newParentId)}
                    helperText={formik.touched.newParentId && formik.errors.newParentId}
                  />
                </Grid>
              </Grid>
              <Box sx={{ display: 'flex', justifyContent: 'start', mt: 1 }}>
                <Button
                  type="submit"
                  style={{ textTransform: 'none', width: '30%', borderRadius: '8px', backgroundColor: "#027BFE", color: 'white' }}
                  disabled={formik.isSubmitting}
                >
                  {formik.isSubmitting ? <CircularProgress size={24} /> : 'Assign'}
                </Button>
              </Box>
            </form>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ChangeParent;
