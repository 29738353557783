import React from 'react'

const TicketHandling = () => {
  return (
    <div style={{marginTop:'20%'}}>
      {/* Hello Kamran's Ticket Handling! */}
    </div>
  )
}

export default TicketHandling
