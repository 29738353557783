import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Grid } from '@mui/material';
import axios from "axios";

const EditUserDialog = ({ open, handleClose, user, handleSave }) => {
    const [userData, setUserData] = useState({
        firstName: '',
        middleName: '',
        lastName: '',
        businessName: '',
        email: '',
        mobileNumber: '',
        address: '',
        dob: '',
    });

    useEffect(() => {
        if (user) {
            setUserData({
                firstName: user.firstName || '',
                middleName: user.middleName || '',
                lastName: user.lastName || '',
                businessName: user.businessName || '',
                email: user.email || '',
                mobileNumber: user.mobileNumber || '',
                address: user.address || '',
                dob: user.dob || '',
            });
        }
    }, [user]);

    const handleCancel = () => {
        handleClose();
        setUserData({
            firstName: '',
            middleName: '',
            lastName: '',
            businessName: '',
            email: '',
            mobileNumber: '',
            address: '',
            dob: '',
        });
    };

    const handleSaveUser = async () => {
        // Simple validation
        if (!userData.firstName || !userData.lastName || !userData.businessName || !userData.email || !userData.address || !userData.dob) {
            alert('All fields except middle name and mobile number are required.');
            return;
        }

        // Email validation
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!emailRegex.test(userData.email)) {
            alert('Invalid email format.');
            return;
        }

        try {
            const token = localStorage.getItem('jwtToken'); // Retrieve JWT token from local storage
            console.log('Token:', token);
            console.log('User Data:', userData);

            // Make API call to save the edited user details
            const response = await axios.put(`https://api.ipaisa.co.in/myapp/auth/updateUser`, userData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            
            console.log('API Response:', response);

            // Pass the edited user data to the parent component
            handleSave(userData);
            handleClose();
        } catch (error) {
            console.error('Error saving user:', error);
            alert('Failed to save user details. Please try again.');
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;

        // Perform validation for each field
        switch (name) {
            case 'firstName':
            case 'middleName':
            case 'lastName':
                // Allow only text characters
                if (!/^[a-zA-Z\s]*$/.test(value)) {
                    return; // Do not update state if input contains invalid characters
                }
                break;
            default:
                break;
        }

        // Update state with the new value
        setUserData({ ...userData, [name]: value });
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle bgcolor={'#027BFE'} color={'white'}>Edit User Details</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            margin="dense"
                            name="firstName"
                            label="First Name"
                            type="text"
                            fullWidth
                            value={userData.firstName}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            margin="dense"
                            name="middleName"
                            label="Middle Name"
                            type="text"
                            fullWidth
                            value={userData.middleName}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            margin="dense"
                            name="lastName"
                            label="Last Name"
                            type="text"
                            fullWidth
                            value={userData.lastName}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            margin="dense"
                            name="businessName"
                            label="Business Name"
                            type="text"
                            fullWidth
                            value={userData.businessName}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            margin="dense"
                            name="email"
                            label="Email"
                            type="email"
                            fullWidth
                            value={userData.email}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            margin="dense"
                            name="mobileNumber"
                            label="Mobile Number"
                            type="text"
                            fullWidth
                            value={userData.mobileNumber}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            margin="dense"
                            name="address"
                            label="Address"
                            type="text"
                            fullWidth
                            value={userData.address}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            margin="dense"
                            name="dob"
                            label="Date of Birth"
                            type="date"
                            fullWidth
                            value={userData.dob}
                            onChange={handleChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between' }}>
                <Button onClick={handleCancel} sx={{ width: '150px', height: '54px', borderRadius: '12px',  backgroundColor: '#FF0000', color: '#ffffff', '&:hover': {backgroundColor: '#FF0000', color:'#ffffff' },
                 }}>
                    Cancel
                </Button>
                <Button onClick={handleSaveUser} sx={{ width: '150px', height: '54px', borderRadius: '12px', backgroundColor: '#027BFE', color: '#FFFFFF', '&:hover': {backgroundColor: '#027BFE',
                color: 'white'}, }}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditUserDialog;
